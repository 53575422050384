/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect} from 'react'
import './practiceWEPage.scss'

export default function We({slideIndex}) {
    const [showData, setData] = useState([])
  
    var url_root= "https://9scoretrain.com/pte-files/questions/we/"
    var question_id= slideIndex.toString()
    var file_name = "we-"+ question_id + ".json"
    var url = url_root + file_name
    let container

    /**
     * Fetching the data
     */
     function weJsonData(link){
        fetch(link)
        .then(response => {
        if (!response.ok) { throw new Error("HTTPS error " + response.status); }
         return response.json();
        })
        .then(json =>{
        container = Object.keys(json).map(key =>{
            return {[key]: json[key]}
        })
        setData(container)
        })
        .catch(error =>{
        console.log(error)
        })
    }

    useEffect(() => {
        weJsonData(url)
    },[slideIndex])

  return (
    <>
    <div className='slideshow-container'>
        <div className={slideIndex === 1 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 2 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 3 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 4 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 5 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 6 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 7 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 8 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 9 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
        <div className={slideIndex === 10 ? "active-slides" : "mySlides"}> 
            {showData.map(data =>{
                return(
                    <div key={data.id}>
                        <p>{data.content}</p>
                    </div>
                )
            })}
        </div>
    </div>
    </>
  )
}
