/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { withTranslation } from 'react-i18next'
import {bottomData} from './data'
import SmallItem from './smallItem'


function BottomItem() {
    return (
        <div className="bottom-item p-3">
            {
                bottomData.map((b) => (
                    <SmallItem 
                    className = "p-3"
                    title ={b.title}
                    des = {b.des}
                    />
                ))
            }
        </div>
    )
}

export default withTranslation()(BottomItem)