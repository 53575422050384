/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


export const dataListFilter = {
    "Source": ["All", "Real Question", "By Machine", "其他"], 
    "Frequency": ["All", "High Frequency", "Low Frequency"],
    "Practice": ["All", "Practiced", "Not Practiced"],
    "Favorite": ["All", "Collect", "Least Favorite"]
}
export const dataListSort = ["Question Number", "Frequency", "New", "Renew"]

