/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const data = [
    {
        title: "Quality Enhancement Course",
        des: "The first class of your PTE test preparation study, from advanced test preparation beginners to 8 band masters",
        tabs: ["Speaking", "Writing", "Reading", "Listening"], 
        slides: [
            [{cardTitle: "PTE test introduction", cardDes: ""},
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}], 

            [{cardTitle: "Introduction to PTE Speaking", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""},
             {cardTitle: "PTE test introduction", cardDes: ""}],

            [{cardTitle: "Introduction to PTE Speaking", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}],

            [{cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "PTE test introduction", cardDes: ""}]
        ]
    },
    {
        title: "Beginner's Guide",
        des: "Recommend for newcomers, Overview of 20 question types",
        tabs: ["Learn", "Speaking", "Writing", "Reading", "Listening"], 
        slides: [
            [{cardTitle: "PTE test introduction", cardDes: ""}, 
             {cardTitle: "The difference between PTE and IELTS", cardDes: ""}, 
             {cardTitle: "PTE Test Preparation Guide", cardDes: ""}, 
             {cardTitle: "Sign-up process", cardDes: ""}, 
             {cardTitle: "Cancellation process", cardDes: ""}, 
             {cardTitle: "Detailed explanation of official machine scoring", cardDes: ""}, 
             {cardTitle: "10 minutes to complete the mock test process", cardDes: ""}, 
             {cardTitle: "Exclusive review plan", cardDes: ""}, 
             {cardTitle: "How to Analyze Transcripts", cardDes: ""}, 
             {cardTitle: "PTE accredited schools and governments", cardDes: ""}, 
             {cardTitle: "The translator teaches you how to take notes", cardDes: ""}] ,

            [{cardTitle: "Introduction to PTE Speaking", cardDes: ""}, 
             {cardTitle: "Introduction to RA question types", cardDes: ""}, 
             {cardTitle: "Introduction to DI Question Types", cardDes: ""}, 
             {cardTitle: "Introduction to RS Question Types", cardDes: ""},
             {cardTitle: "Introduction to RL question types", cardDes: ""},
             {cardTitle: "Introduction to ASQ question types", cardDes: ""}],

            [{cardTitle: "Introduction to PTE Writing", cardDes: ""}, 
             {cardTitle: "Introduction to SWT question types", cardDes: ""}, 
             {cardTitle: "Introduction to WE question types", cardDes: ""}],

            [{cardTitle: "Introduction to PTE Reading", cardDes: ""}, 
             {cardTitle: "Introduction to RFIB Question Types", cardDes: ""}, 
             {cardTitle: "Introduction to RMCM Question Types", cardDes: ""}, 
             {cardTitle: "Introduction to RMCS Question Types", cardDes: ""}, 
             {cardTitle: "Introduction to RO Question Types", cardDes: ""}, 
             {cardTitle: "Introduction to RWFIB Question Types", cardDes: ""}],

            [{cardTitle: "Introduction to PTE Listening", cardDes: ""}, 
             {cardTitle: "Introduction to FIB question types", cardDes: ""}, 
             {cardTitle: "Introduction to HCS question types", cardDes: ""}, 
             {cardTitle: "Introduction to HIW question types", cardDes: "大家来找茬"},
             {cardTitle: "Introduction to MCM question types", cardDes: ""}, 
             {cardTitle: "Introduction to MCS question types", cardDes: ""},
             {cardTitle: "Introduction to SMW question types", cardDes: ""}, 
             {cardTitle: "Introduction to SST question types", cardDes: ""},
             {cardTitle: "Introduction to WFD question types", cardDes: "尽量不要挑衅机器"}]
        ]
    },
    {
        title: "Famous teacher open class",
        des: "Regular analysis of test preparation hotspots, test dynamics and skills",
        tabs: ["Speaking", "Writing", "Reading", "Listening"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["4", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["5", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    },
    {
        title: "COW PTE three minutes",
        des: "",
        tabs: ["Speaking", "Writing", "Reading", "Listening"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["4", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["5", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    },
    {
        title: "Experience Sharing",
        des: "",
        tabs: ["79", "8 Band", "7 Band"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    }
]