/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import './streamBanner.scss'
import 'styled-components/macro'
import { TwitchEmbed } from 'react-twitch-embed'
import React, { useEffect, useState } from 'react'



export default function StreamBanner() {
    // const youtube_playlistItem_API = "https://www.googleapis.com/youtube/v3/playlistItems"
    // const YOUTUBE_API_KEY = "AIzaSyAPdICy2lLSFCsyysCQfCzuCSWIa_TXa10"
    // const [playlistData, setPlaylistData] = useState([])
    // const [videoID, setVideoID] = useState("videoseries?list=PLWqiBsWJlPx08PtBIyNeoVuRFw0wHa4R_")
    // let container

    // useEffect(() => {
    //     fetch(`${youtube_playlistItem_API}?part=snippet&playlistId=PLWqiBsWJlPx08PtBIyNeoVuRFw0wHa4R_&maxResults=50&key=${YOUTUBE_API_KEY}`)
    //         .then(response => {
    //             if (!response.ok) { throw new Error("HTTPS error " + response.status); }
    //             return response.json();
    //         })
    //         .then(json => {
    //             container = Object.keys(json).map(key => {
    //                 return { [key]: json[key] }
    //             })
    //             setPlaylistData(container)
    //             console.log(container)
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }, [])

    // Live Stream configuration
    const channel = "cow_pte"
    const width = 1000
    const height = 500

    return (
        <section id="mn-stream-banner">
            <a className="bg" href="javascript:void(0);" target="_self"></a>
            <div className="header-placehoder">This placeholder is for the fix header</div>
            {/* <div className="slider-wrapper">
                <div className="slider-player">
                    <iframe style={{ width: 784, height: 441 }}
                        src={"https://www.youtube.com/embed/" + videoID}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className="slider-tabs">
                    {playlistData.slice(2, 3).map(item => {
                        return (
                            <>
                                <div className='slider-tabs-list'>
                                    {item.items.map(d => {
                                        const { id, snippet = {} } = d;
                                        const { title, thumbnails = {}, resourceId } = snippet
                                        const { medium = {} } = thumbnails
                                        return (
                                            <li className='playlist-item' key={id}>
                                                <img src={medium.url} alt={title} onClick={() => { setVideoID(resourceId.videoId) }}></img>
                                            </li>
                                        )
                                    })
                                    }
                                </div>
                            </>
                        )
                    })}
                </div>
            </div> */}
            <div className="d-flex justify-content-center align-items-center">
            <TwitchEmbed channel={channel} width={width} height={height} />
            </div>
        </section>
    )
}



function SlideTabUnit(props) {
    return (
        <div className="Slider-flashTab Slider-flashTabCover">
            <div className="Slider-flashTabImg">
                <div className="LazyLoad is-visible DyImg Slider-flashTabImgIn">
                    <img src="https://sta-op.douyucdn.cn/douyu-vrp-admin/2020/11/17/6a0bfdb0c7117f9981a8a4f04c9fcaf0.avif?x-oss-process=image/format,webp/quality,q_75" className="DyImg-content is-normal " />
                </div>
                <div className="Slider-flashTabHover"></div>
            </div>
        </div>
    )
}