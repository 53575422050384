/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import {FcHome, FcDocument, FcCrystalOscillator, FcSimCard, FcSelfie, FcSportsMode, FcSupport, FcStatistics, FcTodoList, FcVoicePresentation, FcWikipedia, FcOnlineSupport, FcScatterPlot, FcTimeline, FcStackOfPhotos, FcGraduationCap, FcFaq, FcElectricity} from 'react-icons/fc'
import { PATHS } from '../../../global/constants'



export const adminMenuData = [
    {
        path: PATHS.admin.name,
        icon: <FcHome />,
        title: "Home"
    },
    {
        path: PATHS.predict.name, 
        icon: <FcSportsMode />,
        title: "Predicts"
    },
    {
        path: PATHS.practice.name,
        icon: <FcDocument />,
        title: "Question Bank"
    },
    {
        path: PATHS.admin.name+PATHS.memory.name, 
        icon: <FcSimCard />,
        title: "Exam Memories"
    },
    {
        path: PATHS.admin.name+PATHS.mock.name, 
        icon: <FcSelfie />,
        title: "Mocks"
    },
    {
        path: PATHS.admin.name+PATHS.pteInfo.name, 
        icon: <FcStackOfPhotos />,
        title: "PTE Info"
    },
    {
        path: PATHS.admin.name+PATHS.shortCourses.name, 
        icon: <FcGraduationCap />,
        title: "Short Courses"
    },
    {
        path: PATHS.admin.name+PATHS.tutoring.name, 
        icon: <FcOnlineSupport />,
        title: "Tutoring"
    },
    {
        path: PATHS.admin.name+PATHS.vocabulary.name, 
        icon: <FcWikipedia />,
        title: "Vocabulary"
    },
    {
        path: PATHS.admin.name+PATHS.myCollections.name, 
        icon: <FcOnlineSupport />,
        title: "My Collections"
    },
    {
        path: PATHS.admin.name+PATHS.dailyPlan.name, 
        icon: <FcTodoList />,
        title: "Daily Plan"
    },
    {
        path: PATHS.pteResearch.name, 
        icon: <FcStatistics />,
        title: "PTE Research"
    },
    {
        path: PATHS.admin.name+PATHS.analysis.name, 
        icon: <FcTimeline />,
        title: "Analysis"
    },
    {
        path: PATHS.admin.name+PATHS.practiceRecord.name, 
        icon: <FcScatterPlot />,
        title: "Practice Records"
    },
    {
        path: PATHS.admin.name+PATHS.studyGroups.name, 
        icon: <FcElectricity />,
        title: "Study Groups"
    },
    {
        path: PATHS.admin.name+PATHS.experienceSharing.name, 
        icon: <FcVoicePresentation />,
        title: "Experience Sharing"
    },
    {
        path: PATHS.admin.name+PATHS.commonQuestions.name, 
        icon: <FcFaq />,
        title: "Common Questions"
    },
    {
        path: PATHS.admin.name+PATHS.setting.name, 
        icon: <FcSupport />,
        title: "Setting"
    },
    {
        path:  PATHS.home.name, 
        icon: <FcCrystalOscillator />,
        title: "Exit"
    }
]