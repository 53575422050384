/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './streamFixSidebar.scss'
import { DiApple } from 'react-icons/di'

export default function StreamFixSidebar() {
    return (
        <div id="mn-stream-fix-sidebar">
            <div className="item" >
                {/* correct */}
                <img src={process.env.PUBLIC_URL + "/assets/images/enlist.avif"} alt='enlist'/>
                {/* wrong */}
                {/* <img src={require('./images/enlist.avif').default} alt="enlist" /> */}
                <p>Apply</p>
            </div>
            <div className="item" >
                <div className="download-container p-3">
                    <div className="wrapper">

                        <img src={process.env.PUBLIC_URL + "/assets/images/pc.avif"} className="top" alt="download mac" />
                        <div className="down" >
                            <DiApple />
                            Download
                        </div>
                        <div className="txt" >APP</div>
                    </div>
                </div>
                <img src={process.env.PUBLIC_URL + "/assets/images/download.avif"} alt="download tools" />
                <p>Tools</p>
            </div>
            <div className="item" >
                <img src={process.env.PUBLIC_URL + "/assets/images/ctrl.avif"} alt="stream control" />
                <p>Management</p>
            </div>
        </div>
    )
}
