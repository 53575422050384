/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import {RiComputerLine, RiRefund2Line, RiRecycleLine} from 'react-icons/ri'
import {FaChalkboardTeacher} from 'react-icons/fa'

export const data = [
    {
        icon: <RiComputerLine />,
        title: "Free Trial Promise",
        des1: "Free Trial Listening to the First Assessment Lesson",
        des2: "Pay for the class after you are satisfied"
    },
    {
        icon: <FaChalkboardTeacher />,
        title: "Promise to change teachers at any time",
        des1: "Dissatisfaction with the teacher at any time",
        des2: "Anyone who wants to apply for a change of teacher"
    },
    {
        icon: <RiRefund2Line />,
        title: "Full Money Back Promise",
        des1: "Within 31 days of payment and no more than 3 lessons",
        des2: "Full refund available"
    },
    {
        icon: <RiRecycleLine />,
        title: "Anytime Money Back Promise",
        des1: "Dissatisfied during class",
        des2: "Request a refund at any time"
    }
]