/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import {Form, InputGroup} from 'react-bootstrap'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import {formErrorEnum} from './data'



export default function FieldPassword(props) {
    const [showPassword, setShowPassword] = useState(false)


    function checkPassword(v) {
        // write to state
        props.setFormInfo({...props.formInfo, password: v})

        // password strength
        // at least 8 bits
        // at least 1 number
        // at least 1 special character
        // at least 1 capital letter
        // at least 1 normal letter

        const reNum = /\d/;
        const reLower = /[a-z]/;
        const reCapital = /[A-Z]/;
        const reSpecial = /[_\W]/;
        
        
        if (v === ""){
            props.setFormState({...props.formState, password: formErrorEnum.passwordEmpty})

        } else if (v.length < 8) {
            props.setFormState({...props.formState, password: formErrorEnum.passwordNotLength})

        } else if (!reNum.test(v)) {
            props.setFormState({...props.formState, password: formErrorEnum.passwordNotNumber})
 
        }  else if (!reSpecial.test(v)) {
            props.setFormState({...props.formState, password: formErrorEnum.passwordNotSpecialCharacter})

        } else if (!reLower.test(v)) {
            props.setFormState({...props.formState, password: formErrorEnum.passwordNotLowercase})

        } else if (!reCapital.test(v)) {
            props.setFormState({...props.formState, password: formErrorEnum.passwordNotCapitalLetter})

        } else {
            props.setFormState({...props.formState, password: ""})
        }
    }


    return (
        <div>
            <InputGroup>
                <Form.Control 
                    type={showPassword ? "text" : "password"}
                    placeholder="Please enter at least 8-digit password" 
                    value={props.formInfo.password}
                    onChange={(e) => checkPassword(e.target.value)}
                    />
                <InputGroup.Text>
                    <div onClick={() => {setShowPassword(!showPassword)}}>
                    { showPassword &&  <AiOutlineEyeInvisible /> }
                    { !showPassword &&  <AiOutlineEye /> }
                    </div>
                    
                </InputGroup.Text> 
            </InputGroup>
            {
                props.formState.password !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.password}
                </Form.Text>
            }
            <br />
        </div>
    )
}
