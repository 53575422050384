/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import FixFooter from '../general/fixFooter/fixFooter'
import Footer from '../general/footer/footer'
import Nav from '../general/nav/nav'
import StreamBanner from './banner/streamBanner'
import StreamFixSidebar from './fixSidebar/streamFixSidebar'
import { AiOutlinePlus } from 'react-icons/ai'
import './streamPage.scss'


export default function StreamPage() {
    return (
        <div>
            <Nav />
            <StreamBanner />
            <main id="mn-stream-page">
                <div className='body'>
                    <div className='header'>
                        <div className='title'>
                            <h4><b>Other PTE videos</b></h4>
                        </div>
                        <div className='header-right'>
                            <button className='create-btn'><AiOutlinePlus /> Create video</button>
                            <button className='my-list-btn'>My list</button>
                            <button className='fav-btn'>Favorites</button>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <StreamFixSidebar />
            <FixFooter />
        </div>
    )
}
