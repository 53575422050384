/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


import { Swiper, SwiperSlide, } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "styled-components/macro";
import './testimony.scss'
import HomeTitle from '../../general/title/homeTitle'


// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


export default function Testimony() {
    return (
        <section id="mn-testimony" className="py-5">
            <HomeTitle  
                title = "COW PTE Tutors"
                des = "Tutors' score reports"
            />

            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation
                pagination={{ 
                    clickable: true
                }}
                autoplay = {{
                    delay: 3000
                }}
                speed = {1000}
                loop = {true}
                >
                {
                    [...Array(5).keys()].map((idx) => (
                    <SwiperSlide key={idx}>
                        <img src={process.env.PUBLIC_URL + "/assets/images/pte_"+(idx+1)+".avif"} alt='' className="shadow"/>
                    </SwiperSlide>
                    ))
                }
            </Swiper>
        </section>
    );
};