/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { Route, Switch, BrowserRouter} from 'react-router-dom'
import MockPage from './mockPage'
import ListeningTest from './listeningTest'
import ReadingTest from './readingTest'
import WritingTest from './writingTest'
import SpeakingTest from './speakingTest'
import VIPTest from './vipTest'
import ModelTest from './modelTest/modelTest'
import VIPModelTest from './modelTest/vipModelTest'
import CustomModelTest from './modelTest/customModelTest'
import { PATHS } from '../../../global/constants'

export default function MockRoute() {
  return (
    <>
    <BrowserRouter>
    <Switch>
      <Route path={PATHS.admin.name+PATHS.mock.name} exact component={MockPage}/>
      <Route path={PATHS.admin.name+PATHS.mock.listeningTest.name} component={ListeningTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.readingTest.name} component={ReadingTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.writingTest.name} component={WritingTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.speakingTest.name} component={SpeakingTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.vipTest.name} component={VIPTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.modelTest.name} component={ModelTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.vipModelTest.name} component={VIPModelTest}/>
      <Route path={PATHS.admin.name+PATHS.mock.customModelTest.name} component={CustomModelTest}/>
   </Switch>
    </BrowserRouter>
    </>
  )
}
