/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './product.scss'
import HomeTitle from '../../general/title/homeTitle'
import { Container, Row, Col} from 'react-bootstrap'
import {FcCheckmark} from 'react-icons/fc'

export default function Product() {
    return (
        <section id="mn-product" className="py-5">

            <HomeTitle  
                title = "COW PTE - APP"
                des = "The choice of millions of PTE candidates"
            />

            <Container>
                <Row>
                    <Col md={6} className="mb-5">
                        <div className="app-left">
                            <div className="app-info animation-translation-fr an-active">
                                <p><FcCheckmark /> AI scored</p>
                                <p><FcCheckmark /> High-fequency questions</p>
                                <p><FcCheckmark /> Updated Micro-tutorials</p>
                                <p><FcCheckmark /> Interactive Community</p>
                            </div>
                            <div className="app-btns mt-5">
                                <img src={process.env.PUBLIC_URL+'/assets/images/apple.avif'} alt="" className="shadow"/>
                                <img src={process.env.PUBLIC_URL+'/assets/images/qr.avif'} alt="" className="shadow" />
                            </div>
                        </div>
                    
                    </Col>
                    <Col md={6} className="mb-5 animation-translation-rf an-active">
                        <div className="app-right">
                            <img src={process.env.PUBLIC_URL+'/assets/images/app.avif'} alt="" className="shadow"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
