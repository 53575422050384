/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import 'styled-components/macro'
import './popupFeedback.scss'

export default function PopupFeedback(props) {
    return (
        <Modal id="mn-modal-feedback" show={props.modalIsShow} onHide={props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <div className="el-dialog-body">
      <div  role="radiogroup" className="el-radio-group">
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio-input"><span className="el-radio-inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio-original" value="1" /></span>
            <span className="el-radio-label" style={{margin: 5}}>
               Not updated in time
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio-input"><span className="el-radio-inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio-original" value="2" /></span>
            <span className="el-radio-label" style={{margin: 5}}>
               Wrong text
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio-input"><span className="el-radio-inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio-original" value="3" /></span>
            <span className="el-radio-label" style={{margin: 5}}>
               Wrong audio
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio-input"><span className="el-radio-inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio-original" value="4" /></span>
            <span className="el-radio-label" style={{margin: 5}}>
               Unapproriate comments
            </span>
         </label>
         <label  role="radio" aria-checked="true" tabindex="0" className="el-radio radioSelect is-checked" >
            <span className="el-radio-input is-checked"><span className="el-radio-inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio-original" value="5" /></span>
            <span className="el-radio-label" style={{margin: 5}}>
               Others
            </span>
         </label>
      </div>
      <div  className="el-textarea" >
         <textarea autocomplete="off" placeholder="Enter content" className="el-textarea-inner"></textarea>
         
      </div>
   </div>

            </Modal.Body>

            <Modal.Footer>
            <Button variant="secondary" onClick={props.hideModal}>
               Cancel
            </Button>
            <Button variant="primary" onClick={props.hideModal}>
               Confirm
            </Button>
            </Modal.Footer>
        </Modal>

    )
}
