/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import AdminNav from './nav/adminNav'
import {Route, Switch} from 'react-router-dom'
import AdminSideMenu from './sideMenu/adminSideMenu'
import PracticePage from './practice/practicePage'
import MemoryPage from './memory/memoryPage'
import MockRoute from './mock/mockRoute'
import AdminHomePage from './home/adminHomePage'
import { PATHS } from '../../global/constants'
import PredictPage from './predict/predictPage'
import BookRoute from './vocabulary/bookRoute'
import CollectionRoute from './myCollections/collectionRoute'
import TutorRoute from './tutoring/tutorRoute'
import ShortCourses from './shortCourses/shortCourses'
import PTEInfo from './pteInfo/pteInfo'
import PlanRoute from './dailyPlan/planRoute'
import PTEResearch from './pteResearch/pteResearch'
import Analysis from './analysis/analysis'
import PracticeRecord from './practiceRecord/practiceRecord'
import StudyGroups from './studyGroups/studyGroups'
import ExperienceSharing from './experienceSharing/experienceSharing'
import CommonQuestions from './commonQuestions/commonQuestions'
import Setting from './setting/setting'
import './adminPage.scss'

export default function AdminPage(props) {
    return (
        <div id="mn-admin-page">
            <AdminNav />
            <main className="p-3 pr-5">
                <AdminSideMenu />
                <Switch>
                    <Route path={PATHS.admin.name} exact component={AdminHomePage}></Route>
                    <Route path={`${PATHS.practice.name}`} component={PracticePage}></Route>
                    <Route path={`${props.match.url}${PATHS.memory.name}`} component={MemoryPage}></Route>
                    <Route path={`${PATHS.predict.name}`} component={PredictPage}> </Route>
                    <Route path={`${props.match.url}${PATHS.mock.name}`} component={MockRoute}></Route>
                    <Route path={`${props.match.url}${PATHS.pteInfo.name}`} component={PTEInfo}></Route>
                    <Route path={`${props.match.url}${PATHS.shortCourses.name}`} component={ShortCourses}></Route>
                    <Route path={`${props.match.url}${PATHS.tutoring.name}`} component={TutorRoute}></Route>
                    <Route path={`${props.match.url}${PATHS.vocabulary.name}`} component={BookRoute}></Route>
                    <Route path={`${props.match.url}${PATHS.myCollections.name}`} component={CollectionRoute}></Route>
                    <Route path={`${props.match.url}${PATHS.dailyPlan.name}`} component={PlanRoute}></Route>
                    <Route path={`${props.match.url}${PATHS.pteResearch.name}`} component={PTEResearch}></Route>
                    <Route path={`${props.match.url}${PATHS.analysis.name}`} component={Analysis}></Route>
                    <Route path={`${props.match.url}${PATHS.practiceRecord.name}`} component={PracticeRecord}></Route>
                    <Route path={`${props.match.url}${PATHS.studyGroups.name}`} component={StudyGroups}></Route>
                    <Route path={`${props.match.url}${PATHS.experienceSharing.name}`} component={ExperienceSharing}></Route>
                    <Route path={`${props.match.url}${PATHS.commonQuestions.name}`} component={CommonQuestions}></Route>
                    <Route path={`${props.match.url}${PATHS.setting.name}`} component={Setting}></Route>
                    <Route path={`${props.match.url}${PATHS.home.name}`} ></Route>
                </Switch>
            </main>
        </div>
       
    )
}
