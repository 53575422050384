/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import auth from '../../utils/auth'
import { formErrorEnum, loginStateEnum } from './data'
import FieldUsernameEmail from './fieldUsernameEmail'
import FieldPassword from './fieldPassword'
import LoginMainBtn from './loginMainBtn'
import {Form} from 'react-bootstrap'
import 'styled-components/macro'


export default function FormEmailLogin(props) {

    // for error
    const [formState, setFormState] = useState({
        username: formErrorEnum.fieldEmpty, 
        email: "",
        password: formErrorEnum.passwordEmpty
    })



    function toForget() {
        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty
        })

        props.setLoginState(loginStateEnum.emailForget)

    }

    function toRegister() {
        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty
        })


        props.setLoginState(loginStateEnum.emailRegister)

    }

    return (
        <div className="emailLogin" css={`display: ${props.loginState === loginStateEnum.emailLogin ? "block" : "none"};`}>
            <Form.Group>
                <FieldUsernameEmail 
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />

                <FieldPassword
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />
            </Form.Group>

            <div className="options pb-3">
                            
                <div 
                    className="register"
                    onClick={()=>{toRegister()}}>
                        Register Now
                </div>
                <div 
                    className="forget"
                    onClick={()=>{toForget()}}>
                        Forgot password
                </div>
            </div>
                        
            {/*<LoginMainBtn 
                formState = {formState}
                onClick = {() => {auth.loginEmail(props.formInfo)}}
                content = "Log in"
            />*/}
            <LoginMainBtn 
                formState = {formState}
                onClick = {() => {auth.loginByEmail(props.formInfo)}}
                content = "Log in"
            />
        </div>
    )
}
