/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import './makePlan.scss'
import {AiFillCaretLeft} from 'react-icons/ai'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../global/constants'
import { planTitle, questionType } from './planData'

export default function MakePlan() {
    const [title, setTitle] = useState("Speak")
  return (
    <div id="mn-make-plan">
    <div className='plan-panel'>
        <div className='title p-2'>
          <Link to={PATHS.admin.name+PATHS.dailyPlan.name}>
          <AiFillCaretLeft className='go-back' />
          </Link>
          Making plans
        </div>
        <div className='body'>
            <div className='plan-title'>
                {planTitle.map((t, idx) =>{
                    return(
                        <>
                            <button onClick={() =>{setTitle(t)}} 
                            className={title === t ? 'btn-title': "btn-unactive"} key={idx}>{t}</button>
                        </>
                    )
                })}
                <button className='save-btn'><b>Save Setting</b></button>
            </div>
            <div className='plan-option'>
                <table>
                    <tr className='table-header'>
                        <th>Question type</th>
                        <th>Quantity</th>
                        <th>Type</th>
                    </tr>
                    {questionType.map((type, idx)=>{
                        return(
                        <div key={idx} className={title === type.name ? "list-active":"list-unactive"}>
                            {type.type.map((data, pos) =>{
                                return(
                                    <tr key={pos}>
                                        <td className='type'>{data}</td>
                                        <td><input type='number' className='quantity' min="1"></input></td>
                                        {type.option.map((op, index) =>{
                                            return(
                                                <td className='options-list'>
                                                    <input type="radio" key={index} name="options" id={index} 
                                                    value={op}></input>
                                                    <label for id={index} value={op} className="options">
                                                        {op}
                                                    </label>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </div>
                        )
                    })}
                </table>
            </div>
        </div>
    </div>
    </div>
  )
}
