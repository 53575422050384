/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect} from 'react'
import './imageUploader.scss'
import axios from 'axios'
import {Alert} from 'react-bootstrap'
import { BAST_URL_TEST } from '../../../../../global/constants'


const UPLOAD_ERROR = {
    NO_FILE: "Cannot send! You haven't selected an image.",
    EXCEED_MAX_FILE_SIZE: "Cannot upload! The image size has exceeded the max size of !",
    INVALID_IMAGE_FORMAT: "Cannot upload! Invalid file format!"
}

export default function ImageUploader(props) {
    const [imgFile, setImgFile] = useState(null);
    const [imgPreviewData , setImgPreviewData] = useState(null);
    const [imgPreviewURL, setImgPreviewURL] = useState(null);      
    const [showSnack, setShowSnack] = useState(false)
    const [errorMsg, setErrorMsg] = useState({
        variant: "danger", 
        msg: ""
    });



    /**
     * When there is an errorMsg, prompt the snack
     */
    useEffect(() => {
        if (errorMsg.msg !== "") {
            setShowSnack(true)

            setTimeout(() => {
                setShowSnack(false)
                // [TODO] it will have a blank
                setErrorMsg({
                    variant: "danger", 
                    msg: ""
                })
            }, 3000)


        }
    }, [errorMsg])




    /**
     * Upload image from local to browser
     * @param {*} e 
     */
    function uploadImg(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        // check file size < 1MB
        if (file.size > 1048576) {
            setErrorMsg({
                variant: "danger",
                msg: UPLOAD_ERROR.EXCEED_MAX_FILE_SIZE
            })
        // check file format
        } else if (!["png", "jpg", "jpeg"].includes(file.type.substring(6))) {
            setErrorMsg({
                variant: "danger", 
                msg: UPLOAD_ERROR.INVALID_IMAGE_FORMAT
            })
        } else {
            reader.onloadend = () => {
                setImgFile(file)
                setImgPreviewData(reader.result)
                setImgPreviewURL(URL.createObjectURL(file))
            }
    
            reader.readAsDataURL(file)
        }        
    }


    /**
     * Remove image from browser
     * @param {*} e 
     */
    function removeImg(e) {
        e.preventDefault()
        setImgFile(null)
        setImgPreviewData(null)
        setImgPreviewURL(null)
    }


    /**
     * Send image from browser to SpringBoot server
     * @param {*} e 
     */
    function sendImg(e) {
        e.preventDefault();

        if (!imgFile){
            setErrorMsg( {
                variant: "danger", 
                msg: UPLOAD_ERROR.NO_FILE
            })
        } else {
            const formData = new FormData();
            formData.append("file", imgFile, imgFile.name);
            console.log(BAST_URL_TEST +props.questionType+"/"+props.questionType+'-'+props.questionId +".json")
            axios
            .put(BAST_URL_TEST +props.questionType+"/"+props.questionType+'-'+props.questionId +".json", formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                // upload successfull
                if (response.status === 200) {
                    // show alert
                    setErrorMsg({
                        variant: "success", 
                        msg: response.data.MSG
                    })

                    // get the image path
                    axios
                    .get(BAST_URL_TEST +props.questionType+"/"+props.questionType+'-'+props.questionId +".json")
                    .then(response => {
                        props.questionData.imagePath = response.data.imagePath
                    })
                } else {
                    // upload failed
                    setErrorMsg({
                        variant: "danger", 
                        msg: response.data.MSG
                    })
                }
             
                
            });
        }    
    }





    
    return (
        
        <div id="mn-image-uploader">
            <button className="file-upload-btn" type="button" onClick={(e)=>sendImg(e)}>Send Image</button>
        
            {
                !imgFile && 
                <div className="image-upload-wrap">
                    <input className="file-upload-input" type='file' onChange={(e)=>uploadImg(e)} accept="image/*" />
                    <div className="drag-text">
                        <h3>Drag and drop a file or select add Image</h3>
                    </div>
                </div>
            }
            {
                imgFile && 
                <div className="file-upload-content">
                    <img className="file-upload-image" src={imgPreviewURL} alt="your image" />
                    <p>{imgFile.name}</p>
                    <div className="image-title-wrap">
                        <button type="button" onClick={(e)=>removeImg(e)}className="remove-image">Remove Image</button>
                    </div>
                </div>
            }



            <Alert variant={errorMsg.variant} onClose={() => setShowSnack(false)} show={showSnack} dismissible>
                <div>{errorMsg.msg}</div>
            </Alert>
            
        </div>
    )
}



