/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import './shortCourses.scss'
import {coursesData, courseTitle } from './coursesData'
import {Row, Col} from 'react-bootstrap'

export default function ShortCourses() {
  const [title, setTitle] = useState("All classes")
  return (
    <div id='mn-short-courses'>
         <div className='short-courses-panel'>
            <div className='title p-2'>
                Short Courses
            </div>
            <div className='courses px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                  <div className='courses-list'>
                    {courseTitle.map((t, idx) =>{
                      return(
                        <>
                        <div className='option-list'>
                          <button key={idx} onClick={()=>{setTitle(t)}}
                          className={title === t ? "btn-active" : "btn-unactive"}>
                            <b>{t}</b>
                          </button>
                        </div>
                        </>
                      )
                    })}
                  </div>
                  <br/>
                  <div className='classes-list'>
                  <Row>
                    {title === "All classes" && 
                    <>
                    {coursesData.map(data =>{
                      return(
                        <Col md={4}>
                        <div className='classes-info'>
                          <div className='cover-img'>
                            <img src={process.env.PUBLIC_URL+'/assets/images/cover.avif'} alt="cover_image"/>
                          </div>
                          <p><b>[{data.title}]</b> - {data.description}</p>
                        </div>
                        </Col>
                      )
                    })}
                    </>
                    }
                    {title !== "All classes" &&
                    <>
                     {coursesData.map(data =>{
                      return(
                        <Col md={4}>
                        <div className={ title === data.catergory? 'classes-info': "classes-unactive"}>
                        <div className='cover-img'>
                            <img src={process.env.PUBLIC_URL+'/assets/images/cover.avif'} alt="cover_image"/>
                          </div>
                          <p><b>[{data.title}]</b> - {data.description}</p>
                        </div>
                        </Col>
                      )
                    })}
                    </>
                    }
                  </Row>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}
