/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const PATHS = {
    home: { name: "/" },
    course: { name: "/course" },
    practice: {
        name: "/practice",
        ra: { name: "/ra" },
        rs: { name: "/rs" },
        rl: { name: "/rl" },
        di: { name: "/di" },
        asq: { name: "/asq" },
        swt: { name: "/swt" },
        we: { name: "/we" },
        rfib: { name: "/rfib" },
        rmcm: { name: "/rmcm" },
        rmcs: { name: "/rmcs" },
        ro: { name: "/ro" },
        rwfib: { name: "/rwfib" },
        fib: { name: "/fib" },
        hcs: { name: "/hcs" },
        hiw: { name: "/hiw" },
        mcm: { name: "/mcm" },
        mcs: { name: "/mcs" },
        smw: { name: "/smw" },
        sst: { name: "/sst" },
        wfd: { name: "/wfd" }
    },
    admin: {
        name: "/admin"
    },
    memory: { name: "/memory" },
    community: { name: "/community" },
    mock: {
        name: "/mock",
        listeningTest: { name: "/listeningTest" },
        readingTest: { name: "/readingTest" },
        writingTest: { name: "/writingTest" },
        speakingTest: { name: "/speakingTest" },
        vipTest: { name: "/vipTest" },
        modelTest: { name: '/modelTest' },
        customModelTest: { name: "/customModelTest" },
        vipModelTest: { name: "./vipModelTest" }
    },
    predict: { name: "/predict" },
    stream: { name: "/stream" },
    login: { name: "/login" },
    pteInfo: { name: "/pteInfo" },
    shortCourses: { name: "/shortCourses" },
    tutoring: { 
        name: "/tutoring",
        schedule: {name: "/schedule"} 
    },
    vocabulary: {
        name: "/vocabulary",
        bookPage: { name: "/bookPage" }
    },
    myCollections: {
        name: "/myCollections",
        writeCollect: { name: "/writingCollection" },
        readCollect: { name: "/readingCollection" },
        listenCollect: { name: "/listeningCollection" },
        speakCollect: { name: "/speakingCollection" }
    },
    dailyPlan: {
        name: "/dailyPlan",
        makePlan: { name: "/makePlan" }
    },
    pteResearch: { name: "/pteResearch" },
    analysis: { name: "/analysis" },
    practiceRecord: { name: "/praticeRecord" },
    studyGroups: { name: "/studyGroups" },
    experienceSharing: { name: "/experienceSharing" },
    commonQuestions: { name: "/commonQuestions" },
    setting: { name: "/setting" },
    exit: { name: '/exit' }
};



// API (production)
//export const BASE_URL = "https://9scoretrain.com/pte-files/questions/"
export const BASE_URL = "https://cowpte.com:8702/api/v1/questions/"

export const BASE_URL_AUTH = "https://cowpte.com:8701/api/v1/user/"
// if we need an API list passed from backend
export const API_REGISTER_BY_EMAIL = BASE_URL_AUTH + "registerByEmail"
export const API_LOGIN_BY_EMAIL = BASE_URL_AUTH + "loginByEmail"
export const API_FORGOT_PASSWORD = BASE_URL_AUTH + "user/forgotPassword"






export const BASE_URL_2 = "http://192.168.20.6:8702/api/v1/questions/"
// API (test)
export const API_ACCOUNT_URL = "http://localhost:8701/api/v1/accounts/"
// export const BASE_URL = "http://localhost:8081/api/v1/"
//export const BASE_URL = "http://192.168.137.1:8181/"

export const BAST_URL_TEST = "https://9scoretrain.com/pte-files/questions/"
