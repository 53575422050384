/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect, useState} from 'react'
import { QuestionWrapper } from '../questionWrapper'
import {Button} from 'react-bootstrap'
import "./practiceRWFIBPage.scss"
//import Rwfib from './rwfib'

/**
 * RWFIB Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    let contentLst =[]
    const ansLst =[]
    var lastState = false
    const [state, setState] = useState(0)
    var count = 0
    
    function rwfib_transform_paragraph(paragraph) {
        //const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        if (contentLst.length !== 0) {
            contentLst = []
        }
        const new_eles = rwfib_insert_answerbox(paragraph)
        const eles = new_eles.split("<answer box>")
        count = eles.length - 1
        
        eles.map(ele => {
            contentLst.push(ele)
        })
        return contentLst
    }
    
    function rwfib_insert_answerbox(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        const eles = paragraph.split(" ")
        
        const new_eles = eles.map((ele, idx) => {
            if (punctuations.includes(ele)) {
                return " ";
            } 
            else if (ele.includes("</select>")) {
              eles[idx] = ele + " " + "<answer box>"
              return  eles[idx] 
            } else{
                return  ele;
            }
            
        })
        return new_eles.join(" ");
    }

    function getAnswLst(paragraph){
        const eles = paragraph.split("</option>")
        eles.map(ele=>{
            if(ele.includes("<option answer>"))
            {   
                const v = ele.replace('<option answer>',"")
                ansLst.push(v)
            }
        })
        return ansLst
    }

    function checkCorrectAns(){
        if(state === 0){
            setState(1)
        }else if(state === 1){
            setState(0)
        }
        const answers = document.getElementsByClassName('answer')
        const answ_list = getAnswLst(props.questionData.content)
        Array.from(answers).forEach(txt =>{
            txt.innerHTML = answ_list[txt.id]
        })
    }

    function lastStatus(index) {
        if(state===0)
        {
            lastState = true
        }
        else{
            if (count === index) {
                lastState = true
            } else { lastState = false }
        }
        return lastState
    }

    useEffect(() => {
        setState(0)
    },[props.questionId])

    return (
        <>
        <div>
            {
                props.questionData && 
                /*[TODO] JSX自带Unicode 的转码，但是有被 XSS攻击 的危险，需要在后台做过滤*/
                <div>
                    {rwfib_transform_paragraph(props.questionData.content).map((txt, idx) =>{
                        return(
                            <>
                            <p id='content' key={idx} dangerouslySetInnerHTML={{__html:txt}}></p>
                            <p className='answer' id={idx}
                            style={lastStatus(idx) === true ? { display: "none" } : { display: "inline-block" }}>
                            </p>
                            </>
                        )
                    })}
                    <br/>
                    <Button variant="outline-primary" onClick={checkCorrectAns}>Check answer</Button>
                </div>
                
            }
            {
                !props.questionData && <>
                <p>Is loading...</p>
                </>
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRWFIBPage() {
    return (
        <QuestionPage questionType="rwfib" />
    )
}