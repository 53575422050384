/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export function getTypeUpper(slug) {
  var res = slug.split("-");
  return res[0].toUpperCase();
}

export function getTypeLower(slug) {
    var res = slug.split("-");
    return res[0].toLowerCase();
}

export function getQuestionID(slug) {
    var res = slug.split("-");
    return parseInt(res[1]);
}


export const questionTabEnum = {
  questionContent: "questionContent",
  myPractices: "myPractices",
  allPractices: "allPractices",
  allComments: "allComments",
  questionAnalysis: "questionAnalysis"
}




