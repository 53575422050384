/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import FixFooter from '../general/fixFooter/fixFooter'
import FixSidebar from '../general/fixSidebar/fixSidebar'
import Footer from '../general/footer/footer'
import Nav from '../general/nav/nav'
import { data } from './data'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import './communityPage.scss'



export default function CommunityPage() {
    return (
        <div>
            <Nav />
            <main id="mn-community-page">
                <div className="bg"><img src={process.env.PUBLIC_URL + "/assets/images/bg.avif"} alt="bg-img"></img></div>
                <div className='body'>
                    <div className='community-list'>
                        <h4><b>PTE's discussion</b></h4>
                        <div className='community-item'>
                            {data.map(d => {
                                return (
                                    <div className='community-post' key={d.id}>
                                        <div className='post-header'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="post-icon"></img>
                                            <div className='post-body'>
                                                <h5><b>{d.title}</b></h5>
                                                <p>{d.des}</p>
                                                <div className='member-icon'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="user-icon"></img>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="user-icon"></img>
                                                    <AiOutlinePlusCircle />
                                                </div>
                                            </div>
                                            <button className='join-btn'>Join discussion</button>

                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <br />
                        <h4><b>Questions and Answers</b></h4>
                        <div className='community-item'>
                            {data.map(d => {
                                return (
                                    <div className='community-post' key={d.id}>
                                        <div className='post-header'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="post-icon"></img>
                                            <div className='post-body'>
                                                <h5><b>{d.title}</b></h5>
                                                <p>{d.des}</p>
                                                <div className='member-icon'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="user-icon"></img>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/userIcon.avif"} alt="user-icon"></img>
                                                    <AiOutlinePlusCircle />
                                                </div>
                                            </div>
                                            <button className='join-btn'>Join discussion</button>

                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <FixSidebar />
            <FixFooter />
        </div>
    )
}
