/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {BiRightArrowAlt} from 'react-icons/bi'
import './mockPage.scss'
import {Link} from 'react-router-dom'
import {data} from './data.js'
import 'styled-components/macro'


export default function MockPage() {
    return (
        <div id="mn-mock">
            <div className="practice-panel">
                <div className="header">
                    <img src={process.env.PUBLIC_URL+'/assets/images/mock-banner.avif'} alt="mock-banner" />
                </div>
                <div className="body p-5">
                    <Row>
                        {
                            data.map((d, idx) => {
                                return (
                                <Col md={6} className="p-2" key={idx}>
                                    <div className="p-3" css={`color: ${d.bgColor};`}>
                                        <div className='icon'>{d.icon}</div>
                                        <h4>{d.title}</h4>
                                        <p>{d.des}</p>
                                        <Link 
                                            to={d.path} 
                                            css={`color: ${d.color}; background-color: ${d.bgColor}`}
                                        >
                                            <div>Take a test <BiRightArrowAlt /></div>
                                        </Link>
                                    </div>  
                                </Col>
                                )
                            })
                        }
                    </Row>
                </div>

                <div className="bottom p-5">
                    <div className='left'>
                        <h4>Transcript of history model test</h4>
                        <p>Directly view past score analysis sheets and get AI intelligent suggestions...</p>
                    </div>

                    <div className="right">
                        <Link to="">
                            <div> To check<BiRightArrowAlt /></div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
