/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './footer.scss'
import {Container, Row, Col} from 'react-bootstrap'
import {Trans, withTranslation} from 'react-i18next'
import QRBlock from './qrBlock'
import {ImYoutube2} from 'react-icons/im'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTaobaoCircle} from 'react-icons/ai'
import {qrData} from './data'


function Footer() {

    return (
        <footer id="mn-footer" className="pt-5">
            <Container> 
                <Row>
                    <Col md="3">
                        <img src={"./assets/images/mn_logo_white.avif"} alt="" className="mb-3"/>
                        <p>Makes study more enjoyable</p>

                        
                    </Col>
                    <Col md="3">
                        <h4>Contact us</h4>
                        <p>Tel: +61 451 261 860</p>
                        <p>Email：noreply@ptetrain.com</p>
                        <p>Address：Canberra，Australia</p>
                    </Col>
                    <Col md="3">
                        <h4>Useful links</h4>
                        <p><a href="https://pearsonpte.com/" target="_blank" rel="noreferrer" ><Trans>Officail registration</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/help-support/tools/points-calculator" target="_blank" rel="noreferrer" ><Trans>Immigration points</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/visas/visa-pricing-estimator" target="_blank" rel="noreferrer" ><Trans>Visa fee calculation</Trans></a></p>
                        <p><a href="https://pearsonpte.com/wp-content/uploads/2019/02/PTE-Academic-Test-Taker-Handbook-Simplified-Chinese.pdf" target="_blank" rel="noreferrer" ><Trans>Candidate handbook</Trans></a></p>
                        <p><a href="ptetrain.com" target="_blank">Consult</a></p>
                    </Col>
                    <Col md="3">
                        <h4>Cooperation</h4>
                        <p><a href="ptetrain.com">Business cooperation</a></p>
                        <p><a href="ptetrain.com">Join as tutor</a></p>
                        <p><a href="ptetrain.com">Join the team</a></p>
                    </Col>
                </Row>
                <hr className="my-3"></hr>
                <Row>
                    <Col md="6" className="mb-3">
                        <p>Follow us</p>
                        <a href="https://tutehub.taobao.com/" target="_blank" rel="noreferrer" ><AiFillTaobaoCircle /></a>
                        <a href="https://www.youtube.com/channel/UCTSEzPwITH1HC19cK6_rDEQ" target="_blank" rel="noreferrer" ><ImYoutube2/></a>
                        <a href="https://www.facebook.com/Fengcao-Culture-109633237637604" target="_blank" rel="noreferrer" ><FaFacebook/></a>
                    </Col>
                    <Col md="6">
                        <Row>
                        {
                        qrData.map((qr, idx) =>(
                            <QRBlock 
                            key = {idx}
                            img = {qr.img}
                            des = {qr.des}
                            />
                        ))
                        }
                        </Row>
                    </Col>
                </Row>
                <div className="law">
                    <div className="copy">Copyright by <Trans>COW PTE</Trans>@ {new Date().getFullYear()} </div>
                    <div className="policies">
                        <p className="mr-5"><a href="ptetrain.com"><Trans>Privacy Policy</Trans></a></p>
                        <p className="mr-5"><a href="ptetrain.com"><Trans>Terms of usage</Trans></a></p>
                    </div>
                </div>
       
            </Container>
        </footer>
    )
}

export default withTranslation()(Footer);