/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import {RiVipCrownLine} from 'react-icons/ri'
import {FiHeadphones, FiBook, FiPenTool, FiMic} from 'react-icons/fi'
import { PATHS } from '../../../global/constants'

export const data = [
    {
        title: "Public Listening test",
        des: "Real exam process, AI score evaluation",
        color: "rgb(71, 110, 255)",
        bgColor: "rgb(198, 223, 255)",
        icon: <FiHeadphones />,
        path: PATHS.admin.name+PATHS.mock.listeningTest.name
    },
    {
        title: "Public Reading test",
        des: "Real exam process, AI score evaluation",
        color: "rgb(255, 120, 71)",
        bgColor: "rgb(255, 207, 198)",
        icon: <FiBook />,
        path: PATHS.admin.name+PATHS.mock.readingTest.name
    },
    {
        title: "Public Writing test",
        des: "Real exam process, AI score evaluation",
        color: "rgb(249, 71, 255)",
        bgColor: "rgb(239, 198, 255)",
        icon: <FiPenTool />,
        path: PATHS.admin.name+PATHS.mock.writingTest.name
    }, 
    {
        title: "Public Speaking test",
        des: "Real exam process, AI score evaluation",
        color: "rgb(71, 255, 148)",
        bgColor: "rgb(198, 255, 206)",
        icon: <FiMic />,
        path: PATHS.admin.name+PATHS.mock.speakingTest.name
    },
    {
        title: "VIP mock test",
        des: "Multiple sets of Manniu PTE mock test papers, with additional custom model test question patterns",
        color: "rgb(230, 147, 54)",
        bgColor: "rgb(255, 238, 219)",
        icon: <RiVipCrownLine />,
        path: PATHS.admin.name+PATHS.mock.vipTest.name
    }

]

export const mockTestData=[
    { 
        title: "Public model test",
        des: "Real exam process, AI score evaluation",
        color: "rgb(71, 110, 255)",
        bgColor: "rgb(198, 223, 255)",
        path: PATHS.admin.name+PATHS.mock.modelTest.name
    },
    { 
        title: "VIP model test 3-piece set",
        des: "3 set of mock exams | Difficulty grades for simulated real exams",
        color: "rgb(230, 147, 54)",
        bgColor: "rgb(255, 238, 219)",
        path: PATHS.admin.name+PATHS.mock.vipModelTest.name
    },
    { 
        title: "VIP custom model test",
        des: "Flexible self-selected questions | Get ability evaluation reports in different scenarios",
        color: "rgb(255, 120, 71)",
        bgColor: "rgb(255, 207, 198)",
        path: PATHS.admin.name+PATHS.mock.customModelTest.name
    }
]