/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const bookData = [
    {
        id: '1',
        title: 'Book 1 Title',
        description: "This the book's description",
        newword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ],
        masteredword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ]
    },
    {
        id: '2',
        title: 'Book 2 Title',
        description: "This the book's description",
        newword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ],
        masteredword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ]
    },
    {
        id: '3',
        title: 'Book 3 Title',
        description: "This the book's description",
        newword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ],
        masteredword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ]
    },
    {
        id: '4',
        title: 'Book 4 Title',
        description: "This the book's description",
        newword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ],
        masteredword:[
            "1. word1",
            "2. word2",
            "3. word3",
            "4. word4"
        ]
    }
]