/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


import {loginStateEnum, requestModeEnum} from "../components/login/data.js"
import { API_LOGIN_BY_EMAIL, API_ACCOUNT_URL } from "../global/constants.js";
import axios from 'axios'
import { useHistory } from "react-router-dom";


class Auth {

    constructor() {
        this.authenticated = false;
        this.gmailLoginAuthenticated = false;
        this.gmailUser={};
        this.userInfo={}
        this.userInfoMGDB =[];
    }
    
    

    login(cb) {
        this.authenticated = true;
        if (typeof cb === 'function') {
            cb();
        }
    }

    logout(cb) {
        this.authenticated = false;
        if (typeof cb === 'function') {
            cb();
        }
    }

    gmailLogin(user){
        this.authenticated = true;
        this.gmailLoginAuthenticated = true;
        this.gmailUser = user;
    }

    gmailLogout(){
        this.authenticated = false;
        this.gmailLoginAuthenticated = false;
        this.gmailUser = {};
    }

    userLogout(){
        this.authenticated = false;
        this.userInfo={};
    }
    /**
     * Call backend API
     * Email mode
     */
    enableFormSubmitBtn(errorMsgs) {
        for (const [key, value] of Object.entries(errorMsgs)) {
            if (value !== "") {
                return false;
            }
        }
        return true;
    }

    registerUserEmail(payload, cb) {
        payload.mode = requestModeEnum.usernameEmailRegister
        
        console.log(payload)


        if (typeof cb === 'function') {
            cb();
        }
        
    }

    resetPasswordEmail(payload, cb) {
        if (payload.username !== "" && payload.email === "") {
            payload.mode = requestModeEnum.USERNAME_FORGET

        } else if (payload.username === "" && payload.email !== ""){
            payload.mode = requestModeEnum.EMAIL_FORGET
        }
        
        
        if (typeof cb === 'function') {
            cb();
        }
    }


    loginEmail(payload, cb) {
        if (payload.username !== "" && payload.email === "") {
            payload.mode = requestModeEnum.USERNAME_LOGIN

        } else if (payload.username === "" && payload.email !== ""){
            payload.mode = requestModeEnum.EMAIL_LOGIN
        }


        if (typeof cb === 'function') {
            cb();
        }

    }

    /**
     * Login API from local MongoDB
     */
    fetchAPI(){
        
        axios.get(API_ACCOUNT_URL)
        .then((res) =>{
            this.userInfoMGDB = res.data
            console.log(this.userInfoMGDB)
        })
        .catch(err => console.log(err))
}

    loginAPI(info){
        console.log(info)
        this.fetchAPI()
        this.userInfoMGDB.map((d) =>{
            if(d.password === info.password){
                this.authenticated = true
            }
        })
    }

    /**
     * Login API from URL
    */
    loginByEmail(info){
        console.log(info)
        axios.post(API_LOGIN_BY_EMAIL,{email: info.email, password: info.password})
        .then((res) =>{
            this.userInfo= res.data.object
            console.log(this.userInfo)
            if(this.userInfo !== null)
                this.authenticated = true
            else
                this.authenticated = false            
        })
        .catch(err => console.log(err))
    }


    registerByEmail(userInfo) {

    }

    forgetPassword(userInfo) {
        
    }
}

export default new Auth();