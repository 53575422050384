/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React,{useState, useEffect} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import { QuestionWrapper } from '../questionWrapper'
import "./practiceRFIBPage.scss"
//import Rfib from './rfib'
import Blanks from './blanks'
import {Form, Button} from 'react-bootstrap'

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    //const [optionLst, setLst] = useState(props.questionData.options)

    var lastState = false
    const [state, setState] = useState(0)
    var count = 0
    const ansLst =[]
    let sentences = []

    function allowDrop(ev) {
        ev.preventDefault();
    }
    
    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }
    
    function drop(ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
    }

    function rfib_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]

        const eles = paragraph.split(" ")

        const new_eles = eles.map((ele, idx) => {
            if (punctuations.includes(ele)) {
                return;
            } else if (ele.includes("<answer>")) {
                if(!ele.includes("</answer>")){
                    eles[idx +1] = " "
                }
                return "<blank>"
            }else if (ele.includes("</answer>")) {
                return ""
            }  else {
                return ele;
            }
        })
        return new_eles.join(" ");
    }

    function rfib_blank_insert(text) {
        if (sentences.length !== 0) {
            sentences = []
        }
        const content = rfib_transform_paragraph(text)

        const eles = content.split("<blank>")
        count = eles.length - 1
        eles.map(ele => {
            sentences.push(ele)
        })
        return sentences
    }

    function getAnswLst(paragraph){
        const eles = paragraph.split(" ")
         eles.map((ele, idx)=>{
            if(ele.includes("<answer>"))
            {   
                if(ele.includes("</answer>")){
                    const v = ele.replace('<answer>',"").replace("</answer>","")
                    ansLst.push(v)
                }
                else{
                    const pos = idx + 1
                    let text = ele.replace("<answer>","")
                    if(eles[pos].includes("</answer>")){
                        const v2 = eles[pos].replace('</answer>',"")
                        text = text + " " + v2
                        ansLst.push(text)
                    }
                    else {
                        text = text + " " + eles[pos]
                        if(eles[pos +1].includes("</answer>")){
                            const v3 = eles[pos + 1].replace("</answer>","")
                            text = text +" "+ v3
                        }
                        ansLst.push(text)
                    }
                }
            }
        })
        console.log(ansLst)
        return ansLst
    }

    function lastStatus(index){
        if (count === index) {
            lastState = true
        } else { lastState = false }
        return lastState
    }

    function lastStatus2(index) {
        if(state===0)
        {
            lastState = true
        }
        else{
            if (count === index) {
                lastState = true
            } else { lastState = false }
        }
        return lastState
    }

    function checkCorrectAns(){
        if(state === 0){
            setState(1)
        }else if(state === 1){
            setState(0)
        }
        const answers = document.getElementsByClassName('box2')
        const answ_list = getAnswLst(props.questionData.content)
        Array.from(answers).forEach(txt =>{
            txt.innerHTML = answ_list[txt.id]
        })
    }
    
    useEffect(() => {
        setState(0)
    },[props.questionId])

    return (
        <>
        <div>
            {
                props.questionData &&
                <>
                    <div className='content'>
                        {rfib_blank_insert(props.questionData.content).map((txt, idx) => {
                            return (
                                <>
                                    {txt}
                                    <Blanks lastStatus={lastStatus(idx)} />
                                    <p className='box2' id={idx}
                                    style={lastStatus2(idx) === true ? { display: "none" } : { display: "inline-block" }}>
                                    </p>
                                </>
                            )
                        })}
                    </div>
                    <br/>
                    <table className='answer' onDrop={drop} onDragOver={allowDrop}>
                        <tbody>
                        <tr>
                            {props.questionData.options.map((op, idx) =>{
                                return(
                                <td className='textboxes' draggable="true" onDragStart={drag} key={idx +1} id={op}>
                                    {op}
                                </td>
                                )
                            })}
                            
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <Button variant="outline-primary" onClick={checkCorrectAns}>Check answers</Button>
                </>
            }
            {
                !props.questionData &&
                //<Rfib slideIndex={props.questionId} data={props.questionData}/>
                "Is loading..."
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRFIBPage() {
    return (
        // 
        <QuestionPage questionType="rfib" />
    )
}