/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import "styled-components/macro";
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './material.scss';
import HomeTitle from '../../general/title/homeTitle';
import MaterialItem from './materialItem'

const data = [
    {
        title: "PTE predicts PDF", 
        des: "Update every 2 weeks", 
        action: "Download"
    },
    {
        title: "Exam memories", 
        des: "Update every 2 weeks", 
        action: "Download"
    },
    {
        title: "Writing examples", 
        des: "Updated till 202301", 
        action: "Download"
    },
    {
        title: "Listening examples", 
        des: "Updated till 202301", 
        action: "Download"
    }
]
export default function Material() {
    return (
        // 蛮牛-备考资料
        <section id="mn-material" className="py-5">
            <Container>  
                
                <HomeTitle 
                title = "Updated study materials"
                des = "We collected latest exam information for you."
                />
                <div className="material-list">
                    {
                        data.map((item, idx) => (
                            <MaterialItem 
                            key = {idx}
                            title = {item.title}
                            des = {item.des}
                            action = {item.action}
                            />
                        ))
                    }
      
                </div>
            </Container>
        </section>
    )
}

