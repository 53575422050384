/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { Trans, withTranslation} from 'react-i18next';
import './fixSidebar.scss'
import {FcConferenceCall, FcCollapse, FcFeedback, FcHeadset, FcPortraitMode, FcLikePlaceholder, FcMindMap} from 'react-icons/fc'

function FixSidebar() {
    function scrollToTop(){
        document.documentElement.scrollTop = 0
    }

    return (
        <div id="mn-fixed-sidebar" className="text-center">
            <div className="fixed-nav-item">
                <FcLikePlaceholder />
                <p className="item-text"><Trans>Downloads</Trans></p>
            </div>
            <a className="fixed-nav-item" href="https://tutehub.taobao.com" target="_blank" rel="noreferrer">
                <FcPortraitMode />
                <p className="item-text"><Trans>Consult</Trans></p>
            </a>
            <div className="fixed-nav-item">
                <FcMindMap />
                <p className="item-text"><Trans>Study group</Trans></p>
                <img className="service-code" src="./assets/images/serviceWechatQR.avif" alt="PTE,蛮牛PTE,PTE考试,蛮牛PTE名师学霸群" />
            </div>
            <div className="fixed-nav-item">
                <FcConferenceCall />
                <p className="item-text"><Trans>Be a tutor</Trans></p>
            </div>
            <FcCollapse onClick={scrollToTop}/>
            <div className="expand-handle" id="expandHandle"></div>
        </div>
    )
}


export default withTranslation()(FixSidebar)