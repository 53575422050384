/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import './courseSection.scss'
import SectionTitle from './sectionTitle'
import SectionBody from './sectionBody'
import { withTranslation } from 'react-i18next'



function CourseSection(props) {
    const [tabKey, setTabKey] = useState(props.tabs[0])

    function setNewTabKey(newValue) {
        setTabKey(newValue)
    }
    return (

        <section id="mn-course-section" className="py-5">
            <SectionTitle 
            title = {props.title}
            des = {props.des}
            tabs = {props.tabs} 
            tabKey = {tabKey}
            setNewTabKey = {setNewTabKey}
            />
            {
                props.tabs.map((tab, idx) => (
                    <SectionBody 
                    tab = {tab} 
                    tabKey={tabKey}
                    slides={props.slides[idx]}
                    />
                ))
            }
            
        </section>
    )
}


export default withTranslation()(CourseSection)