/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import 'styled-components/macro'
import './vocabularyPage.scss'
import { bookData } from './bookData'
import { Button } from 'react-bootstrap'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../global/constants'

export default function VocabularyPage() {
 return (
    <div id='mn-vocabulary'>
      <div className='vocabulary-panel'>
        <div className='title p-2'>
          Word book
        </div>
        <div className='books px-5 py-3'>
          <ul className="mb-3 px-5 py-3">
            {bookData.map(book =>{
                  return(
                    <>
                    <li className='books-list'>
                    <img src={process.env.PUBLIC_URL+'/assets/images/vocabulary-cover.avif'} alt='book-1.avif'/>
                      <div className='list-tab'>
                        <div key={book.id}>
                          <h5>{book.title}</h5>
                          <p>{book.description}</p>
                        </div>
                        <Link to={PATHS.admin.name+PATHS.vocabulary.bookPage.name}>
                        <Button id='button'>Start</Button>
                        </Link>
                      </div>
                    </li>
                    <br/>
                    </>
                  )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
