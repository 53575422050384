/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import {AiOutlineRight, AiOutlineDown} from 'react-icons/ai'
import { generalInfo, reasonInfo, tipInfo } from './data'
import './pteInfo.scss'

export default function PTEInfo() {
    const [showInfo, setShowInfo] = useState("")
  return (
    <div id='mn-pte-info'>
        <div className='pte-info-panel'>
            <div className='title p-2'>
                PTE Info
            </div>
            <div className='infos px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <h5><b>What is PTE?</b></h5>
                    <div className='body'>
                        <ul className='pte-info-list'>
                            {generalInfo.map(info =>{
                                return(
                                    <li className='info-list' key={info.id}>
                                    <b>{info.ques}</b>
                                    {info.ans.id !== showInfo &&
                                        <button className="show-ans" onClick={() =>{setShowInfo(info.id)}}>
                                        <AiOutlineRight />
                                        </button>
                                    }
                                    {info.ans.id === showInfo && 
                                    <>
                                        <button className="show-ans" onClick={() =>{setShowInfo("")}}>
                                        <AiOutlineDown />
                                        </button>
                                    
                                        <div className="ans-list">{info.ans.content}</div>
                                    </>
                                    }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <h5><b>Why should I take PTE exam?</b></h5>
                    <div className='body'>
                        <ul className='pte-info-list'>
                            {reasonInfo.map(info =>{
                                return(
                                    <li className='info-list' key={info.id}>
                                    <b>{info.ques}</b>
                                    {info.ans.id !== showInfo &&
                                        <button className="show-ans" onClick={() =>{setShowInfo(info.id)}}>
                                        <AiOutlineRight />
                                        </button>
                                    }
                                    {info.ans.id === showInfo && 
                                    <>
                                        <button className="show-ans" onClick={() =>{setShowInfo("")}}>
                                        <AiOutlineDown />
                                        </button>
                                    
                                        <div className="ans-list">{info.ans.content}</div>
                                    </>
                                    }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <h5><b>Tips and tricks for PTE exam</b></h5>
                    <div className='body'>
                        <ul className='pte-info-list'>
                            {tipInfo.map(info =>{
                                return(
                                    <li className='info-list' key={info.id}>
                                    <b>{info.ques}</b>
                                    {info.ans.id !== showInfo &&
                                        <button className="show-ans" onClick={() =>{setShowInfo(info.id)}}>
                                        <AiOutlineRight />
                                        </button>
                                    }
                                    {info.ans.id === showInfo && 
                                    <>
                                        <button className="show-ans" onClick={() =>{setShowInfo("")}}>
                                        <AiOutlineDown />
                                        </button>
                                    
                                        <div className="ans-list">{info.ans.content}</div>
                                    </>
                                    }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
