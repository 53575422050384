/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { PATHS } from '../../../global/constants'
import {navTestData} from './data'


function NavPractice() {

    return (
        <div className="practice-list">
            {
                navTestData.map((section, idx) => (
                    <div key={idx} className="practice-list-item">
                        <div className="item-head">{section.headImgName}<span>{section.head}</span></div>
                        {
                            section.subs.map((sub, idx) => (
                                <div key={idx} className="item-content"><a className="isLogin" href={`${PATHS.practice.name}${sub.path}`}><span>{sub.name}</span>{sub.ai}</a></div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default NavPractice
