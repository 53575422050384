/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const planData = []

export const planTitle = [
    "Speak", "Read", "Write", "Listen"
]

export const questionType = [
    {
        name: "Speak",
        type: [
            'Read Aloud', 'Repeat Sentence', 'Describe Image', 'Re-tell Lecture', 'Answer Short Question'
        ],
        option: [
            'Type1', 'Type2', 'Type3', 'Type4'
        ]
    },
    {
        name: "Read",
        type: [
            'R&W：Fill in the Blanks', 'R：Multiple Choice Questions (Multiple)', 'Re-order Paragraphs', 'R：Fill in the Blanks',
            'R：Multiple Choice Questions (Single)'
        ],
        option: [
            'Type1', 'Type2', 'Type3', 'Type4'
        ]
    },
    {
        name: "Listen",
        type: [
            'Write From Dictation', 'Summarize Spoken Text', 'L：Multiple Choice Questions (Multiple)', 'L：Fill in the Blanks',
            'Highlight Correct Summary', 'L：Multiple Choice Questions (Single)', 'Select Missing Words', 'Highlight Incorrect Words'
        ],
        option: [
            'Type1', 'Type2', 'Type3', 'Type4'
        ]
    },
    {
        name: "Write",
        type: [
            'Summarize Written Text', 'Write-essay'
        ],
        option: [
            'Type1', 'Type2', 'Type3', 'Type4'
        ]
    }
]