/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import { researchTitle, researchClasses } from './researchData'
import {Row, Col} from 'react-bootstrap'
import './pteResearch.scss'

export default function PTEResearch() {
    const[title, setTitle] = useState("All")
  return (
    <div id="mn-pte-research">
        <div className='pte-research-panel'>
            <div className='title p-2'>
                PTE Research
            </div>
            <div className='researches px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='slide-container'>
                        {researchTitle.map((t, idx) =>{
                            return(
                                <div key={idx} className={title === t ? 'my-slides' : "title-unactive"}>
                                    <button onClick={() =>{setTitle(t)}}><b>{t}</b></button>
                                </div>
                            )
                        })}
                    </div>
                    <div className='classes-list'>
                        <Row>
                            {researchClasses.map(data =>{
                                return(
                                    <Col md={4} key={data.id}>
                                        <div className='classes-info'>
                                            <img src={process.env.PUBLIC_URL+'/assets/images/cover.avif'} alt="cover_image"/>
                                            <p><b>[{data.title}]</b> - {data.des}</p>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
