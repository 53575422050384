/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const teacherData=[
    {
        id: "tutor-1",
        name:"Tutor's name",
        degree:"Tutor's degree",
        des:"Tutor's description",
        info:"Tutor info"
    },
    {
        id: "tutor-2",
        name:"Tutor's name",
        degree:"Tutor's degree",
        des:"Tutor's description",
        info:"Tutor info"
    },
    {
        id: "tutor-3",
        name:"Tutor's name",
        degree:"Tutor's degree",
        des:"Tutor's description",
        info:"Tutor info"
    }
]

export const legendData=[
    {
        label:"Booked",
        color:"rgb(201, 46, 60)",
    },
    {
        label:"Vacant",
        color:"rgb(104, 209, 92)",
    }
]
 
export const scheduleDate=[
    {
        month: "1",
        date:[
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: false 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: false 
            }
        ]
    },
    {
        month: "2",
        date:[
            {
                day:"09/01",
                booked: false 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: false 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: false 
            }
        ]
    },
    {
        month: "3",
        date:[
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: true 
            },
            {
                day:"09/01",
                booked: false 
            }
        ]
    }
]

export const dateTitle=[
    {
        date:"09/01"
    },
    {
        date:"09/01"
    },
    {
        date:"09/01"
    },
    {
        date:"09/01"
    },
    {
        date:"09/01"
    }
]