/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import { QuestionWrapper } from '../questionWrapper'
import './practiceROPage.scss'
//import Ro from './ro'
import {Form, Button} from 'react-bootstrap'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const [answered, setAns] = useState(0)
    const [state, setState] = useState(0)
    let order = []
    let dataLst = []

    function drop(e){
        e.preventDefault();
        var data = e.dataTransfer.getData("text");
        e.target.appendChild(document.getElementById(data));
    }
    function drag(e){
        e.dataTransfer.setData("text", e.target.id);
    }
    function allowDrop(e){
        e.preventDefault();
        setAns(0)
    }
    function allowDrop2(e){
      e.preventDefault();
      setAns(1)
    }

    function ro_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        
        dataLst.push(paragraph)
        const eles = paragraph.split(" ")
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            } 
            else if (ele.includes("<answer")) {
              const v = ele.replace("<answer", "").replace("</answer>", "")
              return  v 
            } else if(ele.includes("order")){
              return ""
            } else if(ele.includes("</answer>")){
              const v=ele.replace('</answer>',"")
              return v
            } else{
                return  ele;
            }
        })
        return new_eles.join(" ");
    }

    function ro_ordered_paragraph(paragraph){
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        const eles = paragraph.split(" ")
        
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return " ";
            } 
            else if (ele.includes("<answer")) {
              const v = ele.replace("<answer", "").replace("</answer>", "")
              return  v 
            } else if(ele.includes("order")){
              const v = ele.replace("order=>", "")
              return  v 
            } else if(ele.includes("</answer>")){
              const v=ele.replace('</answer>',"")
              return v
            }else{
                return  ele;
            }
            
        })
        return new_eles.join(" ");
    }

    function checkCorrectAns(){
      const content = document.getElementsByClassName('source')
      const result = document.getElementById('result')
      var ans = ""
      var i =0
      if(state === 0)
      { setState(1)}
      else{ setState(0)}
      if(answered===0)
      { result.innerHTML = 'Please answer the question first.'}
      else if(answered === 1)
      {
        
        Array.from(content).forEach(ans =>{
          const answer = ans.getAttribute('value')
          order.push(answer)
        })
        order.sort((a,b) => a<b ? -1:1)
        console.log(order)
        for( i = 0; i< order.length; i++){
          ans += order[i] + "<br/>"
        }
        
        result.innerHTML= "Correct order:"+  "<br/>"+ ans
        setAns(2)
      }
    }

    useEffect(()=>{
        setState(0)
    },[props.questionId])

    return (
        <>
        <div>
            {
                props.questionData && 
                <>
                <div className='row'>
                <div className='column'>
                <b style={{marginLeft: "250px"}}> Source</b>
                {props.questionData.content.map((data, index) =>{
                    return(
                    <>
                    <div key={index}>
                        <table onDrop={drop} onDragOver={allowDrop}>
                            <tr>
                                <td>
                                <div id={index + 1} key={index} className='source' draggable="true" onDragStart={drag} value={ro_ordered_paragraph(data)}>
                                    {ro_transform_paragraph(data)}
                                </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
                    )
                })}
                </div>
                <div className='column'>
                <b style={{marginLeft: "250px"}}> Target</b>
                    <table className='target' onDrop={drop} onDragOver={allowDrop2}>
                    {dataLst.map(op =>{
                        return(
                            <tbody>
                            <tr>
                                <td> </td>
                            </tr>
                            </tbody>
                        )
                    })}
                    </table>
                </div>
                </div>
                <Button variant="outline-primary" onClick={checkCorrectAns}>Check answers</Button>
                <p id='result' style={state === 1 ? {display:'inline-flex'}:{display:'none'}}></p>
                </>
            }
            {
                !props.questionData && 
                <>
                <p>Is loading...</p>
                </> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeROPage() {
    return (
        // 
        <QuestionPage questionType="ro" />
    )
}