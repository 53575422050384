/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import CourseItem from './courseItem'
import {Container, Row} from 'react-bootstrap'

const data = [

    {
        title: "Beginer Class", 
        subTitle1: "Essential courses for Xiaobai, overview of 20 PTE question types", 
        subTitle2: "Suitable for: PTE novice or IELTS transfer test taker",
        style: {background: "linear-gradient(#d5e0fc 0%, #9cade8 100%)"}
    },
    {
        title: "Intermediate Class", 
        subTitle1: "Explain high-frequency real questions, with special emphasis on oral English", 
        subTitle2: "Students who are suitable for sprinting",
        style: {background: "linear-gradient(#D7D3FA 0%, #B5B0EA 100%)"}
    },
    {
        title: "VIP", 
        subTitle1: "Flexible, specialized", 
        subTitle2: "Best for part-times",
        style: {background: "linear-gradient(#CDE8F3 0%, #96CFCB 100%)"}
    }
]

export default function FreeCourse() {
    return (
        <div id="free-courses" class="pb-3">
            <Container>
                <Row className="pb-5">
                    {
                        data.map((item, idx) => (
                            <CourseItem 
                            key = {`free-${idx}`}
                            title = {item.title}
                            subTitle1 = {item.subTitle1}
                            subTitle2 = {item.subTitle2}
                            style = {item.style}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}
