/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import './analysis.scss'
import { AnalysisData} from './analysisData'
import PopupExam from './popupExam'
import PopupAdd from './popupAdd'

export default function Analysis() {
    const [goalIsShow, setGoalIsShow] = useState(false)
    const [addIsShow, setAddIsShow] = useState(false)
  return (
    <div id="mn-analysis">
        <div className='analysis-panel'>
            <div className='title p-2'>
                Analysis
            </div>
            <div className='analysis px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='header'>
                        <img src={process.env.PUBLIC_URL+'/assets/images/userIcon.avif'} alt="user_icon"/>
                        <p>User name</p>
                    </div>
                    <div className='body'>
                        <h5><b>Target score</b></h5>
                        <p className='input-date'>Exam date:</p> 
                        <p className='input-date'onClick={() =>{setGoalIsShow(true)}}>----/--/--</p>
                        <br/>
                        { AnalysisData.map(data =>{
                            return(
                                <>
                                <div className='score-list'>
                                {data.scoreType.map((type, idx)=>{
                                    return(
                                        <>
                                        {!type.score && 
                                            <>
                                            <div  key={idx} className='icon'>{type.icon}</div><p>__</p>
                                            </>
                                        }
                                        {type.score && 
                                            <>
                                            <div  key={idx} className='icon'>{type.icon}</div><p>{type.score}</p>
                                            </>
                                        }
                                        </>
                                    )
                                })}
                                </div>
                                </>
                            )
                        })}
                        <br/>
                        <div className='add-previous-data'>
                            <h5><b>Previous exam's score</b></h5>
                            <button className='add-btn' onClick={() =>{setAddIsShow(true)}}>+ Add exam's score</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopupExam
            modalIsShow={goalIsShow}
            hideModal ={() =>{setGoalIsShow(false)}}
        />
        <PopupAdd
            modalIsShow={addIsShow}
            hideModal ={() =>{setAddIsShow(false)}}
        />
    </div>
  )
}
