/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import "styled-components/macro";
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import HomeTitle from '../../general/title/homeTitle';
import {Trans} from 'react-i18next';
import FreeCourse from './freeCourse';
import VIPCourse from './vipCourse';
import OpenCourse from './openCourse';
import './course.scss'
import BottomButton from '../../general/buttons/bottomButton';


export default function Course() {
    return (
        <section id="mn-course" className="py-5">
            <Container>
                <HomeTitle 
                title = "PTE Courses"
                des = "The first class of your PTE preparation study, from preparing for the test to reaching the top 8"
                />


                <Tab.Container defaultActiveKey="first">
                <Row>
                    <Col md={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item><Nav.Link eventKey="first"><Trans>Free class</Trans></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="second"><Trans>VIP class</Trans></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="three"><Trans>Public class</Trans></Nav.Link></Nav.Item>
                    </Nav>
                    </Col>
                    <Col md={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first"><FreeCourse /></Tab.Pane>
                        <Tab.Pane eventKey="second"><VIPCourse /></Tab.Pane>
                        <Tab.Pane eventKey="three"><OpenCourse /></Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
            </Container>



        <BottomButton btns={["Register now", "Inquire now"]}/>
            
        </section>
    )
}
