/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import auth from '../../utils/auth'
import { formErrorEnum, loginStateEnum } from './data'
import FieldMatchPassword from './fieldMatchPassword'
import FieldUsername from './fieldUsername'
import FieldEmail from './fieldEmail'
import FieldPassword from './fieldPassword'
import LoginMainBtn from './loginMainBtn'
import {Form} from 'react-bootstrap'
import 'styled-components/macro'


export default function FormEmailRegister(props) {

    // for error
    const [formState, setFormState] = useState({
        username: formErrorEnum.usernameEmpty, 
        email: formErrorEnum.emailEmpty,
        password: formErrorEnum.passwordEmpty, 
        confirmPassword: formErrorEnum.passwordEmpty,
    })


    function toLogin() {

        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.usernameEmpty, 
            email: formErrorEnum.emailEmpty,
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })

        props.setLoginState(loginStateEnum.emailLogin)

    }

    function toForget() {
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.usernameEmpty, 
            email: formErrorEnum.emailEmpty,
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })

        props.setLoginState(loginStateEnum.emailForget)

    }


    return (
        <div className="emailRegister" css={`display: ${props.loginState === loginStateEnum.emailRegister ? "block" : "none"};`}>
        <Form.Group>
            <FieldUsername 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />

            
            <FieldEmail 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />
            

            <FieldPassword
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />


            <FieldMatchPassword 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />

        </Form.Group>


        <div className="options pb-3">
            <div 
                className="register"
                onClick={()=>{toLogin()}}>
                Account login
            </div>
            <div 
                className="forget"
                onClick={()=>{toForget()}}>
                Forgot password？
            </div>
        </div>


        <LoginMainBtn 
            formState = {formState}
            onClick = {() => {auth.registerUserEmail(props.formInfo)}}
            content = "Register"
        />

        <small className="text-secondary">You read and agree to our Privacy Policy and Service Agreement</small>
    </div>
    )
}
