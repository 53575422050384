/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './setting.scss'
import {settingData} from './settingData'

export default function Setting() {
  return (
    <div id="mn-setting">
        <div className='setting-panel'>
            <div className='title p-2'>
                Setting
            </div>
            <div className='setting px-5 py-3'>
                <ul className="mb-3 px-5 py-3">
                    {settingData.map(data =>{
                        return(
                            <>
                            <li className='setting-list'>
                                <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt="user-icon"/>
                                <div className='list-tab'>
                                    <div key={data.id}>
                                    <p><b>Nickname:</b> {data.name} <div className='edit-symbol'>&#128393; </div></p>
                                    <p><b>E-mail:</b> {data.email} <div className='edit-symbol'>&#128393; </div></p>
                                    <p><b>Timezone:</b> {data.timezone} <div className='edit-symbol'>&#128393; </div></p>
                                    </div>
                                    <br/>
                                </div>
                            </li>
                            </>
                        )
                    })}
                </ul>
            </div>
        </div>
    </div>
  )
}
