/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import {AiFillCaretLeft} from 'react-icons/ai'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../../global/constants'
import { bookData } from '../bookData'
import './bookPage.scss'

export default function BookPage() {
  const [newword, setNew] = useState(true)
  const [master, setMaster] = useState(false)
  function activeList(){
    if(newword && !master)
    {
      setNew(false)
      setMaster(true)
    }
    else{
      setNew(true)
      setMaster(false)
    }
  }

  return (
    <>
    <div id="mn-book-page">
    <div className='book-panel'>
        <div className='title p-2'>
          <Link to={PATHS.admin.name+PATHS.vocabulary.name}>
          <AiFillCaretLeft className='go-back' />
          </Link>
          Words vocabulary
        </div>
        <div className='books px-5 py-3'>
          <div className='books-list'>
              <img src={process.env.PUBLIC_URL+'/assets/images/vocabulary-cover.avif'} alt='book-1.avif'/>
              <div className='list-tab'>
                <div key={bookData[0].id}>
                  <h5>{bookData[0].title}</h5>
                  <p>{bookData[0].description}</p>
                </div>
              </div>
           </div>
          <br/>
          <div className='words-list'>
                <button onClick={activeList}
                className={newword ? "active" : "unactive"}>
                      New words
                </button>
                <button onClick={activeList}
                className={master ? "active" : "unactive"}>
                      Mastered words
                </button>
          </div>
          <div className='newword-list' style={newword ? {display: 'flex', flexDirection:"column"} : {display: "none"}}>
            {bookData[0].newword.map(word =>{
              return(
                <p>
                  {word}
                </p>
              )
            })}
          </div>
          <div className='masterword-list' style={master ? {display: 'flex', flexDirection:"column"} : {display: "none"}}>
            {bookData[0].masteredword.map(word =>{
              return(
                <p>
                  {word}
                </p>
              )
            })}
          </div>
        </div>
    </div>
    </div>
    </>
  )
}
