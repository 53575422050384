export const data = [
    {
        id: "com-1",
        title: "Community 1",
        des: "Community's description"
    },
    {
        id: "com-2",
        title: "Community 2",
        des: "Community's description"
    },
    {
        id: "com-3",
        title: "Community 3",
        des: "Community's description"
    },
    {
        id: "com-4",
        title: "Community 4",
        des: "Community's description"
    }
]