/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import { teacherData } from './data'
import {AiOutlineRight} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { PATHS } from '../../../global/constants'
import './tutoring.scss'


export default function Tutoring() {

  return (
    <div id='mn-tutoring'>
        <div className='tutoring-panel'>
            <div className='title p-2'>
                Tutors
            </div>
            <div className='tutors px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='body'>
                        <ul className='tutor-list'>
                            {teacherData.map(data =>{
                                return(
                                    <>
                                        <li className='tutor' key={data.id}>
                                        <div >
                                            <div className='tutor-img'><img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='tutor-img'/></div>
                                            <div className='tutor-name'><b>{data.name}</b></div>
                                        </div>
                                        <div className='tutor-info'>
                                            <p><b>Degree:</b> {data.degree}</p>
                                            <p><b>Description:</b> {data.des}</p>
                                            <p><b>Info:</b> {data.info}</p>
                                            <Link
                                                to={PATHS.admin.name+PATHS.tutoring.schedule.name}
                                            >
                                            <button className='more-btn'>View more <AiOutlineRight/></button>
                                            </Link>
                                        </div>
                                        </li>
                                    </>
                                )
                            })}
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
                         
    </div>
  )
}
