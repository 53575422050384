/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import FixFooter from '../general/fixFooter/fixFooter'
import FixSidebar from '../general/fixSidebar/fixSidebar'
import Footer from '../general/footer/footer'
import Nav from '../general/nav/nav'
import GetMaterial from '../popups/getMaterial/getMaterial'
import About from './about/about'
import Banner from './banner/banner'
import Compare from './compare/compare'
import Course from './course/course'
import System from './course/system'
import Material from './material/material'
import Platform from './platform/platform'
import Product from './product/product'
import Teacher from './teacher/teacher'
import Testimony from './testimony/testimony'


export default function HomePage() {
    const [showPopupMaterial, setPopupMaterial] = useState(getShowPopupMaterial())


    function getShowPopupMaterial() {
        if (localStorage.getItem("showPopupMaterial")) {
            /* 
            localStorage value is a string, 
            you cannot use Boolean for 'false',
            which will always return true
            */
            return localStorage.getItem("showPopupMaterial") === 'true'
        } else {
            localStorage.setItem("showPopupMaterial", true)
            return true
        }
    }

    function hidePopupMaterial() {
        setPopupMaterial(false);
        localStorage.setItem("showPopupMaterial", false)
    }


    return (
        <div >
            <Nav />
            <main id="mn-homePage">
                <Banner />
                <Platform />
                <Material />
                <Compare />
                <Course />
                <Teacher />
                <Product />
                <Testimony />
            </main>
            <Footer />
            <FixFooter />
            <FixSidebar />

            {/* <GetMaterial 
                modalIsShow = {showPopupMaterial}
                hideModal = {hidePopupMaterial}
            /> */}
        </div>

    )
}
