/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import {UserPracticeData} from './userPracticeData'
import "./myPractices.scss"
import { Button } from 'react-bootstrap'
import PopupScore from '../score/popupScore'

export default function MyPractices() {
    const [scoreIsShow, setScoreIsShow] = useState(false)
    return (
        <div id="mn-question-my-practices" className="p-3">
            <div className='mypractice px-5 py-3'>
            <ul className="mb-3 px-5 py-3">
            {UserPracticeData.map(data =>{
                return(
                    <>
                    <li className='mypractice-list'>
                        <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
                        <div className='list-tab'>
                        <div key={data.id}>
                            <h8>{data.username}</h8> <p className='datetime'>MM-DD-YYYY time</p>
                            <br/>
                            <audio controls></audio>
                            <br/>
                            <p>{data.practice}</p>
                        </div>
                        <Button id='button' onClick={()=>{setScoreIsShow(true)}}>Score</Button>
                        </div>
                    </li>
                    <br/>
                    </>
                )
            })}
            </ul>
            </div>
            <PopupScore
                modalIsShow={scoreIsShow}
                hideModal ={() =>{setScoreIsShow(false)}}
            />
        </div>
    )
}
