/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useState } from 'react'
import PopupVid from './popupVid'

export default function CourseCard(props) {
    const [vidIsShow, setVidIsShow] = useState(false)
    return (
        <div className="course-card shadow">
            <img src={process.env.PUBLIC_URL + '/assets/images/placeholder_course.avif'} alt="placeholder_course"
                onClick={() => { setVidIsShow(true) }} />
            <div class="bottom p-3">
                <h5>{props.cardTitle}</h5>
                <p>{props.cardDes}</p>
            </div>
            <PopupVid
                modalIsShow={vidIsShow}
                hideModal={() => { setVidIsShow(false) }}
            />
        </div>
    )
}
