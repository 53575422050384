/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import './popupMemory.scss'

export default function PopupMemory(props) {
  return (
    <Modal id="mn-modal-memory" show={props.modalIsShow} onHide={props.hideModal}>
      <Modal.Header closeButton>
            <Modal.Title>Exam memories</Modal.Title>
      </Modal.Header>
      <Modal.Body className='body'>
        <input type="date" className='date-input'></input>
      </Modal.Body>
      <Modal.Footer className='footer'>
          <Button variant="secondary" onClick={props.hideModal}>
               Cancel
          </Button>
          <Button variant="primary" onClick={props.hideModal}>
               Confirm
          </Button>
      </Modal.Footer>
    </Modal>
  )
}
