/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */



export const qrData = [
    {
        img: "serviceWechatQR",
        des: "WeChat Customer Service"
    },
    {
        img: "serviceWhatsAppQR",
        des: "WhatsApp Customer Service"
    },
    {
        img: "appQR",
        des: "iOS APP Download"
    }

]