/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './practiceRecord.scss'
import { practiceData, examData } from './practiceData'
import CardUnit from '../home/cardUnit'
import {AiOutlineRight} from 'react-icons/ai'

export default function PracticeRecord() {
  return (
    <div id="mn-practice-record">
        <div className='practice-record-panel'>
            <div className='title p-2'>
                Practice Record
            </div>
            <div className='records px-5 py-3'>
                <ul className="mb-3 px-5 py-3">
                    <h4><b>Practice status</b></h4>
                    <li className='record-list'>
                        <div className="header p-3">
                            {practiceData.header.name}
                            <p className="m-0">{practiceData.header.btn}</p>
                        </div>
                        <div className="body">
                            {practiceData.body.map(data =>{
                                return(
                                    <CardUnit 
                                    title = {data.title}
                                    des = {data.des}
                                    />
                                )
                            })}
                        </div>
                        <div className="footer p-3">
                            {practiceData.footer}
                            <AiOutlineRight />
                        </div>
                    </li>
                </ul>
                <ul className="mb-3 px-5 py-3">
                    <h4><b>Exam history</b></h4>
                    <li className='record-list'>
                        <div className="header p-3">
                            {examData.header.name}
                            <p className="m-0">{examData.header.btn}</p>
                        </div>
                        <div className="body">
                            {examData.body.map(data =>{
                                return(
                                    <CardUnit 
                                    title = {data.title}
                                    des = {data.des}
                                    />
                                )
                            })}
                        </div>
                        <div className="footer p-3">
                            {examData.footer}
                            <AiOutlineRight />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
