/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import AdminSideMenuUnit from './adminSideMenuUnit'
import { adminMenuData } from './data'
import './adminSideMenu.scss'


function AdminSideMenu(props) {
    
    useEffect(() => {
        let expand = document.querySelector("#mn-admin-side .expand");
        let nav = document.querySelector("#mn-admin-side");
        let items = document.querySelectorAll("#mn-admin-side li");

        // effect 1
        expand.addEventListener("click", () => {
            nav.classList.toggle("closed");
        });

        // effect 2
        items.forEach((link) => link.addEventListener("click", listActive));


        function listActive() {
            items.forEach((link) => link.classList.remove("active"));
            this.classList.add("active");
        }
        return () => {}
    })

    
  

    return (
        <section id="mn-admin-side">  
            <svg className="expand" viewBox="0 0 256 512" width="100" title="angle-right">
                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
            </svg>

            <ul className="side-nav pb-5">
         
                <AdminSideMenuUnit 
                location = {props.location}
                path = {adminMenuData[0].path}
                icon = {adminMenuData[0].icon}
                title = {adminMenuData[0].title}
                />
                <hr />
                {
                    adminMenuData.slice(1, 5).map((item, idx) => (
                        <AdminSideMenuUnit 
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    )) 
                }
                <hr />
                {
                    adminMenuData.slice(5, 8).map((item, idx) => (
                        <AdminSideMenuUnit 
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    ))
                }
                <hr />
                {
                    adminMenuData.slice(8, 11).map((item, idx) => (
                        <AdminSideMenuUnit 
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    ))
                }
                <hr />
                {
                    adminMenuData.slice(11, 14).map((item, idx) => (
                        <AdminSideMenuUnit 
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    ))
                }
                <hr />
                {
                    adminMenuData.slice(14, 16).map((item, idx) => (
                        <AdminSideMenuUnit
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    ))
                }
                <hr />
                {
                    adminMenuData.slice(16, 19).map((item, idx) => (
                        <AdminSideMenuUnit 
                        key = {idx}
                        location = {props.location}
                        path = {item.path}
                        icon = {item.icon}
                        title = {item.title}
                        />
                    ))
                }
            </ul>

            
        </section>
    )
}


export default withRouter(withTranslation()(AdminSideMenu));