/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


export default function PlatformItem(props) {
  return (
    <li className="practice-item animation-translation-bt" >
      <a href={props.url}>
        <div className="item-head">
          {props.img()}
          <h4>{props.title}</h4>
        </div>
        <p>{props.description}</p>
        <button>
          {props.btnTitle}
          <img className="static-icon" src={props.iconUrl} alt="" />
          <img className="hover-icon" src={props.iconUrlH} alt="" />
        </button>
      </a>
    </li>
  )
}

