/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


import { Swiper, SwiperSlide, } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import "styled-components/macro";

import './banner.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);


export default function Banner() {
  return (
      <section id="mn-home-banner">
          <Swiper
            spaceBetween={0}
            effect = {"fade"}
            slidesPerView={1}
            navigation
            pagination={{ 
                clickable: true
            }}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay = {{
                delay: 5000
            }}
            speed = {1000}
            loop = {true}
            >
            {
                [...Array(3).keys()].map((idx) => (
                <SwiperSlide key={idx}>
                    <div >
                    <img src={"./assets/images/banner"+idx+".avif"}  alt='' style= {{width: '100vw', height: '50vw'}}/>
                    </div>
                </SwiperSlide>
                ))
            }
            </Swiper>
      </section>
    
  );
};