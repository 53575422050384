/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {data} from './data'
import PromiseUnit from './promiseUnit'
import './promise.scss'


export default function Promise() {
    return (
        <div id="mn-promise">
            {
                data.map((d) => (
                    <PromiseUnit 
                    icon = {d.icon}
                    title = {d.title}
                    des1 = {d.des1}
                    des2 = {d.des2}
                    />
                ))
            }
        </div>
    )
}
