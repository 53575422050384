/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'


export default function MaterialItem(props) {
    return (
        <div className="material-item shadow">
            <div className="material-item-new">NEW</div>
            <div className="material-item-title">{props.title}</div>
            <div className="material-item-des">{props.des}</div>
            <button className="material-item-btn" data-url="">{props.action}</button>
        </div>
    )
}
