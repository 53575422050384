/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import { render } from '@testing-library/react'
import React, { useRef, useEffect, useState } from 'react'
import "./practiceRFIBPage.scss"
import Blanks from './blanks'
//import picURL from '../../../../../components/stream/banner/images/bg.avif'

export function allowDrop(ev) {
    ev.preventDefault();
}

export function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
}

export function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
}

export default function Rfib({ slideIndex, data }) {
    const [optionLst, setLst] = useState(data.options)

    var lastState = false
    var count = 0
    let sentences = []

    function rfib_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]

        const eles = paragraph.split(" ")

        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            } else if (ele.includes("<answer>")) {
                return "<blank>"
            } else {
                return ele;
            }
        })
        return new_eles.join(" ");
    }

    function rfib_blank_insert(text) {
        if (sentences.length !== 0) {
            sentences = []
        }
        const content = rfib_transform_paragraph(text)

        const eles = content.split("<blank>")
        count = eles.length - 1
        eles.map(ele => {
            sentences.push(ele)
        })
        return sentences
    }

    function lastStatus(index) {
        if (count === index) {
            lastState = true
        } else { lastState = false }
        return lastState
    }

    function checkCorrectAns(){}
    
    useEffect(() => {
        console.log(optionLst)
    }, [slideIndex])

    return (
        <>
            <div className='slideshow-container'>
                <div className={slideIndex === 1 ? "active-slides" : "mySlides"}>
                    <div className='content'>
                        {rfib_blank_insert(data.content).map((txt, idx) => {
                            return (
                                <>
                                    {txt}
                                    <Blanks lastStatus={lastStatus(idx)} />
                                </>
                            )
                        })}
                    </div>
                    <br/>
                    <table className='answer' onDrop={drop} onDragOver={allowDrop}>
                        <tbody>
                        <tr>
                            {optionLst.map((op, idx) =>{
                                return(
                                <td className='textboxes' draggable="true" onDragStart={drag} key={idx +1} id={idx}>
                                    {op}
                                </td>
                                )
                            })}
                        </tr>
                        </tbody>
                    </table>
                    {/* <div className='answer' onDrop={drop} onDragOver={allowDrop}>


                        <div id="div1" onDrop={drop} onDragOver={allowDrop} 
                        style={{border:'2px solid #aaaaaa' ,width:'350px', height:'70px', padding:'10px'}}></div>
                        <br />
                        <div id="div2" onDrop={drop} onDragOver={allowDrop} 
                        style={{border:'2px solid #aaaaaa' ,width:'350px', height:'70px', padding:'10px'}}></div>
                        <img id="1" src={picURL} draggable="true" onDragStart={drag} width="336" height="69" />
                        <br/>
                        <div id='2' draggable="true" onDragStart={drag} 
                        style={{background:'red', width:'100px', height:'50px'}}>Testing </div>
            
                    </div>*/}
                    
                </div>
                <div className={slideIndex === 2 ? "active-slides" : "mySlides"}>
                    <div className='content'>
                        {rfib_blank_insert(data.content).map((txt, idx) => {
                            return (
                                <>
                                    {txt}
                                    <div className='blanks' onDrop={drop} onDragOver={allowDrop}
                                        style={lastStatus(idx) === true ? { display: "none" } : { display: "inline-block" }}>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    
                </div>
            </div>
            <p id="result"></p>
            <button class="check_btn" onClick={checkCorrectAns}>Check answers</button>
        </>
    )
}
