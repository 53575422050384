/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

// temporary not supported overseas phone
export const countryCodes = [
   "+86（中国）", 
   "+61（澳大利亚）", 
   "+375（白俄罗斯）",
   "+1（加拿大）",
   "+852（中国-香港）", 
   "+853（中国-澳门）",
   "+60（马来西亚）",
   "+64（新西兰）",
   "+63（菲律宾）",
   "+65（新加坡）",
   "+82（韩国）",
   "+886（中国-台湾）",
   "+66（泰国）",
   "+44（英国）",
   "+1（美国）"
]

export const loginStateEnum = {
    
    phoneLogin: "phoneLogin",
    phoneRegister: "phoneRegister",
    phoneForget: "phoneForget",
    emailLogin: "emailLogin",
    emailRegister: "emailRegister",
    emailForget: "emailForget",
    wechat: "wechat",
    app: "app"
}

export const requestModeEnum = {
    usernameEmailRegister: "USERNAME_EMAIL_REGISTER",
    emailLogin: "EMAIL_LOGIN",
    emailForget: "EMAIL_FORGET",
    usernameLogin: "USERNAME_LOGIN",
    usernameForget: "USERNAME_FORGET"
}

export const formErrorEnum = {
    usernameEmpty: "Username cannot be empty",
    emailEmpty: "Email cannot be empty",
    emailInvalid: "Email is not valid",
    passwordEmpty: "Password cannot be empty", 
    passwordNotMatch: "Passwords are not matched",
    passwordNotLength: "Password's length should be at least 8 bits", 
    passwordNotCapitalLetter: "Password should have at least 1 capital letter", 
    passwordNotLowercase: "Password should have at least 1 lowercase", 
    passwordNotNumber: "Password should have at least 1 number", 
    passwordNotSpecialCharacter: "Password should have at least 1 special character", 
    fieldEmpty: "Field cannot be empty"
}