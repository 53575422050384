/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import "styled-components/macro";
import {Container, Row} from 'react-bootstrap'
import HomeTitle from '../../general/title/homeTitle';
import './compare.scss'
import BottomButton from '../../general/buttons/bottomButton'


export default function Compare() {
    return (
        <section id="mn-compare" className="py-5">
            <Container>
                <HomeTitle 
                title = "Why choose PTE over IELTS"
                des = "Its simplicity helps you reach success more closely."
                />

                <Row>
                <div className="main">
                    <table className="price-table">
                        <tbody>
                            <tr className="table-head">
                                <td></td>
                                <td>PTE</td>
                                <td className="green-width">IETLS</td>
                            </tr>
                            <tr>
                                <td>Exam format</td>
                                <td>Computer-based test</td>
                                <td>Paper / computer-based test</td>
                            </tr>
                            <tr>
                                <td>Scope</td>
                                <td>Study / Immigration / Work</td>
                                <td>Study / Immigration / Work</td>
                            </tr>
                            <tr>
                                <td>Test date</td>
                                <td>Apply at any time. <br />Register 1 day in advance.</td>
                                <td>Fixed date, 12 working days in advance for registration, exam seats are tight, 1-4 exam rooms per month</td>
                            </tr>
                            <tr>
                                <td>Exam fee</td>
                                <td>$275/Coupon ¥1700<br/>280美元（UKVI）</td>
                                <td>¥2170/2200(UKVI)</td>
                            </tr>
                            <tr>
                                <td>Result speed</td>
                                <td>2 days</td>
                                <td>More days</td>
                            </tr>
                            <tr>
                                <td>Applicable schools</td>
                                <td>Has been widely recognized around the world</td>
                                <td>Accredited by nearly 10,000 institutions around the world, including 3,000 in the United States</td>
                            </tr>
                            <tr>
                                <td>Question bank</td>
                                <td>Small. <br/>Hit rate of original questions is high.</td>
                                <td>Huge. <br/>May/November is the question changing season. <br/>Hit rate is low.</td>
                            </tr>
                            <tr>
                                <td>Scoring</td>
                                <td>Machine</td>
                                <td>Human</td>
                            </tr>
                            <tr>
                                <td>Exam Features</td>
                                <td>Spoken language: pay attention to the fluency of English, and have clear requirements for pronunciation/pause/voice intonation<br /></td>
                                <td><i className="fas fa-check"></i></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
              
                </Row>
            </Container>

            <BottomButton btns={["Consult us"]}/>
        </section>

    )
}
