import React from 'react'
import './schedule.scss'
import { PATHS } from '../../../global/constants'
import { Link } from 'react-router-dom'
import { AiFillCaretLeft } from 'react-icons/ai'
import { legendData, scheduleDate, dateTitle } from './data'

export default function schedule() {
    return (
        <div id="mn-schedule">
            <div className='schedule-panel'>
                <div className='title p-2'>
                <Link to={PATHS.admin.name+PATHS.tutoring.name}>
                <AiFillCaretLeft className='go-back' />
                </Link>
                Schedule
                </div>
                <div className='body'>
                    <div className='tutor-space'>
                        <div className='tutor-img'><img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='tutor-img'/></div>
                        <div className='tutor-info'>
                            <h5><b>Tutor's name</b></h5>
                            <p>Tutor's description</p>
                        </div>
                    </div>
                    <div className='schedule-space'>
                        <div className='schedule-table'>
                            <div className='legend-table'>
                                {legendData.map((d, idx) =>{
                                    return(
                                        <div key={idx} className="legend">
                                            <div className='color-label' 
                                            style={{backgroundColor: d.color}}>
                                            </div>
                                            <p>{d.label}</p>
                                        </div>
                                    )
                                })}
                                <button className='book-btn'>Book now</button>
                            </div>
                            <table>
                                <tr>
                                    <th></th>
                                    {dateTitle.map((d, index) =>{
                                        return(
                                            <th key={index}>Date {d.date}</th>
                                        )
                                    })}
                                </tr>
                                {scheduleDate.map((date, idx) =>{
                                    return(
                                        <>
                                        <tr>
                                            <td>Month {date.month}</td>
                                            {date.date.map((d, index) =>{
                                            return(
                                                <td key={index} className={d.booked ? "booked" : "vacant"}> Time</td>
                                            )
                                        })}
                                        </tr>
                                        </>
                                    )
                                })}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
