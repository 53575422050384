/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const coursesData=[
    {
        id: '1',
        title: 'Courses 1 Title',
        description: "This the course's description",
        catergory:"Describe Image"
    },
    {
        id: '2',
        title: 'Courses 2 Title',
        description: "This the course's description",
        catergory:"RA and Vocalization"
    },
    {
        id: '3',
        title: 'Courses 3 Title',
        description: "This the course's description",
        catergory:"PTE Writing"
    },
    {
        id: '4',
        title: 'Courses 4 Title',
        description: "This the course's description",
        catergory:"PTE reading"
    },
    {
        id: '5',
        title: 'Courses 5 Title',
        description: "This the course's description",
        catergory:"PTE vocalbulary"
    },
    {
        id: '6',
        title: 'Courses 6 Title',
        description: "This the course's description",
        catergory:"PTE Listening"
    },
    {
        id: '7',
        title: 'Courses 7 Title',
        description: "This the course's description",
        catergory:"PTE Listening"
    }
]

export const courseTitle=[
    "All classes", "Describe Image", "RA and Vocalization", "PTE Writing", "PTE reading", "PTE vocalbulary", "PTE Listening" 
]