/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AdminPage from './components/admin/adminPage';
import { PATHS } from './global/constants'
import HomePage from './components/home/homePage';
import StreamPage from './components/stream/streamPage';
import CommunityPage from './components/community/communityPage';
import CoursePage from './components/course/coursePage';
import LoginPage from './components/login/loginPage';
import ProtectedRoute from './components/route/protectedRoute';
import PracticePage from './components/admin/practice/practicePage';
import PredictPage from './components/admin/predict/predictPage';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-254165750-1";
ReactGA.initialize(TRACKING_ID);


const App = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter>
            <Switch>
                <Route path={PATHS.home.name} exact component={HomePage}></Route>
                <Route path={PATHS.course.name} component={CoursePage}></Route>
                <Route path={PATHS.community.name} component={CommunityPage}></Route>
                <Route path={PATHS.stream.name} component={StreamPage}></Route>
                <Route path={PATHS.practice.name} component={PracticePage}></Route>
                <Route path={PATHS.predict.name} component={PredictPage}></Route>
                <ProtectedRoute path={PATHS.admin.name} component={AdminPage}></ProtectedRoute>
                <Route path={PATHS.admin.name} component={AdminPage}></Route>
                <Route path={PATHS.login.name} component={LoginPage}></Route>
            </Switch>
        </BrowserRouter>
    );
}


export default App;