/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Form} from 'react-bootstrap'
import {formErrorEnum} from './data'



export default function FieldEmail(props) {
    function checkEmail(v) {
        // write to state
        props.setFormInfo({...props.formInfo, email: v})

        // check
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (v === ""){
            props.setFormState({...props.formState, email: formErrorEnum.emailEmpty})
        } else if(!re.test(String(v).toLowerCase())) {
            props.setFormState({...props.formState, email: formErrorEnum.emailInvalid})
        } else {
            
            props.setFormState({...props.formState, email: ""})
        }
    }


    return (
        <div>
            <Form.Control 
                type="email" 
                placeholder="Email" 
                value={props.formInfo.email} 
                onChange={(e) => checkEmail(e.target.value)}
            />
            {
                props.formState.email !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.email}
                </Form.Text>
            }
            <br />
        </div>
    )
}
