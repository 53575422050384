/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import "styled-components/macro";
import {Trans, withTranslation} from 'react-i18next'
import {Link, withRouter} from 'react-router-dom';
import NavPractice from './navPractice';
import NavMock from './navMock'
import './nav.scss'
import NavApp from './navApp';
import {AiOutlineMenu} from 'react-icons/ai';
//import NavLogin from './NavLogin';
//import AIScoreTimes from '../../popups/aiScoreTimes/AIScoreTimes';
import { PATHS } from '../../../global/constants';
import { navData } from './data';
import NavMobileUnit from './navMobileUnit'
import auth from '../../../utils/auth';
const { v4: uuidv4 } = require('uuid');



class Nav extends React.Component {

    constructor(props) {
        super(props);
        
        this.listeners = [];
        this.state = {
          bgOpacity: 0.3, 
          showMobileMenu: false, 
          loginIsShow: false,
          isMobile: window.innerWidth <= 960
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.showLogin = this.showLogin.bind(this);
        this.hideLogin = this.hideLogin.bind(this);
   
    }


    componentDidMount() {
        // scoll change nav opacity
        this.listeners[0] = document.addEventListener("scroll", e => {
           var scrolled = document.scrollingElement.scrollTop;
           if (scrolled >= 120) {
              if (this.state.status !== 1) {
                 this.setState({ bgOpacity: 1 });
              }
           } else {
              this.setState({bgOpacity: scrolled/120+0.3});
           }
        });
  
  
        // resize window
        this.listeners[1] = window.addEventListener('resize', () => {
           this.setState({
               isMobile: window.innerWidth <= 960
           });
           if (window.innerWidth > 960) {
                this.setState( {
                    showMobileMenu: false
                })
           }
       }, false);
     }


    componentDidUpdate() {
        document.removeEventListener("scroll", this.listeners[0]);
        window.removeEventListener("resize", this.listeners[1]);
     }

    toggleMobileMenu() {
        this.setState(prevState => ({
            showMobileMenu: !prevState.showMobileMenu
        }));
    }

    showLogin() {
        this.setState( {
            loginIsShow:  true
        })
    }
    hideLogin() {
        this.setState( {
            loginIsShow: false
        })
    }



    render() {


        return (
            <header style={{backgroundColor:"rgba(255, 255, 255, "+this.state.bgOpacity+")"}}>

                {/* mobile-download*/}
                <a className="header-download-m" href="https://apps.apple.com/au/app/cow-pte-exam-practice-tool/id1555990766">
                    <img src={process.env.PUBLIC_URL+'/assets/images/mn_download.avif'} alt="PTE,蛮牛App" />
                </a>

                
                <div className="header-space px-5 clearfix">
                    {/* pc */}
                    <Link to="/" className="logo"><img src={process.env.PUBLIC_URL+'/assets/images/mn_logo.avif'} alt="" /></Link>

                    <ul className="header-nav">
                        <li className="practice-hover" css={`position: relative;`}>
                            <Link to={PATHS.practice.name}><Trans>Questions</Trans></Link>
                            <NavPractice key={uuidv4()}/>
                        </li>
                        <li><Link to={PATHS.predict.name}><Trans>Predicts</Trans></Link></li>
                        <li className="mock-hover" css={`position: relative;`}>
                            <Link to={`${PATHS.admin.name}${PATHS.mock.name}`}><Trans>Mocks</Trans></Link>
                            <NavMock key={uuidv4()}/>
                        </li>
                        <li><Link to={PATHS.course.name}><Trans>Courses</Trans></Link></li>
                        <li><Link to={PATHS.community.name}><Trans>Community</Trans></Link></li>
                        <li><Link to={PATHS.stream.name}><Trans>Live</Trans></Link></li>
                    </ul>
            
                    {/* fr: from right to left */}                    
                    <p className="header-lan fr mx-3">
                        <button onClick={() => (this.props.i18n.changeLanguage("en"))}>En</button>/
                        <button onClick={() => (this.props.i18n.changeLanguage("ch"))}>中文</button>
                    </p>
                    {/* mobile */}
                    <h3 className="header-title-m fl m-0"><Link to='/'><Trans>蛮牛PTE</Trans></Link></h3>
                    <AiOutlineMenu className="header-menu-m fr" onClick={this.toggleMobileMenu}/>
                    <ul className="menu-nav-m" style={{display: this.state.showMobileMenu ? "block" : "none"}}>
                        {
                            navData.map((n, idx) => (
                                <NavMobileUnit 
                                key = {idx}
                                location = {this.props.location}
                                path = {n.path}
                                title = {n.title}
                                />
                            ))
                        }
                    </ul>


                    {/* pc/mobile */}
                    {!auth.authenticated &&
                        <Link to={PATHS.login.name}>
                        <button className="login-handle fr"><Trans><p>Login</p></Trans></button>
                        </Link>
                    }
                    {auth.gmailLoginAuthenticated &&
                        <>
                        <button className="logout-handle fr" onClick={() =>{auth.gmailLogout()}}><Trans>
                            <p>Log out </p>  
                            <div className='avatar'>
                            <img src={auth.gmailUser.picture} alt="portrait"/>
                            </div></Trans>
                        </button>
                        </>
                    }
                    {auth.authenticated && !auth.gmailLoginAuthenticated &&
                        <button className="logout-handle fr" onClick={() =>{auth.userLogout()}}><Trans>
                        <p>Log out </p>  
                        <div className='avatar'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/userIcon2.png"} alt="portrait"/>
                        </div></Trans>
                        </button>
                    }
                    {/*<AIScoreTimes modalIsShow={this.state.loginIsShow} hideModal={this.hideLogin} />
                    <NavLogin modalIsShow={this.state.loginIsShow} hideModal={this.hideLogin}/>*/}
                    {/* pc */}
                    <button className="lxapp fr">
                        <Trans>APP</Trans>
                        <NavApp />
                    </button>
                </div>
            </header>
         )
     }
}



export default withRouter(withTranslation()(Nav));