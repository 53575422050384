/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { planData } from './planData'
import './dailyPlan.scss'
import { PATHS } from '../../../global/constants'

export default function DailyPlan() {
    function getFirstLastDay() {
        var curr = new Date()
        var firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay()))
        var firstMonth = parseInt(firstDay.getMonth()) + 1 
        var result = firstMonth + "/"+firstDay.getDate() + "/" + firstDay.getFullYear()
        return result
    }

  return (
    <div id="mn-daily-plan">
        <div className='daily-plan-panel'>
            <div className='title p-2'>
                Daily Plan
            </div>
            <div className='plans px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='no-plans'>
                    {planData &&
                    <>
                    <label for='date'>Select date:</label>
                    <input type='text' id='date' className='date' placeholder={getFirstLastDay()} readOnly></input>
                    <div className='no-plan-space'>
                        <img src={process.env.PUBLIC_URL+'/assets/images/planIndex.avif'} alt="no-plan-img"/>
                        <p>No plan yet</p>
                        <Link to={PATHS.admin.name+PATHS.dailyPlan.makePlan.name}>
                        <button>Create Plan</button>
                        </Link>
                    </div>
                    </>
                    }
                    </div>
                    <div className='plans-list'>
                    {!planData &&
                    <>
                    <label for='date'>Select date:</label>
                    <input type='text' id='date' className='date' placeholder={getFirstLastDay()} 
                    onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")}></input>
                    </>
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
