/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import Nav from '../general/nav/nav'
import Footer from '../general/footer/footer'
import FixFooter from '../general/fixFooter/fixFooter'
import FixSidebar from '../general/fixSidebar/fixSidebar'
import CourseBanner from './banner/courseBanner'
import CourseSection from './section/courseSection'
import './coursePage.scss'
import {data} from './data'
import Promise from '../general/promise/promise'


export default function CoursePage() {
    return (
        <div id="mn-course-page">
            <Nav />
            <main id="mn-course-body">
                <CourseBanner />
                {
                    data.map((d) => (
                        <CourseSection 
                        title = {d.title}
                        des = {d.des}
                        tabs = {d.tabs}
                        slides = {d.slides}
                        />
                    ))
                }
            </main>
            <Promise />
            <Footer />
            <FixFooter />
            <FixSidebar />
        </div>
        
    )
}
