/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {AiFillCaretLeft} from 'react-icons/ai'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../global/constants'
import "./readCollection.scss"
import { readingData } from './collectionData'

export default function ReadCollection() {
  return (
    <div id="mn-read-collections">
    <div className='collections-panel'>
        <div className='title p-2'>
            <Link to={PATHS.admin.name+PATHS.myCollections.name}>
            <AiFillCaretLeft className='go-back' />
            </Link>
            Reading Collection
        </div>
        <div className='body'>
            <div className='questions-collection'>
                {readingData.map(data =>{
                    return(
                        <>
                        <div className='questions-option'>
                            <button className='question-type'>{data.type} ({data.number})</button>
                        </div>
                        </>
                    )
                })}
            </div>
            <div className='empty-space'>
                <img src={process.env.PUBLIC_URL+'/assets/images/planIndex.avif'} alt="empty-cover"/>
                <p>Nothing here yet</p>
            </div>
        </div>
    </div>
    </div>
  )
}
