/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const statisticsData = 
    {
        header: {
            name: "Exam date：2021-3-29",
            btn: "0 days left"
        }, 
        body: [
            {
                title: "0",
                des: "Today Practiced"
            },
            {
                title: "433",
                des: "Total Practiced"
            },
            {
                title: "27",
                des: "Practice days"
            }
        ],
        footer:"Click to view"
    }


export const memoriesData = 
    {
        header: {
            name: "Weekly： 05/03 - 05/10",
            btn: "Download"
        }, 
        body: [
            {
                title: "69%",
                des: "Epetition rate"
            },
            {
                title: "24",
                des: "New question"
            },
            {
                title: "1100",
                des: "Predict"
            },
            {
                title: "66",
                des: "Renew"
            }
        ],
        footer: "Click to view"
    }
    

export const smallData = [
    {
        className: "shadow-item p-4",
        title: "Shadow read", 
        des: ""
    },
    {
        className: "test-item p-4",
        title: "Exam skills", 
        des: ""
    },
    {
        className: "high-item p-4",
        title: "High frequency prediction", 
        des: ""
    },
    {
        className: "tool-item p-4",
        title: "More tools", 
        des: ""
    }
]



export const bottomData = [
    {
        title: "Level test", 
        des: "Aptitude Tests, Customized Programs"
    },
    {
        title: "Cut word", 
        des: "Change to another model, self-examination"
    },
    {
        title: "Export machine", 
        des: "Huge question bank, practice without worry"
    }
]
