/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer} from 'react'
import { QuestionWrapper } from '../questionWrapper'
import AudioPlayer from '../../common/audioPlayer/audioPlayer'
import "./practiceMCSPage.scss"
import {Button} from 'react-bootstrap'
import Mcs from './mcs'

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {

    function mcs_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        const letters =['A.','B.','C.','D.','E.','F.']

        const eles = paragraph.split(" ")
        
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            }else if(ele.includes(")") || letters.includes(ele)){
                return "" 
            } else if (ele.includes("<answer>")) {
                const v = ele.replace("<answer>", "")
                return  v 
            } else if(ele.includes("</answer>")){
              const v = ele.replace("</answer>", "")
              return  v 
            }else{
              return  ele;
            }
        })
        return new_eles.join(" ");
    }

    function clearOut(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
            input.checked = false
        })
        Array.from(label_eles).forEach(label =>{
          label.style.background="white"
        })
    }

    function checkCorrectAns(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
        if(input.checked){
            Array.from(label_eles).forEach(label =>{
            const answer = label.getAttribute('value')
            if(answer.includes("<answer>"))
            {   label.style.background="#4beba8"}
            })
        }
      
        })
    }

    useEffect(() => {
        clearOut()
    }, [props.questionId])
    
    return (
        <>
        <div>
            {
                props.questionData && props.questionData.audioPath &&
                <>
                <div className='audio-speaker mb-3'>
                <AudioPlayer 
                     state = {props.state}
                     dispatch = {props.dispatch}
                     audioSrc = {props.questionData.audioPath}
                />
                </div>
                <p>{props.questionData.question}</p>
                {
                    props.questionData.options.map((o, idx) => {
                        return (
                        <>
                        <input type="radio" key={idx} name="options" id={idx} 
                        value={o}
                        ></input>
                        <label for id={idx} value={o} className="options">
                            {mcs_transform_paragraph(o)}
                        </label><br/>
                        </>
                        )
                    })
                }
                <br/>
                <Button variant="outline-primary" onClick={checkCorrectAns}>Check answer</Button>
                </>
            }
            {
                !props.questionData && 
                <>
                <p>Is loading... </p>
                </> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeMCSPage() {
    const [playDuration, setPlayDuration] = useState(45)

    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        idle: {id: 1}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
    }, [state])

    return (
        // 
        <QuestionPage 
        questionType="mcs" 
        state = {state}
        dispatch = {dispatch}
        setPlayDuration = {setPlayDuration}
        />
    )
}