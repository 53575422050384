/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


import React, {useEffect} from "react";
import {Container} from 'react-bootstrap'
import {BsCaretLeft, BsCaretRight} from 'react-icons/bs'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


// import Swiper core and required modules
import SwiperCore, {Pagination, Navigation} from 'swiper/core';
import CourseCard from "./courseCard";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);


const { v4: uuidv4 } = require('uuid');

export default function SectionBody(props) {
    const id = uuidv4()

    useEffect(() => {
        let s = document.getElementById('swiper' + id);
        s.swiper.update();
    })

    return (
        <div className="course-videos" style={{display: props.tab === props.tabKey ? "block" : "none"}} >
            <Container>
                <div className="swiper-button-prev-unique p-2">
                    <BsCaretLeft />
                </div>
                <Swiper 
                    id = {"swiper"+id}
                    slidesPerView={4} 
                    slidesPerColumn={2} 
                    slidesPerColumnFill= {"row"} 
                    spaceBetween={10} 
                    pagination={{
                        "clickable": true
                    }} 
                    navigation={{
                        "nextEl": ".swiper-button-next-unique",
                        "prevEl": ".swiper-button-prev-unique"
                    }} 
                    className="py-5">
                        {
                            props.slides.map((slide) => (
                                // <SwiperSlide>{slide}</SwiperSlide>
                                <SwiperSlide>
                                    <CourseCard 
                                    cardTitle = {slide.cardTitle}
                                    cardDes = {slide.cardDes}
                                    />
                                </SwiperSlide>
                            ))
                        }
                </Swiper>
                <div className="swiper-button-next-unique p-2">
                    <BsCaretRight />
                </div>
            </Container>
        </div>
    )
}
