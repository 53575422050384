/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const speakingData=[
    {
        id:"spk-1",
        type:"RA",
        number:"312",
        des:"RA-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
            "7. Question 7",
            "8. Question 8",
            "9. Question 9"
        ]
    },
    {
        id:"spk-2",
        type:"RL",
        number:"112",
        des:"RL-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"spk-3",
        type:"RS",
        number:"212",
        des:"RS-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"spk-4",
        type:"DI",
        number:"32",
        des:"DI-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"spk-5",
        type:"ASQ",
        number:"332",
        des:"ASQ-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    }
]

export const readingData=[
    {
        id:"read-1",
        type:"RFIB",
        number:"312",
        des:"RFIB-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"read-2",
        type:"RWFIB",
        number:"312",
        des:"RWFIB-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"read-3",
        type:"RO",
        number:"312",
        des:"RO-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    }
]

export const writingData=[
    {
        id:"wri-1",
        type:"SWT",
        number:"312",
        des:"SWT-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"wri-2",
        type:"WE",
        number:"312",
        des:"WE-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    }
]

export const listeningData=[
    {
        id:"lis-1",
        type:"SST",
        number:"312",
        des:"SST-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"lis-2",
        type:"FIB",
        number:"112",
        des:"FIB-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"lis-3",
        type:"HIW",
        number:"212",
        des:"HIW-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    },
    {
        id:"lis-4",
        type:"WFD",
        number:"32",
        des:"WFD-description",
        content:[
            "1. Question 1",
            "2. Question 2",
            "3. Question 3",
            "4. Question 4",
            "5. Question 5",
            "6. Question 6",
        ]
    }
]

export const dataSort=[
    "Very high frequency","By question number","New question"
]
export const  rateName=[
            {
                name:"Hit rate",
                rate:"30%",
                bgColor:"rgb(74, 46, 232)"
            },
            {
                name:"Topic stability",
                rate:"40%",
                bgColor:"rgb(220, 232, 46)"
            },
            {
                name:"Very high frequency",
                rate:"90%",
                bgColor:"rgb(232, 46, 71)"
            }
        ]
