/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const generalInfo=[
    {
        id: 'GI-1',
        ques: "General Info 1",
        ans: {
            id: 'GI-1',
            content: "Info 1"
        }
    },
    {
        id: 'GI-2',
        ques: "General Info 2",
        ans: {
            id: 'GI-2',
            content: "Info 2"
        }
    },
    {
        id: 'GI-3',
        ques: "General Info 3",
        ans: {
            id: 'GI-3',
            content: "Info 3"
        }
    }
]

export const reasonInfo=[
    {
        id: 'RI-1',
        ques: "Reason why 1",
        ans: {
            id: 'RI-1',
            content: "Reason 1"
        }
    },
    {
        id: 'RI-2',
        ques: "Reason why 2",
        ans: {
            id: 'RI-2',
            content: "Reason 2"
        }
    },
    {
        id: 'RI-3',
        ques: "Reason why 3",
        ans: {
            id: 'RI-3',
            content: "Reason 3"
        }
    }
]

export const tipInfo=[
    {
        id: 'TI-1',
        ques: "Tip 1",
        ans: {
            id: 'TI-1',
            content: "Info 1"
        }
    },
    {
        id: 'TI-2',
        ques: "Tip 2",
        ans: {
            id: 'TI-2',
            content: "Info 2"
        }
    },
    {
        id: 'TI-3',
        ques: "Tip 3",
        ans: {
            id: 'TI-3',
            content: "Info 3"
        }
    }
]