/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useState, useEffect } from 'react'
import './adminNav.scss'
import { FcCloseUpMode } from 'react-icons/fc'
import { RiVipFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import auth from '../../../utils/auth'

export default function AdminNav() {
    const [isMobile, setIsMoble] = useState(window.innerWidth <= 960)

    useEffect(() => {
        window.addEventListener("resize", updateMobileObserver);
        return () => window.removeEventListener("resize", updateMobileObserver);
    });
    const updateMobileObserver = () => (
        setIsMoble(window.innerWidth <= 960)
    )


    return (
        <div id="mn-admin-nav" className={isMobile ? "" : "px-5"}>

            <div className="header-left">
                <Link to="/"><img src="./assets/images/mn_logo.avif" alt="" /></Link>
            </div>
            <div className="header-right">
                <div className="invite px-2">
                    <FcCloseUpMode size={28} />
                    <p className="m-0">Invitation</p>
                </div>
                <div className="vip mx-3 px-2">
                    <RiVipFill size={28} />
                    <p className="m-0">Active VIP</p>
                </div>
                <div className="avator">
                    {!auth.authenticated &&
                        <img src={process.env.PUBLIC_URL+'/assets/images/userIcon.avif'} alt="portrait" />
                    }
                    {/**Gmail avatar */}
                    {
                        auth.gmailLoginAuthenticated &&
                        <img src={auth.gmailUser.picture} alt="portrait" />
                    }
                    {/**User avatar */}
                    {auth.authenticated && !auth.gmailLoginAuthenticated &&
                        <img src={process.env.PUBLIC_URL+'/assets/images/userIcon.avif'} alt="portrait" />
                    }
                </div>
            </div>
        </div>
    )
}
