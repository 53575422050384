/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { Switch, Route } from 'react-router'
import { PATHS } from '../../../global/constants';
import PracticeListPage from './list/practiceListPage'
import PracticeASQPage from './types/asq/practiceASQPage';
import PracticeDIPage from './types/di/practiceDIPage';
import PracticeFIBPage from './types/fib/practiceFIBPage';
import PracticeHCSPage from './types/hcs/practiceHCSPage';
import PracticeHIWPage from './types/hiw/practiceHIWPage';
import PracticeMCMPage from './types/mcm/practiceMCMPage';
import PracticeMCSPage from './types/mcs/practiceMCSPage';
import PracticeRAPage from './types/ra/practiceRAPage';
import PracticeRFIBPage from './types/rfib/practiceRFIBPage';
import PracticeRLPage from './types/rl/practiceRLPage';
import PracticeRMCMPage from './types/rmcm/practiceRMCMPage';
import PracticeRMCSPage from './types/rmcs/practiceRMCSPage';
import PracticeROPage from './types/ro/practiceROPage';
import PracticeRSPage from './types/rs/practiceRSPage';
import PracticeRWFIBPage from './types/rwfib/practiceRWFIBPage';
import PracticeSMWPage from './types/smw/practiceSMWPage';
import PracticeSSTPage from './types/sst/practiceSSTPage';
import PracticeSWTPage from './types/swt/practiceSWTPage';
import PracticeWEPage from './types/we/practiceWEPage';
import PracticeWFDPage from './types/wfd/practiceWFDPage';
import '../adminPage.scss';
import AdminSideMenu from '../sideMenu/adminSideMenu';
import AdminNav from '../nav/adminNav';

export default function PracticePage(props) {

    return (
        <>
            <div id="mn-admin-page">
                <AdminNav />
                <main className="p-3">
                    <AdminSideMenu />
                    <Switch>
                        <Route path={props.match.url} exact>
                            <PracticeListPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.ra.name}`}>
                            <PracticeRAPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.di.name}`}>
                            <PracticeDIPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rs.name}`}>
                            <PracticeRSPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rl.name}`}>
                            <PracticeRLPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.asq.name}`}>
                            <PracticeASQPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.swt.name}`}>
                            <PracticeSWTPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.we.name}`}>
                            <PracticeWEPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rfib.name}`}>
                            <PracticeRFIBPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rwfib.name}`}>
                            <PracticeRWFIBPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rmcm.name}`}>
                            <PracticeRMCMPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.rmcs.name}`}>
                            <PracticeRMCSPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.ro.name}`}>
                            <PracticeROPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.hiw.name}`}>
                            <PracticeHIWPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.hcs.name}`}>
                            <PracticeHCSPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.sst.name}`}>
                            <PracticeSSTPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.smw.name}`}>
                            <PracticeSMWPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.mcm.name}`}>
                            <PracticeMCMPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.mcs.name}`}>
                            <PracticeMCSPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.wfd.name}`}>
                            <PracticeWFDPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.fib.name}`}>
                            <PracticeFIBPage />
                        </Route>
                        <Route path={`${props.match.url}${PATHS.practice.di.name}/list`} exact>
                            <div>DI</div>
                        </Route>
                    </Switch>
                </main>
            </div>
        </>
    )
}
