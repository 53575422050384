/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import 'styled-components/macro'
import './popupTested.scss'

export default function PopupTested(props) {
    return (
        <Modal id="mn-modal-feedback" 
        show={props.modalIsShow} onHide={props.hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Fill out your exam's information</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
                    <h5> Your test information will help us get more accurate preditcions </h5>
                        <div>
                            <form className="el-form el-form--label-top">
                                <div className="el-form-item">
                                    <label for='input-time' className="el-form-item__label">Exam date(required)</label>
                                    <div className="el-form-item__content">
                                        <div className="el-date-editor el-input el-input--small el-input--prefix el-input--suffix el-date-editor--date">
                                            <input type="date" autocomplete="off" name="" placeholder="Enter content" className="el-input__inner" id='input-time'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="el-form-item">
                                    <label className="el-form-item__label">Exam location(optional)</label>
                                    <div className="el-form-item__content">
                                        <div className="el-input">
                                            <input type="text" autocomplete="off" className="el-input__inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="el-form-item">
                                    <label className="el-form-item__label">Exam recall(optional)</label>
                                    <div className="el-form-item__content">
                                        <div className="el-textarea">
                                            <textarea autocomplete="off" rows="3" placeholder="Enter content" className="el-textarea__inner" css={`min-height: 33px;`}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
    
            <Modal.Footer>
                <Button variant="secondary" onClick={props.hideModal}>Cancel</Button>
                <Button variant="primary" onClick={props.hideModal}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}
