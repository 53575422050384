/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './courseBanner.scss'
import CourseBannerForm from './courseBannerForm'
import {Container, Row, Col} from 'react-bootstrap'

export default function CourseBanner() {
    return (
        <section id="mn-course-banner">
            <div className="content">
                
                <Container>
                    <Row>
                        <Col md={8} className="left mb-3">
                        <div className="left">
                            <img src={process.env.PUBLIC_URL+'/assets/images/cow_study.avif'} alt=""/>
                            <div className='title'>
                                <h1>Overseas PTE Live Classes</h1>
                                <h5>The choice of the majority of students, students from 65 countries and regions around the world</h5>
                            </div>
                            <div className="btns">
                                <div>1V1 solution</div>
                                <div>High-quality teachers</div>
                                <div>Efficient interaction</div>
                            </div>
                        </div>
                        </Col>
                        <Col md={4}>
                            <CourseBannerForm />
                        </Col>
                    </Row>
                </Container>
            </div>
             

            <div className="curve-bg">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
           
        </section>
    )
}
