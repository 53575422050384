/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import './popupScore.scss'

export default function PopupScore(props) {
  return (
    <Modal id="mn-modal-score" show={props.modalIsShow} onHide={props.hideModal}>
        <Modal.Header closeButton>
            <Modal.Title>AI score</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='body'>
                <div className='group'>
                <h8>User's answer</h8>
                <audio controls></audio>
                <h8>Score</h8>
                <p>Oral fluency</p>
                <div className='container'>
                    <div className='skills oral-score'>45/90</div>
                </div>
                <p>Pronunciation</p>
                <div className='container'>
                    <div className='skills pronunciation-score'>50/90</div>
                </div>
                <p>Overall</p>
                <div className='container'>
                    <div className='skills overall-score'>55/90</div>
                </div>
                <h8>AI analysis</h8>
                <div className='analysis-rate'>
                <span className='dot-good rate'>Good</span>
                <span className='dot-bad rate'>Bad</span>
                <span className='dot-average rate'>Average</span>
                </div>
                <p>User's answer here</p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={props.hideModal}>
               Close
            </Button>
        </Modal.Footer>
    </Modal>
  )
}
