/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Container, Row} from 'react-bootstrap'
import CourseItem from './courseItem'

const data = [

    {
        title: "All-round course", 
        subTitle1: "20 kinds of questions, 100 kinds of question types, comprehensive coverage", 
        subTitle2: "Suitable for students with basic skills",
        style: {background: "linear-gradient(#d5e0fc 0%, #9cade8 100%)"}
    },
    {
        title: "Intermediate Class", 
        subTitle1: "Explain high-frequency real questions, with special emphasis on oral English", 
        subTitle2: "Students who are suitable for sprinting",
        style: {background: "linear-gradient(#D7D3FA 0%, #B5B0EA 100%)"}
    },
    {
        title: "VIP", 
        subTitle1: "Flexible, specialized", 
        subTitle2: "Best for part-times",
        style: {background: "linear-gradient(#CDE8F3 0%, #96CFCB 100%)"}
    },
    {
        title: "1V1 Customized", 
        subTitle1: "Small class, strong supervision", 
        subTitle2: "PTE newbies, directly to dream score",
        style: {background: "linear-gradient(#FFE1C3 0%, #F7B879 100%)"}
    },
    {
        title: "Micro-Course", 
        subTitle1: "Small class, strong supervision", 
        subTitle2: "PTE newbies, directly to dream score",
        style: {background: "linear-gradient(#C9E9F3 0%, #90D7F0 100%)"}
    },
    {
        title: "Premium 1V1", 
        subTitle1: "Small class, strong supervision", 
        subTitle2: "PTE newbies, directly to dream score",
        style: {background: "linear-gradient(#BEE3FF 0%, #78BBFD 100%)"}
    }
]

export default function VIPCourse() {
    return (
        <div id="vip-courses" class="pb-3">
            <Container>
                <Row className="pb-5">
                    {
                        data.map((item, idx) => (
                            <CourseItem 
                            key = {`vip-${idx}`}
                            title = {item.title}
                            subTitle1 = {item.subTitle1}
                            subTitle2 = {item.subTitle2}
                            style = {item.style}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}
