/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const modelTest =[
    {
        id:"md-1",
        order:"1",
        title:"Mock Test",
        path:""
    },
    {
        id:"md-2",
        order:"2",
        title:"Mock Test (mini version)",
        path:""
    }
]

export const customTest=[
    {
        id:"ct-1",
        order:"1",
        title:"custom Test 1",
        path:""
    },
    {
        id:"ct-2",
        order:"2",
        title:"custom Test 2",
        path:""
    },
    {
        id:"ct-3",
        order:"3",
        title:"custom Test 3",
        path:""
    }
]

export const vipTest=[
    {
        id:"vt-1",
        order:"1",
        title:"Mock Test (Level 3)",
        path:""
    },
    {
        id:"vt-2",
        order:"2",
        title:"Mock Test (Level 2)",
        path:""
    },
    {
        id:"vt-3",
        order:"3",
        title:"Mock Test (Level 1)",
        path:""
    }
]