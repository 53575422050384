/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {AiFillCaretLeft} from 'react-icons/ai'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../global/constants'
import { mockTestData } from './data.js'
import {BiRightArrowAlt} from 'react-icons/bi'
import 'styled-components/macro'
import './mockTest.scss'

export default function VIPTest() {
  return (
    <div id='mn-mock-test'>
        <div className='title p-2'>
          <Link to={PATHS.admin.name+PATHS.mock.name}>
          <AiFillCaretLeft className='go-back' />
          </Link>
          PTE VIP Test
        </div>
        <div className='body p-5'>
            <ul className="mb-3 px-5 py-3">
            {mockTestData.map((data,idx) =>{
                    return(
                        <>
                        <li classname="test-option-list" key={idx} css={`background-color: ${data.bgColor};`}>
                            <div  className="p-3">                            
                            <h4 css={`color: ${data.color};`}>{data.title}</h4>
                            <p css={`color: ${data.color};`}>{data.des}</p>
                            <Link
                                to={data.path}
                                css={`color: ${data.color}; background-color: ${data.bgColor}`}
                            >
                                <div  className="link-btn">Take a test  <BiRightArrowAlt /></div>
                            </Link>
                            </div>
                        </li>
                        </>
                    )
                })}
            </ul>
        </div>
    </div>
  )
}
