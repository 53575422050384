/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {withTranslation} from 'react-i18next'
import {AiOutlineRightCircle} from 'react-icons/ai'

function MockItem() {
    return (
        <div className="mock-item p-4">
            <h3>Simulation test</h3>
            <p>Simulate the real test environment</p>
            <AiOutlineRightCircle />
        </div>
    )
}

export default withTranslation()(MockItem)
