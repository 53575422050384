/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const UserPracticeData=[
    {
        id:"user-1",
        username:"User 001",
        audiofile:"user1.mp3",
        practice:"user's answer here"
    },
    {
        id:"user-2",
        username:"User 002",
        audiofile:"user1.mp3",
        practice:"user's answer here"
    },
    {
        id:"user-3",
        username:"User 003",
        audiofile:"user1.mp3",
        practice:"user's answer here"
    }
]