/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './navMock.scss'

function NavMock() {
    return (
        <div id="mk-list" className="mk-list">
            <div className="mk-list-item">
                <h5>Mock Test</h5>
                <p id="StandardWork">PTE level quiz</p>
                <p id="StandardWork">
                    <a className="isLogin" href="/Exam/levelTest.html">Guaranteed class entrance test</a>
                </p>
                <p id="MoldTestCode">Free mock test paper 1</p>
                <p id="MoldTestCode">Free mock test paper 2</p>
                <p id="MoldTestCode">Free mock test paper 3</p>
            </div>
            <div className="mk-list-item">
                <h5>Self-study classes</h5>
                <p id="MoldTestCode">Course Code</p>
                <p id="MoldTestCode">Remedial class model test</p>
            </div>
            <div className="mk-list-item">
                <h5>Off Work</h5>
                <p id="MoldTestCode">Class Quiz</p>
                <p id="MoldTestCode">Offline Model Test</p>
            </div>
        </div>
    )
}

export default NavMock
