/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import { memories } from './memoryData'
import './memoryPage.scss'
import PopupMemory from './popupMemory'

export default function MemoryPage() {
    const [addIsShow, setAddIsShow] = useState(false)
    return (
        <div  id="mn-memory">
            <div className="practice-panel" >
                <div className="title p-2"> 
                    Exam memories
                </div>
                <div className='body'>
                    <table>
                        <tr className='table-head'>
                            <th>#</th>
                            <th>Date</th>
                            <th>Memory</th>
                            <th>Operate</th>
                            <th><button onClick={() =>{setAddIsShow(true)}}>Add memory</button></th>
                        </tr>
                        {memories &&
                        <>
                            <div className='empty-space'>
                            <img src={process.env.PUBLIC_URL+'/assets/images/planIndex.avif'} alt='empty-img'/>
                            <p>No memories</p>
                            </div>
                        </>
                        }
                    </table>
                </div>
            </div>
            <PopupMemory
               modalIsShow={addIsShow}
               hideModal ={() =>{setAddIsShow(false)}}
            />
        </div>
    )
}
