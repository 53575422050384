/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */


import React from 'react'
import {Form, Button} from 'react-bootstrap'


export default function CourseBannerForm() {
    return (
    <Form className="right text-center p-3">
        <h4>Free pick up<br />A big gift bag worth 1080 AUD</h4>
        <Form.Group controlId="formBasicPassword">
            <Form.Control type="phone" placeholder="Telephone" />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" placeholder="Mail" />
            <Form.Text className="text-muted">
            Manniu PTE will protect the personal privacy of each student
            </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button variant="primary" type="submit">
        Click to get it for free
        </Button>
    </Form>
    )
}
