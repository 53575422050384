/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { groupData } from './data'
import './studyGroups.scss'

export default function StudyGroups() {
  return (
    <div id="mn-study-groups">
        <div className='study-groups-panel'>
            <div className='title p-2'>
                Study Groups
            </div>
            <div className='groups px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='header'>
                        <div className='add-group'>
                            <button className='add-btn'><AiOutlinePlus/> Add group</button>
                        </div>
                        <input type="text" autocomplete="off" placeholder=" SEARCH..."/>
                        <div className="search-input">
                            <button type="button" className="search-btn">
                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                    <br/>
                    <div className='body'>
                        <ul className='group-list'>
                            {groupData.map(group =>{
                                return(
                                    <li className='group-info'>
                                        <div className='group-icon'><img src={process.env.PUBLIC_URL+'/assets/images/icon.jpeg'} alt='group-icon'/></div>
                                        <div className='detail-space'>
                                        <h5><b>{group.title}</b></h5>
                                        <p>{group.des}</p>
                                        <div className='member-icon'>
                                            <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user-icon'/> 
                                            <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user-icon'/>
                                            <AiOutlinePlus/>
                                        </div>
                                        <button className='delete-btn'>Remove</button>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
