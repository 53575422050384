/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const practiceData ={
    header: {
        name: "Exam date：2021-3-29",
        btn: "Set target"
    }, 
    body: [
        {
            title: "0",
            des: "Today Practiced"
        },
        {
            title: "433",
            des: "Total Practiced"
        },
        {
            title: "27",
            des: "Practice days"
        }
    ],
    footer:"Click to view"
}
export const examData ={
    header: {
        name: "Last exam date：2021-3-29",
        btn: "Set date"
    }, 
    body: [
        {
            title: "0",
            des: "Total Exams"
        },
        {
            title: "0",
            des: "Overall score"
        },
        
    ],
    footer:"Click to view"
}