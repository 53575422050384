/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer} from 'react'
import { QuestionWrapper } from '../questionWrapper'
import {Button} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import PracticeAudioIndicator from '../../common/audioIndicator/practiceAudioIndicator'
import AudioPlayer from '../../common/audioPlayer/audioPlayer'
import './practiceASQPage.scss'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
     const [showTranscript, setShowTranscript] = useState(false)
     const [showAnswer, setShowAnswer] = useState(false)

    // set play duration
    useEffect(() => {
        var audio
        if (props.questionData && props.questionData.audioPath) {
            audio = new Audio(props.questionData.audioPath)
            audio.addEventListener("loadedmetadata", () => {
                props.setPlayDuration(Math.ceil(audio.duration))
            })
        }
        return () => {
            if (audio != undefined) {
                audio.removeEventListener("loadedmetadata", () => {
                    props.setPlayDuration(audio.duration())
                })
            }
        }
    }, [props.questionData])


    return (
        <div>
            {
                props.questionData && props.questionData.audioPath &&
                <>
                <div className="audio-speaker mb-3">
                    <AudioPlayer 
                    state = {props.state}
                    dispatch = {props.dispatch}
                    audioSrc = {props.questionData.audioPath}
                    />
                    <PracticeAudioIndicator 
                    state={props.state}
                    dispatch = {props.dispatch}
                    />
                </div>
                <Button variant="outline-primary" onClick={() => {setShowTranscript(!showTranscript)}}>Show Transcript</Button>
                
                <Button variant="outline-primary" onClick={() => {setShowAnswer(!showAnswer)}}>Show Answer</Button>

                <div>
                <ClickableParagraph
                show = {showTranscript}>
                    {props.questionData.content}</ClickableParagraph>
                <ClickableParagraph show = {showAnswer}>{props.questionData.title}</ClickableParagraph>
                </div>
                </>
            }
            {
                !props.questionData &&
                <>
                <p id="asq-1" title='Departure'>
                    Which section of a train timetable will tell you what time your train leaves?
                </p>
                <audio src="asq-1-03a7650d-73a0-4d72-896c-73fec87002b0.mp3" controls>
                </audio>
                </> 
            }
        </div>
    )
}


const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeASQPage() {
    // [TODO] the listen time is different according to each audio, but to implement that?
    // here just set a unit seconds
    // [COMMENT], we set the id starting from -1 because the PracticeAudioIndicator starts from 0
    const [playDuration, setPlayDuration] = useState(5)


    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        record: {id: 1, seconds: 10, currentTime: 0}, 
        idle: {id: 2, currentTime: -16}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toRecord: "toRecord", 
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toRecord:
                return STATES.record
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toRecord})
            }
            
        // record
        } else if (state.id === 1) {
            if (state.currentTime < state.seconds) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.increase})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
     
    }, [state])



    return (
        // 
        <>
        <Helmet>‍
            <title>蛮牛PTE题目练习（ASQ）Answer Short Question</title>
            <meta name="Keywords" content="蛮牛PTE,蛮牛pte,墨尔本PTE,墨尔本pte,澳洲蛮牛,蛮牛教育,墨尔本蛮牛PTE/CCL培训学校,蛮牛PTE/CCL培训学校,PTE/CCL培训学校"></meta>
            <meta name="Description" content="蛮牛PTE，全球超有责任心的PTE英语教育机构，评测/刷题/辅导三维一度教学体系，和每周线上真题预测，帮你快速通过PTE考试，带给你不一样的学习体验"  data-react-helmet="true" />    
        </Helmet>
        <QuestionPage 
            questionType="asq" 
            state = {state}
            dispatch = {dispatch}
            setPlayDuration = {setPlayDuration}
        />
        </>
    )
}