/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect, useState} from 'react'
import 'styled-components/macro'
import {Link, withRouter} from 'react-router-dom'
import './practiceListPage.scss'
import axios from 'axios'
import { PATHS, BASE_URL} from '../../../../global/constants'

const speakData = ["ra", "di", "rl", "rs", "asq"]
const writeData = ["swt", "we"]
const readData = ["rfib", "rmcm", "rmcs", "ro", "rwfib"]
const listenData = ["sst", "mcm", "fib", "hcs", "hiw", "mcs", "smw", "wfd"]

function PracticeListPage(props) {
    /**
     * get question numbers for every question
     */
    const [questionCounts, setQuestionCounts] = useState({
        ra: 0, rs: 0, di: 0, rl: 0, asq: 0, 
        swt: 0, we: 0, 
        ro: 0, rmcm: 0, rmcs: 0, rfib: 0, rwfib: 0, 
        mcm: 0, mcs: 0, hiw: 0, hcs: 0, sst: 0, smw: 0, fib: 0, wfd: 0
    })

    useEffect(() => {
        Object.keys(questionCounts).map((type) => {
            let api_count = BASE_URL + "count/" + type 
            axios.get(api_count)
            .then((response) => {
                setQuestionCounts((prevState) => ({
                    ...prevState, 
                    [type]: response.data.object
                }))
            })
            .catch(error =>{
                console.log(error)
            })
        })
        
    }, [])


    /**
     * get local question info
     */
    const [questionInfos, setQuestionInfos] = useState({
        ra: {}, rs: {}, di: {}, rl: {}, asq: {}, 
        swt: {}, we: {}, 
        ro: {}, rmcm: {}, rmcs: {}, rfib: {}, rwfib: {}, 
        mcm: {}, mcs: {}, hiw: {}, hcs: {}, sst: {}, smw: {}, fib: {}, wfd: {}
    })
    useEffect(() => {
        Object.keys(questionInfos).map((type) => {
            axios.get(process.env.PUBLIC_URL + "/assets/"+type+".json")
            .then((res) => { 
                setQuestionInfos((prevState) => ({
                    ...prevState, 
                    [type]: res.data
                }))
            });
        });
    }, [])

    return (
        <div id="mn-practice-list">
            <div className="practice-panel">
                <div className="title p-2">Practice</div>
                <div className="exercises px-5 py-3">
                    <ul className="mb-3 px-5 py-3">
                        <li className="exercises-list">
                            <p>
                                <span>Part 1</span>
                                <img src={process.env.PUBLIC_URL+'/assets/images/speak.avif'} alt="speaking" />
                                <i>Speaking</i>
                            </p>
                            <div className="list-tab">
                                {
                                    speakData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName} 
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                        <li className="exercises-list" style={{alignItems: "center"}}>
                            <p>
                                <img src={process.env.PUBLIC_URL+'/assets/images/write.avif'} alt="icon_writing" />
                                <i>Writing</i>
                            </p>
                            <div className="list-tab" css={`margin-top: 20px;`}>
                                {
                                    writeData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                    <ul className="mb-3 px-5 py-3">
                        <li className="exercises-list">
                            <p>
                                <span>Part 2</span>
                                <img src={process.env.PUBLIC_URL+'/assets/images/read.avif'} alt="icon_reading" />
                                <i>Reading</i>
                            </p>
                            <div className="list-tab">
                                {
                                    readData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {false} //{questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                    <ul className="m-0 px-5 py-3"> 
                        <li className="exercises-list">
                            <p>
                                <span>Part 3</span>
                                <img src={process.env.PUBLIC_URL+'/assets/images/listen.avif'} alt="icon_listening" className="icon_listening-3" />
                                <i>Listening</i>
                            </p>
                            <div className="list-tab">
                                {
                                    listenData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {false}//{questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PracticeListPage)


function ExerciseUnit(props) {
    return (
        <Link to={props.destination} className={props.hasAI ? "ai" : ""}>
            <span className="p-1">{props.shortName}</span>
            {props.hasAI &&
                <div>
                    {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions})
                </div>
            }
            {props.shortName === "SST"  && !props.hasAI &&
                <div>
                    {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions})
                </div>
            }
            {props.shortName === "WFD"  && !props.hasAI &&
                <div>
                    {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions})
                </div>
            }
            
            {!props.hasAI && props.shortName !== "SST" && props.shortName !== "WFD" &&
                <div>
                    {props.name} ({props.numOfQuestions})
                </div>
            }
            
        </Link>
    )
}