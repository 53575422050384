/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import { Route, Switch, BrowserRouter} from 'react-router-dom'
import Tutoring from './tutoring'
import Schedule from './schedule'
import { PATHS } from '../../../global/constants'

export default function tutorRoute() {
    return (
        <>
        <BrowserRouter>
        <Switch>
          <Route path={PATHS.admin.name+PATHS.tutoring.name} exact component={Tutoring}/>
          <Route path={PATHS.admin.name+PATHS.tutoring.schedule.name} component={Schedule}/>
        </Switch>
        </BrowserRouter>
        </>
      )
}
