/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer} from 'react'
import { QuestionWrapper } from '../questionWrapper'
import AudioPlayer from '../../common/audioPlayer/audioPlayer'
import {Button} from 'react-bootstrap'
import "./practiceHIWPage.scss"

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const wordLst=[]
    const ansLst = []
    var lastState = false
    const [state, setState] = useState(0)
    const [showColor, setColor] = useState(false)
    var count = 0
    

    function hiw_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
    
        const eles = paragraph.split(" ")
        const new_eles = eles.map((ele) => {
            if (punctuations.includes(ele)) {
                return;
            } else if (ele.includes("</answer>")) {
                const v= ele.replace("</answer>","")
                ansLst.push(v)
                return ""
            }else{
                return  ele;
            }
        })
        return new_eles.join(" ");
      }
    
    function hiw_proccessed_paragraph(text){
        const transformed_text = text
       
        const eles = transformed_text.split(" ")
        count = eles.length - 1
        eles.map(ele => {
            if(ele.includes("<answer>")){
                ele = ele.replace("<answer>"," ")
            }
          wordLst.push(ele)
        })
        return wordLst
    }

    function hightlight(e){

    }

    function lastStatus(index) {
        if(state===0)
        {
            lastState = true
        }
        else{
            if (count === index) {
                lastState = true
            } else { lastState = false }
        }
        return lastState
    }
    function clearOut(){
        const answers = document.getElementsByClassName('answer')
        Array.from(answers).forEach(txt =>{
            txt.className="non-active"
        })
    }

    function checkCorrectAns(){
        const answers = document.getElementsByClassName('non-active')
        var i = 0
        const eles = props.questionData.content.split(" ")
        if(state === 0){
            setState(1)
        }else if(state === 1){
            setState(0)
        }
        eles.map((ele, idx) =>{
            if(ele.includes("<answer>")){
                answers[idx].innerHTML = ansLst[i]
                i++
            }
            else if(!ele.includes("<answer>")){
                answers[idx].style.display='none'
            }
        })
        
    }

    useEffect(() => {
        setState(0)
        clearOut()
    }, [props.questionId])

    return (
        <>
        <div>
            {
                props.questionData &&props.questionData.audioPath &&
                <>
                <div className='audio-speaker mb-3'>
                <AudioPlayer 
                    state = {props.state}
                    dispatch = {props.dispatch}
                    audioSrc = {props.questionData.audioPath}
                />
                </div>
                { hiw_proccessed_paragraph(props.questionData.content).map((text, idx) =>{
                    return(
                        <>
                        <span key={idx} value={text} id={idx} 
                        onClick={hightlight}>
                            {hiw_transform_paragraph(text)} </span>
                        <p className={lastStatus(idx) === true ? "non-active": "answer"} id={idx}></p>
                        </>    
                    )
                    })
                }
                <br/>
                <Button variant="outline-primary" onClick={checkCorrectAns}>Check answer</Button>
                </>
            }
            {
                !props.questionData && 
                <>
                <p>Is loading...</p>
                </> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeHIWPage() {
    const [playDuration, setPlayDuration] = useState(45)

    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        idle: {id: 1}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
    }, [state])

    return (
        // 
        <QuestionPage 
        questionType="hiw" 
        state = {state}
        dispatch = {dispatch}
        setPlayDuration = {setPlayDuration}
        />
    )
}