/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import { QuestionWrapper } from '../questionWrapper'
import {Form, Button} from 'react-bootstrap'
import './practiceRMCSPage.scss'
//import Rmcs from './rmcs'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {

    function rmcs_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        const letters = ["A.", "B.", "C.", "D.", "E.", "F."]
        const bracket_A = ["[A].", "[a].", "(A).", "(a)."]
        const bracket_B = ["[B].", "[b].", "(B).", "(b)."]
        const bracket_C = ["[C].", "[c].", "(C).", "(c)."]
        const bracket_D = ["[D].", "[d].", "(D).", "(d)."]
        const bracket_E = ["[E].", "[e].", "(E).", "(e)."]
    
        const eles = paragraph.split(" ")
        
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            } else if (letters.includes(ele)) {
                return ''
            } else if(bracket_A.includes(ele) || bracket_B.includes(ele) 
            || bracket_C.includes(ele) || bracket_D.includes(ele) || bracket_E.includes(ele)){
                return ''
            } else if (ele.includes("<answer>")) {
                return ''
            } else if(ele.includes("</answer>")){
              const v = ele.replace("</answer>", "")
              return  v 
            }else{
              return  ele;
            }
        })
        return new_eles.join(" ");
    }
    function clearOut(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
            input.checked = false
        })
        Array.from(label_eles).forEach(label =>{
          label.style.background="white"
        })
    }

    function checkCorrectAns(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
        if(input.checked){
            Array.from(label_eles).forEach(label =>{
            const answer = label.getAttribute('value')
            if(answer.includes("<answer>"))
            {   label.style.background="#4beba8"}
            })
        }
      
    })
    }

    useEffect(() => {
        clearOut()
    }, [props.questionId])

    return (
        <>
        <div>
        {
                props.questionData && 
                <div className="content">
                    <div className="left">
                        <ClickableParagraph>{props.questionData.content}</ClickableParagraph>
                    </div>

                    <div className="right">
                        <div className="top mb-3">
                        <ClickableParagraph strong={true}>{props.questionData.question}</ClickableParagraph>
                        {
                            props.questionData.options.map((o, idx) => {
                                return(
                                    <>
                                    <input type="radio" key={idx} name="options" id={idx} 
                                    value={o}
                                    ></input>
                                    <label for id={idx} value={o} className="options">
                                       {rmcs_transform_paragraph(o)}
                                    </label><br/>
                                    </>
                                )
                            })
                        }
                        </div>
                        <Button variant="outline-primary" onClick={checkCorrectAns}>Check answer</Button>
                    </div>
                </div>   
            }
            {
                !props.questionData && 
                <p>Is loading... </p>
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRMCSPage() {
    return (
        // 
        <QuestionPage questionType="rmcs" />
    )
}