/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import './fixFooter.scss'
export default function FixFooter(){
    
    const [barIsHide, setBarIsHide] = useState(false);
    const hideBar = () => (
        setBarIsHide(true)
    )


    return (
        <div id="mn-fix-footer" style={{display: barIsHide ? "none" : "display"}}>
            <div className="mn-fix-footer-pc">
                               
                <div className="ad-text">
                    <p>Tell us how do you feel about the website</p>
                </div>
                <div className="ad-form">
                    <img className="form-icon" src={process.env.PUBLIC_URL+'/assets/images/icon_phone.avif'} alt="" />
                    <input className="form-input" type="text" placeholder="Please enter your mobile number" maxLength="11" />
                    <button className="form-submit">Get it for free</button>
                </div>

                <img className="ad-close" src={process.env.PUBLIC_URL+'/assets/images/icon_close.avif'} alt="" onClick={hideBar} />
            </div>
        </div>
        
    )
}
