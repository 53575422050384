/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import './experienceSharing.scss'
import { AiOutlinePlus, AiFillHeart, AiOutlineComment, AiFillDislike } from 'react-icons/ai'
import { commentData } from './data'

export default function ExperienceSharing() {
  return (
    <div id="mn-experience-sharing">
        <div className='experience-sharing-panel'>
            <div className='title p-2'>
                Experience Sharing
            </div>
            <div className='experiences px-5 py-3'>
                <div className="mb-3 px-5 py-3">
                    <div className='header'>
                        <div className='user-space'>
                            <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user-icon'/>
                            <p>Username</p>
                        </div>
                        <button className='add-btn'><AiOutlinePlus/> New Post</button>
                    </div>
                    <div className='body'>
                        {commentData.map(data =>{
                            return(
                                <div className='comment-section'>
                                    <div className='comment-space'>
                                        <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user-post-img'/>
                                        <p><b>{data.post}</b></p>
                                    </div>
                                    <p>{data.content}</p>
                                    <div className='comment-footer'>
                                        <AiFillHeart/> <AiOutlineComment /> <AiFillDislike/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
