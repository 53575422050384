/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import './loginForm.scss'
import 'styled-components/macro'
import {Form, Row, Col, Button} from 'react-bootstrap'
import {countryCodes, loginStateEnum, formErrorEnum} from './data'
import auth from '../../utils/auth'
import FormEmailLogin from './formEmailLogin'
import FormEmailRegister from './formEmailRegister'
import FormEmailForget from './formEmailForget'
import { useEffect } from 'react'
import jwtDecode from 'jwt-decode'


export default function LoginForm(props) {
    const [countryCode, setCountryCode] = useState(countryCodes[0])
    const [loginState, setLoginState] = useState(loginStateEnum.emailLogin)

    /**
     * post to backend 
     */
    const [formInfo, setFormInfo] = useState({
        username: "", 
        email: "",
        password: "",
        phone: "",
        mode: "",
        pic:""
    })

    function headerReducer(itemType) {
        {/* Phone System */}
        // phoneRegister
        if (loginState === loginStateEnum.phoneRegister) {
            if (itemType === loginStateEnum.phoneRegister) {
                // show register 
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    
        else if (loginState === loginStateEnum.phoneForget) {
            if (itemType === loginStateEnum.phoneForget) {
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    

        /* Email System */
        if (loginState === loginStateEnum.emailRegister) {
            if (itemType === loginStateEnum.emailRegister) {
                // show register 
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    
        else if (loginState === loginStateEnum.emailForget) {
            if (itemType === loginStateEnum.emailForget) {
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }

        /* APP System */
        else {
            if (itemType === loginStateEnum.phoneRegister ||
                itemType === loginStateEnum.phoneForget ||
                itemType === loginStateEnum.emailRegister ||
                itemType === loginStateEnum.emailForget 
                ) {
                    return "hide"
                } else if (itemType === loginState) {
                    return "active"
                } else {
                    return ""
                }
            }
    }

    let history = useHistory();

    function handleCallbackResponse(respond){
        var userObject = jwtDecode(respond.credential)
        console.log(userObject)
        auth.gmailLogin(userObject)
        console.log(userObject.picture)
        history.push("/admin");
    }

    useEffect(() =>{
        /* global google */
        google.accounts.id.initialize({
            client_id: "407682493955-3dderhhjm9p7cqg8883svgrqodu8qa1h.apps.googleusercontent.com",
            callback: handleCallbackResponse
        })

        google.accounts.id.renderButton(
            document.getElementById("google-login"),{
                theme: "outline", size: "large", width: 200, height:100
            }
        )
    }, [])

    return (
        <div id="mn-login-form">
            <div className="login-box">
                <div className="header">
                    {/* Phone System */}
                    {/* <div 
                        className={`item ${headerReducer(loginStateEnum.phoneLogin)} `}
                        onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}} >
                            手机登录
                    </div> 
                    <div className={`item ${headerReducer(loginStateEnum.phoneRegister)} `}>手机注册</div>
                    <div className={`item ${headerReducer(loginStateEnum.phoneForget)} `}>忘记密码</div> */}
                    
                    {/* Email System */}
                    <div 
                        className={`item ${headerReducer(loginStateEnum.emailLogin)} `}
                        onClick={()=>{setLoginState(loginStateEnum.emailLogin)}}>
                            Account
                    </div>
                    <div className={`item ${headerReducer(loginStateEnum.emailRegister)} `}>Sign up</div>
                    <div className={`item ${headerReducer(loginStateEnum.emailForget)} `}>Forgot password</div>

                    {/* App System */}
                    <div 
                        className={`item ${headerReducer(loginStateEnum.app)}`}
                        onClick={()=>{setLoginState(loginStateEnum.app)}}>         
                            APP
                    </div>
                </div>
                <div className="detail p-4">
                    <div className="wechat" css={`display: ${loginState === loginStateEnum.wechat ? "block" : "none"};`}>
                    </div>
                    <div className="app" css={`display: ${loginState === loginStateEnum.app ? "block" : "none"};`}>
                        App <br />
                        <small className="text-secondary">Open the APP, click the scan icon in the upper right 
                        corner of the home page, and scan the code to log in</small>
                        <br/>
                        <img src={process.env.PUBLIC_URL + "/assets/images/qr-code.png"} alt="qrcode-img"
                         style={{width:200, height: 200}}></img>
                    </div>




                    {/* Email */}
                    <FormEmailRegister 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />

                    <FormEmailForget 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />

                    <FormEmailLogin 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />


                    {/* Phone */}
                    <div className="phoneRegister" css={`display: ${loginState === loginStateEnum.phoneRegister ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode}/>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="Please enter a password of 8-20 digits" />
                            </Row>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="Confirm password" />
                            </Row>
                            <Row className="mt-3">
                                <Col sm={8} className="p-0">
                                    <Form.Control type="text" placeholder="Please enter verification code" />
                                </Col>
                                <Col sm={4} className="p-0">
                                    <Button type="submit">Get verification code</Button>
                                </Col>
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}}>
                                Mobile login
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneForget)}}>
                                Forgot password？
                            </div>
                        </div>
                        <div className="enter">Register</div>
                    </div>
                    <div className="phoneForget" css={`display: ${loginState === loginStateEnum.phoneForget ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode} />
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="New Password" />
                            </Row>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="Confirm password" />
                            </Row>
                            <Row className="mt-3">
                                <Col sm={8} className="p-0">
                                    <Form.Control type="text" placeholder="Please enter verification code" />
                                </Col>
                                <Col sm={4} className="p-0">
                                    <Button type="submit">Get verification code</Button>
                                </Col>
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneRegister)}}>
                                Sign up now
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}}>
                                Mobile Login
                            </div>
                        </div>
                        <div className="enter">Reset password</div>
                    </div>
                    <div className="phoneLogin" css={`display: ${loginState === loginStateEnum.phoneLogin ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode} />
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="密码" />
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneRegister)}}>
                                Sign up now
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneForget)}}>
                                Forgot password？
                            </div>
                        </div>
                        <div className="enter" onClick={() => auth.login(()=>{props.history.push(props.location.state.from.pathname)})}>Log in</div>
                    </div>
                </div>
                <div className='dash'> <b>or</b></div>
                <div className='social-media-login'>
                    <div id="google-login"></div>
                </div>
            </div>
        </div>       
    )
}




function LoginPhone(props) {
    return (
        <Row>
            <Col sm={4} className="p-0">
                <Form.Control as="select" defaultValue={props.countryCode}>
                    {
                        countryCodes.map((c) => (
                            <option>{c}</option>
                        ))
                    }
                </Form.Control>
            </Col>
            <Col sm={8} className="p-0">
                <Form.Control placeholder="Mobile phone number" />
            </Col>
        </Row>
    )
}


