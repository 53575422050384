/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect} from 'react'
import './practiceFooter.scss'
import {getQuestionID, getTypeLower} from '../utils'
import PopupTested from '../tested/popupTested'
import PopupFeedback from '../feedback/popupFeedback'
import axios from 'axios'
import { BASE_URL } from '../../../../../global/constants'
import PreviousBtn from "./prevBtn"
import NextBtn from './nextBtn'

export default function PracticeFooter(props) {
    const [feedbackIsShow, setFeedbackIsShow] = useState(false)
    const [testedIsShow, setTestedIsShow] = useState(false)
    const [order, setOrder] = useState(true) // default is positive
    const [questionCount, setQuestionCount] = useState(0)
    const [_questionId, setQuestionId] = useState(getQuestionID(props.slug))
    const [slideIndex, setSlideIndex] = useState(1)
    
    useEffect(() => {
        setQuestionId(getQuestionID(props.slug))
    }, [props.slug])

    const nextSlide = ()=>{
        if(slideIndex !== questionCount){
            setSlideIndex(slideIndex + 1)
        }
        else if(slideIndex === questionCount){
            setSlideIndex(1)
        }
    }
    
    const prevSlide = ()=>{
        if(slideIndex !== 1){
          setSlideIndex(slideIndex - 1)
        }
        else if(slideIndex === 1){
          setSlideIndex(questionCount)
        }
    }

    /**
     * conditional dispatch
     */
    function resetDispatch() {
        if (props.dispatch !== null && props.dispatch !== undefined) {
            props.dispatch({type: "reset"})
        }
    }

    /**
     * get question counts
     */
    useEffect(() => {
        
        axios.get(BASE_URL+"count/" + getTypeLower(props.slug))
        .then((response) => { 
            setQuestionCount(response.data.object)
        });

    }, [])

    return (
        <><div id="mn-practice-footer" className="px-3 py-2">
            <div className="left">
                {/* because actions in each question will differ, but toPrepare is the same */}
                <span className="bg" onClick={() => { resetDispatch() } }>Redo</span>
                <span className="bg" onClick={() => { setTestedIsShow(true) } }>Tested</span>
                <span className="bg">Collect</span>
                <span className="bg" onClick={() => { setFeedbackIsShow(true) } }>Feedback</span>
            </div>

            <div className="right">
                <div className="bg">
                    <span onClick={() => { setOrder(true) } }>Up</span>&nbsp;|&nbsp;
                    <span onClick={() => { setOrder(true) } }>Down</span>&nbsp;|&nbsp;
                    <span onClick={() => { setOrder(false) } }>Random</span>
                </div>
                <span className="bg" onClick={props.setModalShow}>No.{getQuestionID(props.slug)}/{questionCount}</span>
                <div className="bg">
                    <span
                        className={_questionId === 1 ? "disabled" : ""}
                        onClick={_questionId !== 1 && (
                            () => {
                                props.setQuestionId(_questionId - 1)
                                resetDispatch()
                            })
                        }><PreviousBtn moveSlide={prevSlide}/></span>&nbsp;/&nbsp; 
                    <span
                        className={_questionId === questionCount ? "disabled" : ""}
                        onClick={_questionId !== questionCount && (
                            () => {
                                props.setQuestionId(_questionId + 1)
                                resetDispatch()
                            }
                        )}><NextBtn moveSlide={nextSlide}/></span>
                </div>
            </div>

            <PopupFeedback
                modalIsShow={feedbackIsShow}
                hideModal={() => { setFeedbackIsShow(false) } } />
            <PopupTested
                modalIsShow={testedIsShow}
                hideModal={() => { setTestedIsShow(false) } } />
        </div>
        <script src="MySlides.js"></script>
        </>
    )
}
