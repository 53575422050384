/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer} from 'react'
import { QuestionWrapper } from '../questionWrapper'
import AudioPlayer from '../../common/audioPlayer/audioPlayer'
import"./practiceFIBPage.scss"
import {Button} from 'react-bootstrap'

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const ansLst=[]
    var count = 0
    var lastState = false
    var lastState2 = false
    const [state, setState] = useState(0)
    const contentLst =[]

    function fib_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
    
        const eles = paragraph.split(" ")
        
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            } else if (ele.includes("<answer>")) {
                return "<blank> <answer box>" 
            }else{
              return  ele;
            }
        })
        return new_eles.join(" ");
    }

    function getContentLst(text){
        const content = fib_transform_paragraph(text)
        const eles = content.split('<blank>')
        count = eles.length - 1

        eles.map(ele =>{
            if(ele.includes('<answer box>')){
                ele = ele.replace('<answer box>','')
            }
            contentLst.push(ele)
        })
        return contentLst
    }

    function getAnswLst(paragraph){
        const eles = paragraph.split(' ')
        eles.map(ele=>{
            if(ele.includes("<answer>"))
            {   
                const v = ele.replace('<answer>',"").replace('</answer>'," ")
                ansLst.push(v)
            }
        })
        
        return ansLst
    }
    
    function lastStatus(index){
        if (count === index) {
            lastState = true
        } else { lastState = false }
        return lastState
    }

    function lastStatus2(index) {
        if(state===0)
        {
            lastState2 = true
        }
        else{
            if (count === index) {
                lastState2 = true
            } else { lastState2 = false }
        }
        return lastState2
    }

    function checkCorrectAns(){
        if(state === 0){
            setState(1)
        }else if(state === 1){
            setState(0)
        }
        const answers = document.getElementsByClassName('answer')
        const answ_list = getAnswLst(props.questionData.content)
        Array.from(answers).forEach(txt =>{
            txt.innerHTML = answ_list[txt.id]
        })
    }

    function clearOut(){
        const answer1 = document.getElementById('answers')
        if(answer1 !== null){
            answer1.value=''
        }
    }
    
    useEffect(() => {
        setState(0)
        clearOut()
    },[props.questionId])

    return (
        <>
        <div>
            {
                props.questionData && props.questionData.audioPath &&
                <>
                <div className='audio-speaker mb-3'>
                <AudioPlayer 
                     state = {props.state}
                     dispatch = {props.dispatch}
                     audioSrc = {props.questionData.audioPath}
                />
                </div>
                <div>
                    {getContentLst(props.questionData.content).map((data, idx) =>{
                        return(
                            <>
                            <p key={idx}>{data}</p>
                            <input
                            type='text' id='answers' 
                            style={lastStatus(idx) === true ? { display: "none" } : { display: "inline-block" }}>
                            </input>
                            <p className='answer' id={idx} 
                            style={lastStatus2(idx) === true ? { display: "none" } : { display: "inline-block" }}>
                            </p>
                            </>
                        )})
                    }
                    <br/>
                    <Button variant="outline-primary" onClick={checkCorrectAns}>Check answer</Button>
                </div>
                </>
            }
            {
                !props.questionData && 
                <>
                <p>Is loading...</p>
                </> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeFIBPage() {
    const [playDuration, setPlayDuration] = useState(20)

    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        idle: {id: 1}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
    }, [state])

    return (
        // 
        <QuestionPage 
        questionType="fib" 
        state = {state}
        dispatch = {dispatch}
        setPlayDuration = {setPlayDuration}
        />
    )
}