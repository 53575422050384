/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer} from 'react'
import './practiceDIPage.scss'
import PracticeAudioIndicator from '../../common/audioIndicator/practiceAudioIndicator'
import ImageUploader from '../../common/uploadImage/imageUploader'
//import Di from './Di'
//import picURL from '../../../../../components/stream/banner/images/bg.avif'


import { QuestionWrapper } from '../questionWrapper'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <div className="content">
            {
                props.questionData && (props.questionData.imagePath !== "") && 
                <img src={props.questionData.imagePath} alt={"di-"+props.questionId} />
            }
            {
                !props.questionData || (props.questionData.imagePath === "") && <ImageUploader 
                questionType="di" 
                questionId={props.questionId}
                questionData = {props.questionData} />
            }
            {
                !props.questionData && 
                <>
                Is loading...
                </>
            }       
            <div className="mb-3">
                <PracticeAudioIndicator 
                state={props.state}
                dispatch = {props.dispatch}
                />
            </div>
        </div>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeDIPage() {
    const [error, setError] = useState(null);
    const DISTATES = {
        prepare: {id: 0, seconds: 40, currentTime: 40},
        record: {id: 1, seconds: 40, currentTime: 0},
        idle: {id: 2, currentTime: -41}
    }
    
    const DIACTIONS = {
        reset: "reset",
        toRecord: "toRecord",
        toIdle: "toIdle",
        decrease: "decrease", 
        increase: "increase"
    }
    
    const initialState = DISTATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case DIACTIONS.reset:
                return DISTATES.prepare
            case DIACTIONS.toRecord:
                return DISTATES.record
            case DIACTIONS.toIdle:
                return DISTATES.idle
            case DIACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            case DIACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            default:
                try {
                    throw new Error();
                  } catch (error) {
                    setError(error)
                    console.log(error)
                  }
                
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        let intervalRef;
        if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: DIACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: DIACTIONS.skip})
            }
        } else if (state.id === 1) {
            if (state.currentTime < state.seconds) {
                intervalRef = setInterval(() => {
                    dispatch({type: DIACTIONS.increase})
                }, 1000)
            } else {
                dispatch({type: DIACTIONS.stop})
            }
        } else {
            clearInterval(intervalRef)
        }
        return () => {clearInterval(intervalRef)}
    }, [state])




    return (
        // 
        <QuestionPage 
        questionType="di" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}