/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {AiFillCaretLeft} from 'react-icons/ai'
import { Link} from 'react-router-dom'
import { PATHS } from '../../../../global/constants'
import { modelTest } from './data'
import './modelTest.scss'

export default function ModelTest() {
  return (
    <div id="mn-model-test">
         <div className='title p-2'>
          <Link to={PATHS.admin.name+PATHS.mock.name}>
          <AiFillCaretLeft className='go-back' />
          </Link>
          PTE Model Test
        </div>
        <div className='body p-4'>
            <table>
                <tr>
                    <th>Test 1</th>
                    <th>Module name</th>
                    <th>Operate</th>
                </tr>
                {modelTest.map(data =>{
                    return(
                        <tr key={data.id}>
                            <td>{data.order}</td>
                            <td>{data.title}</td>
                            <td><Link to={data.path}>
                                <div  className="link-btn">Start practice test</div>
                            </Link>
                            </td>
                        </tr>
                    )
                })}
            </table>
        </div>
    </div>
  )
}
