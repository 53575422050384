/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useReducer, useRef} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import { QuestionWrapper } from '../questionWrapper'
import './practiceRMCMPage.scss'
import {Form, Button} from 'react-bootstrap'
//import Rmcm from './rmcm'
/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const [answerValues, setAnswerValues] = useState([])

    function rmcm_transform_paragraph(paragraph) {
        const punctuations = [".", ",", ":", "?", "@", "#", "'", "'", '"', "<", ">", "&", "*"]
        const letters = ["A.", "B.", "C.", "D.", "E.", "F."]
        const bracket_A = ["[A]", "[a]", "(A)", "(a)"]
        const bracket_B = ["[B]", "[b]", "(B)", "(b)"]
        const bracket_C = ["[C]", "[c]", "(C)", "(c)"]
        const bracket_D = ["[D]", "[d]", "(D)", "(d)"]
        const bracket_E = ["[E]", "[e]", "(E)", "(e)"]

        const eles = paragraph.split(" ")
        
        const new_eles = eles.map(ele => {
            if (punctuations.includes(ele)) {
                return;
            } else if (letters.includes(ele)) {
                return ''
            } else if(bracket_A.includes(ele) || bracket_B.includes(ele) 
            || bracket_C.includes(ele) || bracket_D.includes(ele) || bracket_E.includes(ele)){
                return ''
            } else if (ele.includes("<answer>")) {
                return ''
            } else if(ele.includes("</answer>")){
              const v = ele.replace("</answer>", "")
              return  v 
            }else{
              return  ele;
            }
        })
        return new_eles.join(" ");
      }
    
    function clearOut(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
            input.checked = false
        })
        Array.from(label_eles).forEach(label =>{
          label.style.background="white"
        })
    }

    function checkCorrectAns(){
        const input_eles = document.getElementsByTagName('input')
        const label_eles = document.getElementsByTagName('label')
        Array.from(input_eles).forEach(input => {
        if(input.checked){
            Array.from(label_eles).forEach(label =>{
            const answer = label.getAttribute('value')
            if(answer.includes("<answer>"))
            {   label.style.background="#4beba8"}
            })
        }
      
    })
    }
    // get answer values 
    useEffect(() => {
        if (props.questionData !== undefined) {
        } else {
            console.log(props.questionData)
        }
        clearOut()
    }, [props.questionId])

    
    return (
        <div>
            {
                props.questionData && 
                <div className="content">
                    <div className="left">
                        <ClickableParagraph>{props.questionData.content}</ClickableParagraph>
                    </div>

                    <div className="right">
                        <div className="mb-3">
                        <ClickableParagraph strong={true}>{props.questionData.question}</ClickableParagraph>
                        {
                            props.questionData.options.map((o, idx) => {
                                return (
                                <>
                                    <input type="checkbox" key={idx} className="options" id={idx} 
                                    value={o}
                                    ></input>
                                    <label for className='options' id={idx+1} value={o}>
                                    {rmcm_transform_paragraph(o)}
                                    </label><br/>
                                </>
                                )
                            })
                        }
                        </div>
                        <Button variant="outline-primary" onClick={checkCorrectAns}>Check answers</Button>
                    </div>
                </div>   
            }
            {
                !props.questionData && 
                <>
                Is loading...
                </>
            }
        </div>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRMCMPage() {
    
    const initialState = {
        selectedValues: []
    }
    
    const ACTIONS = {
        reset: "reset",
        addValue: "addValue",
        removeValue: "removeValue"
    }
    
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return {
                    selectedValues: []
                }
            case ACTIONS.addValue:
                if (!state.selectedValues.includes(action.value)) {
                    return state.selectedValues.push(action.value)
                }
            case ACTIONS.removeValue:
                if (state.selectedValues.includes(action.value)) {
                    const idx = state.selectedValues.indexOf(action.value)
                    return state.selectedValues.splice(idx, 1)
                }

            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    return (
        <QuestionPage 
        questionType="rmcm" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}