/*
* Created by TUTEHUB.
* Copyright © TUTEHUB. All rights reserved.
* ------------------------
* Non-disclosure Terms
* ------------------------
* These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
* Technique Support: jobyme88.com
*/

import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import './popupAdd.scss'
import { scoreType, grammarType } from './analysisData'

export default function PopupAdd(props) {
    return (
        <Modal id="mn-modal-add" show={props.modalIsShow} onHide={props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add previous exam's score</Modal.Title>
            </Modal.Header>
            <Modal.Body className='body'>
                <p>Transcript <b>(optional)</b></p>
                <div className='upload-file'><p> + </p></div>
                <p><i>The information is only used for score analysis and will not be released to anyone</i></p>
                <p>Exam date</p>
                <input type="date" className='date-input'></input>
                <p>Test score</p>
                <div className='score-list'>
                    <div className='score-info'>
                        {scoreType.map((type, idx) => {
                            return (
                                <div key={idx} className='input-score'>
                                    <input type='text' placeholder='--'></input>
                                    <p>{type}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <br />
                <div className='grammar-list'>
                    <div className='grammar-info'>
                        {grammarType.map((type, idx) => {
                            return (
                                <div key={idx} className='input-score'>
                                    <input type='text' placeholder='--'></input>
                                    <p>{type}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='footer'>
                <Button variant="secondary" onClick={props.hideModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.hideModal}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
