/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import {Form, InputGroup, FormControl} from 'react-bootstrap'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import {formErrorEnum} from './data'



export default function FieldMatchPassword(props) {
    const [showPassword, setShowPassword] = useState(false)
    

    function checkConfirmPassword(v) {
        if (v === ""){
            props.setFormState({...props.formState, confirmPassword: formErrorEnum.passwordEmpty})
        } else if (v !== props.formInfo.password ) {
            props.setFormState({...props.formState, confirmPassword: formErrorEnum.passwordNotMatch})
        } else {
            props. setFormState({...props.formState, confirmPassword: ""})
        }
    }

    return (
        <div>
        <InputGroup>
            <Form.Control 
                type={showPassword ? "text" : "password"}
                placeholder="Please enter at least 8-digit password" 
                value={props.formInfo.confirmPassword}
                onChange={(e) => checkConfirmPassword(e.target.value)}
                />
            <InputGroup.Text>
                <div onClick={() => {setShowPassword(!showPassword)}}>
                { showPassword &&  <AiOutlineEyeInvisible /> }
                { !showPassword &&  <AiOutlineEye /> }
                </div>
                
            </InputGroup.Text> 
        </InputGroup>
        {
            props.formState.confirmPassword !== "" &&
            <Form.Text className="text-red text-left" style={{color: "red"}}>
                {props.formState.confirmPassword}
            </Form.Text>
        }
        <br />
        </div>
    )
}
