/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {formErrorEnum} from './data'


export default function FieldUsernameEmail(props) {


    function checkUsernameEmail(v) {
       
        // check
        if (v === ""){
            props.setFormInfo({...props.formInfo, username: "", email: ""})
            props.setFormState({...props.formState, username: formErrorEnum.fieldEmpty, email: ""})


            // email
        } else if (v.includes("@")) {
            props.setFormInfo({...props.formInfo, username: "", email: v})

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(!re.test(String(v).toLowerCase())) {
                props.setFormState({...props.formState, username: "", email: formErrorEnum.emailInvalid})
        
            } else {
                props.setFormState({...props.formState, username: "", email: ""})
            }
            
        } else {
            props.setFormInfo({...props.formInfo, username: v, email: ""})
            props.setFormState({...props.formState, username: "", email: ""})
        } 
    }

    return (
        <div>
            <Form.Control 
                type="text" 
                placeholder="User name / Email" 
                value = {props.formInfo.username !== "" ? props.formInfo.username : props.formInfo.email}
                onChange={(e) => checkUsernameEmail(e.target.value)}
            />
            {
                props.formState.username  !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.username}
                </Form.Text>
            }
            {
                props.formState.email  !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.email}
                </Form.Text>
            }
            <br/>
        </div>
    )
}
