/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useState, useReducer, useEffect, useRef } from 'react'
import PracticeFooter from '../common/footer/practiceFooter'
import PracticeHeader from '../common/header/practiceHeader'
import PracticePrompt from '../common/prompt/practicePrompt'
import 'styled-components/macro'
import axios from 'axios';
import RightBtn from '../common/rightBtn/rightBtn'
import QuestionListModal from '../common/questionListModal/questionListModal'
import { BASE_URL } from '../../../../global/constants'
import { questionTabEnum } from '../common/utils'
import MyPractices from '../common/myPratices/myPractices'
import AllComments from '../common/allComments/allComments'
import QuestionAnalysis from '../common/questionAnalysis/questionAnalysis'
import AllPractices from '../common/allPractices/allPractices'
//import '../common/header/PracticeHeader.scss'
import { useHistory, useLocation } from 'react-router-dom';


import data_placeholder_asq from '../../../../data/placeholder_asq_1.json';
import data_placeholder_di from '../../../../data/placeholder_di_1.json';
import data_placeholder_fib from '../../../../data/placeholder_fib_1.json';
import data_placeholder_hcs from '../../../../data/placeholder_hcs_1.json';
import data_placeholder_hiw from '../../../../data/placeholder_hiw_1.json';
import data_placeholder_mcm from '../../../../data/placeholder_mcm_1.json';
import data_placeholder_mcs from '../../../../data/placeholder_mcs_1.json';
import data_placeholder_ra from '../../../../data/placeholder_ra_1.json';
import data_placeholder_rfib from '../../../../data/placeholder_rfib_1.json';
import data_placeholder_rl from '../../../../data/placeholder_rl_1.json';
import data_placeholder_rmcm from '../../../../data/placeholder_rmcm_1.json';
import data_placeholder_rmcs from '../../../../data/placeholder_rmcs_1.json';
import data_placeholder_ro from '../../../../data/placeholder_ro_1.json';
import data_placeholder_rs from '../../../../data/placeholder_rs_1.json';
import data_placeholder_rwfib from '../../../../data/placeholder_rwfib_1.json';
import data_placeholder_smw from '../../../../data/placeholder_smw_1.json';
import data_placeholder_sst from '../../../../data/placeholder_sst_1.json';
import data_placeholder_swt from '../../../../data/placeholder_swt_1.json';
import data_placeholder_we from '../../../../data/placeholder_we_1.json';
import data_placeholder_wfd from '../../../../data/placeholder_wfd_1.json';




/**
 * Higher order component
 * @param {*} Component 
 * @returns 
 */
export const QuestionWrapper = (Component) =>


    function Comp(props) {

        const [questionId, setQuestionId] = useState(1)
        const [questionData, setQuestionData] = useState()
        const [listShow, setListShow] = useState(false)
        const [questionTabState, setQuestionTabState] = useState(questionTabEnum.questionContent)
        const [questionInfo, setQuestionInfo] = useState({})


        /**
         *  get question data
         */
        useEffect(() => {

            axios.get(BASE_URL + props.questionType + "/" + props.questionType + '-' + questionId)
                .then((res) => {
                    setQuestionData(res.data.object)
                })
                .catch((err) => {
                    console.log(err)

                    if (props.questionType == "asq") { setQuestionData(data_placeholder_asq.object) }
                    else if  (props.questionType == "di") { setQuestionData(data_placeholder_di.object) }
                    else if  (props.questionType == "fib") { setQuestionData(data_placeholder_fib.object) }
                    else if  (props.questionType == "hcs") { setQuestionData(data_placeholder_hcs.object) }
                    else if  (props.questionType == "hiw") { setQuestionData(data_placeholder_hiw.object) }
                    else if  (props.questionType == "mcm") { setQuestionData(data_placeholder_mcm.object) }
                    else if  (props.questionType == "mcs") { setQuestionData(data_placeholder_mcs.object) }
                    else if  (props.questionType == "ra") { setQuestionData(data_placeholder_ra.object) }
                    else if  (props.questionType == "rfib") { setQuestionData(data_placeholder_rfib.object) }
                    else if  (props.questionType == "rl") { setQuestionData(data_placeholder_rl.object) }
                    else if  (props.questionType == "rmcm") { setQuestionData(data_placeholder_rmcm.object) }
                    else if  (props.questionType == "rmcs") { setQuestionData(data_placeholder_rmcs.object) }
                    else if  (props.questionType == "ro") { setQuestionData(data_placeholder_ro.object) }
                    else if  (props.questionType == "rs") { setQuestionData(data_placeholder_rs.object) }
                    else if  (props.questionType == "rwfib") { setQuestionData(data_placeholder_rwfib.object) }
                    else if  (props.questionType == "smw") { setQuestionData(data_placeholder_smw.object) }
                    else if  (props.questionType == "sst") { setQuestionData(data_placeholder_sst.object) }
                    else if  (props.questionType == "swt") { setQuestionData(data_placeholder_swt.object) }
                    else if  (props.questionType == "we") { setQuestionData(data_placeholder_we.object) }
                    else if  (props.questionType == "wfd") { setQuestionData(data_placeholder_wfd.object) }
                })
        }, [questionId])


        /**
         * get local file
         * because it is HOC, so it becomes ../assets/
         */
        useEffect(() => {
            axios
                .get(process.env.PUBLIC_URL + "/assets/" + props.questionType + ".json")
                .then((res) => {
                    setQuestionInfo(res.data)
                })
                .catch(err => console.log(err))
        }, [props.questionType])



        return (
            <div id={`mn-practice-${props.questionType}`} className="mn-practice">
                <div className="practice-panel">
                    <PracticeHeader
                        slug={`${props.questionType}-${questionId}`}
                        questionTabState={questionTabState}
                        setQuestionTabState={setQuestionTabState}
                        setModalShow={() => { setListShow(true) }}
                    />

                    {
                        questionTabState === questionTabEnum.questionContent &&
                        <>
                            <div className="body p-3">
                                <PracticePrompt
                                    prompt={questionInfo.prompt}
                                />

                                <Component
                                    {...props}
                                    questionId={questionId}
                                    questionData={questionData}
                                />
                            </div>
                        </>
                    }
                    {
                        questionTabState === questionTabEnum.myPractices &&
                        <MyPractices slug={`${props.questionType}-${questionId}`} />
                    }
                    {
                        questionTabState === questionTabEnum.allPractices &&
                        <AllPractices slug={`${props.questionType}-${questionId}`} />
                    }
                    {
                        questionTabState === questionTabEnum.allComments &&
                        <AllComments slug={`${props.questionType}-${questionId}`} />
                    }
                    {
                        questionTabState === questionTabEnum.questionAnalysis &&
                        <QuestionAnalysis slug={`${props.questionType}-${questionId}`} />
                    }

                    <PracticeFooter
                        slug={`${props.questionType}-${questionId}`}
                        setQuestionId={setQuestionId}
                        setModalShow={() => { setListShow(true) }}
                        dispatch={props.dispatch}
                    />

                </div>

                <RightBtn
                    onClick={() => setListShow(!listShow)}
                />
                <QuestionListModal
                    slug={`${props.questionType}-${questionId}`}
                    modalShow={listShow}
                    setModalShow={() => { setListShow(false) }}
                    questionInfo={questionInfo}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                    dispatch={props.dispatch}
                />
            </div>
        )
    }

