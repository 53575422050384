/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import "./allComments.scss"
import { Button } from 'react-bootstrap'
import { CommentData } from './commentData'

export default function AllComments() {
    const [repliedState, setRepliedState] = useState(false)
    
    return (
        <div id="mn-question-all-comments">
            <div className='allcomments px-5 py-3'>
            <ul className="mb-3 px-5 py-3">
                {CommentData.map((data, index) =>{
                    return(
                        <>
                        <li className='allcomments-list'>
                            <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
                            <div className='list-tab'>
                            <div key={data.id}>
                                <h8>{data.username}</h8> <p className='datetime'>MM-DD-YYYY time</p>
                                <br/>
                                <p>{data.comment}</p>
                            <Button className='button'>Like</Button>|
                            <Button id={data.id} className='button'onClick={() =>{setRepliedState(!repliedState)}}>Reply</Button>
                            <div className={repliedState === false ? "no-comment" : "comments"}>
                                <li className='replies'>
                                    <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
                                    <div className='list-tab'>
                                    <h8>{data.username}</h8> <p className='datetime'>MM-DD-YYYY time</p>
                                    <br/>
                                    <p>{data.comment}</p>
                                    </div>
                                </li>
                            </div>
                            </div>
                            </div>
                        </li>
                        <br/>
                        </>    
                    )
                    })
                }
            </ul>
            </div>
        </div>
    )
}
