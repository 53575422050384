/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState} from 'react'
import 'styled-components/macro'
import './adminHomePage.scss'
import 'styled-components/macro'
import StatisticsItem from './statisticsItem'
import BottomItem from './bottomItem'
import MockItem from './mockItem'
import {statisticsData, memoriesData, smallData} from './data'
import SmallItem from './smallItem'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/thumbs/thumbs.min.css"

// import Swiper core and required modules
import SwiperCore, {Navigation,Thumbs} from 'swiper/core';
// install Swiper modules
SwiperCore.use([Navigation,Thumbs]);


export default function AdminHomePage() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    return (
        <div id="mn-admin-home" className="p-2">
            <div className="admin-banner">
                <Swiper 
                    style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
                    spaceBetween={10} 
                    navigation={true} 
                    thumbs={{ swiper: thumbsSwiper }} 
                    className="my-swiper-top">
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-1.jpg" alt="1"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-2.jpg" alt="2"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-3.jpg" alt="3"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-4.jpg" alt="4"/></SwiperSlide>
                </Swiper>
                <Swiper 
                    onSwiper={setThumbsSwiper} 
                    spaceBetween={10} 
                    slidesPerView={4} 
                    freeMode={true}
                    watchSlidesVisibility={true} 
                    watchSlidesProgress={true} 
                    className="my-swiper-bottom">
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-1.jpg" alt="1"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-2.jpg" alt="2"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-3.jpg" alt="3"/></SwiperSlide>
                    <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-4.jpg" alt="4"/></SwiperSlide>
                </Swiper>
            </div>
            <div className="home-container">
                <StatisticsItem className="statistics-item" data={statisticsData}/>
                <StatisticsItem className="memories-item" data={memoriesData}/>
                <MockItem />
                {
                    smallData.map((s) => (
                        <SmallItem 
                        className = {s.className}
                        title = {s.title}
                        des = {s.des}
                        />
                    ))
                }
                <BottomItem />
            </div>
        </div> 
    )
}








