/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useState, useEffect } from 'react'
import './predictPage.scss'
import { speakingData, writingData, listeningData, readingData, dataSort, rateName } from './predictData'
import {Button, ButtonGroup, ListGroup, Pagination} from 'react-bootstrap'
import '../adminPage.scss'
import AdminNav from '../nav/adminNav'
import AdminSideMenu from '../sideMenu/adminSideMenu'
import axios from 'axios'
import { BASE_URL } from '../../../global/constants'




export default function PredictPage() {
    function getFirstLastDay() {
        var curr = new Date()
        var firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay()))
        var lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
        var firstMonth = parseInt(firstDay.getMonth()) + 1
        var lastMonth = parseInt(lastDay.getMonth()) + 1
        var result = "【" + firstMonth + "." + firstDay.getDate() + " -- " + lastMonth + "." + lastDay.getDate() + "】"
        return result
    }

    const [questionType, setType] = useState("Speaking")
    const [typeName, setName] = useState("RA")
    const [listSort, setListSort] = useState(dataSort[0])

    const [page, setPage] = useState(1)
    const [questionDatas, setQuestionDatas] = useState()
    const speakingData = ["ra", "di", "rl", "rs", "asq"]
    const writingData = ["swt", "we"]
    const readingData = ["rfib", "rmcm", "rmcs", "ro", "rwfib"]
    const listeningData = ["sst", "mcm", "fib", "hcs", "hiw", "mcs", "smw", "wfd"]
    const [questionCounts, setQuestionCounts] = useState({
        ra: 0, rs: 0, di: 0, rl: 0, asq: 0,
        swt: 0, we: 0,
        ro: 0, rmcm: 0, rmcs: 0, rfib: 0, rwfib: 0,
        mcm: 0, mcs: 0, hiw: 0, hcs: 0, sst: 0, smw: 0, fib: 0, wfd: 0
    })


    function activeSpeakingList() {
        if (questionType !== "Speaking") {
            setType("Speaking")
            setName("RA")
        }
    }
    function activeReadingList() {
        if (questionType !== "Reading") {
            setType("Reading")
            setName("RFIB")
        }
    }
    function activeListeningList() {
        if (questionType !== "Listening") {
            setType("Listening")
            setName("SST")
        }
    }
    function activeWritingList() {
        if (questionType !== "Writing") {
            setType("Writing")
            setName("SWT")
        }
    }


    /**
    * get number of questions
    */
    useEffect(() => {
        Object.keys(questionCounts).map((type) => {
            axios.get(BASE_URL + "count/" + type)
                .then((response) => {
                    console.log(response.data.object)
                    setQuestionCounts((prevState) => ({
                        ...prevState,
                        [type]: response.data.object
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
        })

    }, [])


    /**
   * get local question info
   */
    const [questionInfos, setQuestionInfos] = useState({
        ra: {}, rs: {}, di: {}, rl: {}, asq: {},
        swt: {}, we: {},
        ro: {}, rmcm: {}, rmcs: {}, rfib: {}, rwfib: {},
        mcm: {}, mcs: {}, hiw: {}, hcs: {}, sst: {}, smw: {}, fib: {}, wfd: {}
    })
    useEffect(() => {
        Object.keys(questionInfos).map((type) => {
            axios.get(process.env.PUBLIC_URL + "/assets/" + type + ".json")
                .then((res) => {
                    setQuestionInfos((prevState) => ({
                        ...prevState,
                        [type]: res.data
                    }))
                });
        });
    }, [])

    /**
    * get question data
    */
    const qt = "ra";
    const qid = "1";
    useEffect(() => {
        axios.get(BASE_URL + qt + "/" + qt + '-' + qid,
            { params: { pageNum: page, pageSize: 20, sortType: "ASC" } })
            .then((res) => {
                setQuestionDatas(res.data.object)
            })
            .catch(err => console.log(err))

    }, [page])


    return (
        <div id="mn-admin-page">
            <AdminNav />
            <main className="p-3 pr-5">
                <AdminSideMenu />
                <div id="mn-predict">
                    <div className="practice-panel">
                        <div className="header p-2">
                            <span>{getFirstLastDay()} COW PTE prediction</span>
                            <div className="header-right">
                                <div className="input-with-select el-input el-input--mini el-input-group el-input-group--append">
                                    <input type="text" autocomplete="off" placeholder=" SEARCH..." />
                                    <div className="el-input-group-append">
                                        <button type="button" className="search-btn el-button el-button-default">
                                            <span>Search</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='body p-4'>
                            <div className='question-option'>
                                <button onClick={activeSpeakingList}
                                    className={questionType === "Speaking" ? "active" : "unactive"}>
                                    Speaking
                                </button>
                                <button onClick={activeReadingList}
                                    className={questionType === "Reading" ? "active" : "unactive"}>
                                    Reading
                                </button>
                                <button onClick={activeListeningList}
                                    className={questionType === "Listening" ? "active" : "unactive"}>
                                    Listening
                                </button>
                                <button onClick={activeWritingList}
                                    className={questionType === "Writing" ? "active" : "unactive"}>
                                    Writing
                                </button>
                            </div>
                            <div className='info-list'>
                                <div className={questionType === "Speaking" ? "question-active" : "question-unactive"}>
                                    {/**Speaking Data */}
                                    {speakingData.map((d, idx) => {
                                        return (
                                            <>
                                                <div className={typeName === questionInfos[d].shortName ? "type-active" : "question-unactive"}>
                                                    <div className='rate-n-description'>
                                                        <div className='list-active' key={idx}>
                                                            <b>Question rate - {questionInfos[d].shortName}</b>
                                                            <div>
                                                                {rateName.map((data, idx) => {
                                                                    return (
                                                                        <>
                                                                            <div key={idx} className="rate-list">
                                                                                <div className='label'>{data.name}:</div>
                                                                                <div className='container'>
                                                                                    <div className='rate'
                                                                                        style={{ width: data.rate, background: data.bgColor }}></div>
                                                                                </div>
                                                                                <div className='label'>{data.rate}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='description'>
                                                            <b>Question description - {questionInfos[d].shortName}</b>
                                                            <p>Description here</p>
                                                        </div>
                                                    </div>
                                                    <div className='sort-list'>
                                                        <ButtonGroup className="p-3">
                                                            {dataSort.map((d, idx) => {
                                                                return (
                                                                    <Button key={idx}
                                                                        onClick={() => setListSort(d)}
                                                                        active={listSort === d}
                                                                        variant={listSort === d ? "primary" : "outline-secondary"}
                                                                        size="sm">
                                                                        {d}
                                                                    </Button>
                                                                )
                                                            })}
                                                        </ButtonGroup>
                                                        <table>
                                                            <ListGroup>
                                                                {questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                                {
                                                                    !questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                            </ListGroup>
                                                        </table>
                                                    </div>
                                                    {/* Pagination */}
                                                    <div className="bottom my-3 mb-5">
                                                        {
                                                            questionDatas &&
                                                            <Pagination>
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(1) }}
                                                                    className={page === 1 ? "disabled" : ""}>{1}</Pagination.Item>
                                                                <Pagination.Prev
                                                                    onClick={() => { setPage(page - 1) }}
                                                                    className={page === 1 ? "disabled" : ""}
                                                                />
                                                                <Pagination.Item active>{questionDatas.number + 1}</Pagination.Item>
                                                                <Pagination.Ellipsis />
                                                                <Pagination.Next
                                                                    onClick={() => { setPage(page + 1) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""} />
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(questionDatas.totalPages) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""}>
                                                                    {questionDatas.totalPages}
                                                                </Pagination.Item>
                                                            </Pagination>
                                                        }
                                                        <div>
                                                            Skip to
                                                            <input
                                                                type="text"
                                                                placeholder=""
                                                                className="mx-1"
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        setPage(e.target.value)
                                                                    }
                                                                    // e.currentTarget.value = ""
                                                                }
                                                                } />
                                                            Page
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                {/**Reading Data */}
                                <div className={questionType === "Reading" ? "question-active" : "question-unactive"}>
                                    {readingData.map((d, idx) => {
                                        return (
                                            <>
                                                <div className={typeName === questionInfos[d].shortName ? "type-active" : "question-unactive"}>
                                                    <div className='rate-n-description'>
                                                        <div className='list-active' key={idx}>
                                                            <b>Question rate - {questionInfos[d].shortName}</b>
                                                            <div>
                                                                {rateName.map((data, idx) => {
                                                                    return (
                                                                        <>
                                                                            <div key={idx} className="rate-list">
                                                                                <div className='label'>{data.name}:</div>
                                                                                <div className='container'>
                                                                                    <div className='rate'
                                                                                        style={{ width: data.rate, background: data.bgColor }}></div>
                                                                                </div>
                                                                                <div className='label'>{data.rate}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='description'>
                                                            <b>Question description - {questionInfos[d].shortName}</b>
                                                            <p>Description here</p>
                                                        </div>
                                                    </div>
                                                    <div className='sort-list'>

                                                        <ButtonGroup className="p-3">
                                                            {dataSort.map((d, idx) => {
                                                                return (
                                                                    <Button key={idx}
                                                                        onClick={() => setListSort(d)}
                                                                        active={listSort === d}
                                                                        variant={listSort === d ? "primary" : "outline-secondary"}
                                                                        size="sm">
                                                                        {d}
                                                                    </Button>
                                                                )
                                                            })}
                                                        </ButtonGroup>
                                                        <table>
                                                            <ListGroup>
                                                                {questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                                {
                                                                    !questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                            </ListGroup>
                                                        </table>
                                                    </div>
                                                    {/* Pagination */}
                                                    <div className="bottom my-3 mb-5">
                                                        {
                                                            questionDatas &&
                                                            <Pagination>
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(1) }}
                                                                    className={page === 1 ? "disabled" : ""}>{1}</Pagination.Item>
                                                                <Pagination.Prev
                                                                    onClick={() => { setPage(page - 1) }}
                                                                    className={page === 1 ? "disabled" : ""}
                                                                />
                                                                <Pagination.Item active>{questionDatas.number + 1}</Pagination.Item>
                                                                <Pagination.Ellipsis />
                                                                <Pagination.Next
                                                                    onClick={() => { setPage(page + 1) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""} />
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(questionDatas.totalPages) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""}>
                                                                    {questionDatas.totalPages}
                                                                </Pagination.Item>
                                                            </Pagination>
                                                        }
                                                        <div>
                                                            Skip to
                                                            <input
                                                                type="text"
                                                                placeholder=""
                                                                className="mx-1"
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        setPage(e.target.value)
                                                                    }
                                                                    // e.currentTarget.value = ""
                                                                }
                                                                } />
                                                            Page
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className={questionType === "Listening" ? "question-active" : "question-unactive"}>
                                    {/**Listening Data */}
                                    {listeningData.map((d, idx) => {
                                        return (
                                            <>
                                                <div className={typeName === questionInfos[d].shortName ? "type-active" : "question-unactive"}>
                                                    <div className='rate-n-description'>
                                                        <div className='list-active' key={idx}>
                                                            <b>Question rate - {questionInfos[d].shortName}</b>
                                                            <div>
                                                                {rateName.map((data, idx) => {
                                                                    return (
                                                                        <>
                                                                            <div key={idx} className="rate-list">
                                                                                <div className='label'>{data.name}:</div>
                                                                                <div className='container'>
                                                                                    <div className='rate'
                                                                                        style={{ width: data.rate, background: data.bgColor }}></div>
                                                                                </div>
                                                                                <div className='label'>{data.rate}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='description'>
                                                            <b>Question description - {questionInfos[d].shortName}</b>
                                                            <p>Description here</p>
                                                        </div>
                                                    </div>
                                                    <div className='sort-list'>

                                                        <ButtonGroup className="p-3">
                                                            {dataSort.map((d, idx) => {
                                                                return (
                                                                    <Button key={idx}
                                                                        onClick={() => setListSort(d)}
                                                                        active={listSort === d}
                                                                        variant={listSort === d ? "primary" : "outline-secondary"}
                                                                        size="sm">
                                                                        {d}
                                                                    </Button>
                                                                )
                                                            })}
                                                        </ButtonGroup>
                                                        <table>
                                                            <ListGroup>
                                                                {questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                                {
                                                                    !questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                            </ListGroup>
                                                        </table>
                                                    </div>
                                                    {/* Pagination */}
                                                    <div className="bottom my-3 mb-5">
                                                        {
                                                            questionDatas &&
                                                            <Pagination>
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(1) }}
                                                                    className={page === 1 ? "disabled" : ""}>{1}</Pagination.Item>
                                                                <Pagination.Prev
                                                                    onClick={() => { setPage(page - 1) }}
                                                                    className={page === 1 ? "disabled" : ""}
                                                                />
                                                                <Pagination.Item active>{questionDatas.number + 1}</Pagination.Item>
                                                                <Pagination.Ellipsis />
                                                                <Pagination.Next
                                                                    onClick={() => { setPage(page + 1) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""} />
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(questionDatas.totalPages) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""}>
                                                                    {questionDatas.totalPages}
                                                                </Pagination.Item>
                                                            </Pagination>
                                                        }
                                                        <div>
                                                            Skip to
                                                            <input
                                                                type="text"
                                                                placeholder=""
                                                                className="mx-1"
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        setPage(e.target.value)
                                                                    }
                                                                    // e.currentTarget.value = ""
                                                                }
                                                                } />
                                                            Page
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className={questionType === "Writing" ? "question-active" : "question-unactive"}>
                                    {/**Writing Data */}
                                    {writingData.map((d, idx) => {
                                        return (
                                            <>
                                                <div className={typeName === questionInfos[d].shortName ? "type-active" : "question-unactive"}>
                                                    <div className='rate-n-description'>
                                                        <div className='list-active' key={idx}>
                                                            <b>Question rate - {questionInfos[d].shortName}</b>
                                                            <div>
                                                                {rateName.map((data, idx) => {
                                                                    return (
                                                                        <>
                                                                            <div key={idx} className="rate-list">
                                                                                <div className='label'>{data.name}:</div>
                                                                                <div className='container'>
                                                                                    <div className='rate'
                                                                                        style={{ width: data.rate, background: data.bgColor }}></div>
                                                                                </div>
                                                                                <div className='label'>{data.rate}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='description'>
                                                            <b>Question description - {questionInfos[d].shortName}</b>
                                                            <p>Description here</p>
                                                        </div>
                                                    </div>
                                                    <div className='sort-list'>

                                                        <ButtonGroup className="p-3">
                                                            {dataSort.map((d, idx) => {
                                                                return (
                                                                    <Button key={idx}
                                                                        onClick={() => setListSort(d)}
                                                                        active={listSort === d}
                                                                        variant={listSort === d ? "primary" : "outline-secondary"}
                                                                        size="sm">
                                                                        {d}
                                                                    </Button>
                                                                )
                                                            })}
                                                        </ButtonGroup>
                                                        <table>
                                                            <ListGroup>
                                                                {questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                                {
                                                                    !questionDatas &&
                                                                    <div style={{ padding: 10 }}> Is loading...</div>
                                                                }
                                                            </ListGroup>
                                                        </table>
                                                    </div>
                                                    {/* Pagination */}
                                                    <div className="bottom my-3 mb-5">
                                                        {
                                                            questionDatas &&
                                                            <Pagination>
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(1) }}
                                                                    className={page === 1 ? "disabled" : ""}>{1}</Pagination.Item>
                                                                <Pagination.Prev
                                                                    onClick={() => { setPage(page - 1) }}
                                                                    className={page === 1 ? "disabled" : ""}
                                                                />
                                                                <Pagination.Item active>{questionDatas.number + 1}</Pagination.Item>
                                                                <Pagination.Ellipsis />
                                                                <Pagination.Next
                                                                    onClick={() => { setPage(page + 1) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""} />
                                                                <Pagination.Item
                                                                    onClick={() => { setPage(questionDatas.totalPages) }}
                                                                    className={page === questionDatas.totalPages ? "disabled" : ""}>
                                                                    {questionDatas.totalPages}
                                                                </Pagination.Item>
                                                            </Pagination>
                                                        }
                                                        <div>
                                                            Skip to
                                                            <input
                                                                type="text"
                                                                placeholder=""
                                                                className="mx-1"
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        setPage(e.target.value)
                                                                    }
                                                                    // e.currentTarget.value = ""
                                                                }
                                                                } />
                                                            Page
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </div>

    )
}
