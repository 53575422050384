/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, { useState, useEffect } from 'react';
import TeacherHeadItem from './teacherHeadItem'
import TeacherHeadSubItem from './teacherHeadSubItem'
import TeacherItem from './teacherItem'
import './teacher.scss';
import HomeTitle from '../../general/title/homeTitle'
import {Container, Row, Col} from 'react-bootstrap'
import BottomButton from '../../general/buttons/bottomButton';

const teacherHead = [
    {
        name: "Limin",
        school: "the Australian National University",
        diploma: "Master's degree",
        des: "Scored a GMAT score of 740 out of 800 (97% in the world), and a PTE reading test score of 84 in the first try.",
        slogan: "Slogan: Practice makes perfect"
    }, 
    {
        name: "Karita",
        school: "Monash University",
        diploma: "Master's degree",
        des: "PTE all-round academics, with profound teaching skills in accurately grasping the trend of the test.",
        slogan: "Slogan: Step by step"
    },
    {
        name: "Jenny", 
        school: "Guangdong University of Foreign Studies", 
        diploma: "Master's degree", 
        des: "Good at helping students to break through the bottleneck of scores in the shortest time",
    }, 
    {
        name: "Yulun", 
        school: "Guangdong University of Foreign Studies", 
        diploma: "Master's degree", 
        des: "Well versed in the skills of speaking, writing and listening.",
        slogan: "Slogan: Help each other"
    }, 
    {
        name: "Wendy", 
        school: "University of Sydney ", 
        diploma: "Master's degree", 
        des: "Famous PTE academic who has been dealing with PTE for a long time.", 
        slogan: "Slogan: Methods are more important"
    }
]




const teacherItem = [
    {
        name: "Trisha", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_1", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    },
    {
        name: "Richard", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_2", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Angela Chang", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_3", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Leon", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_4", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Tharu", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_5", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_6", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_7", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_8", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_9", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_10", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_11", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_12", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }
]



export default function Teacher() {
    const [teacherHeadIdx, setTeacherHeadIdx] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTeacherHeadIdx(teacherHeadIdx => (teacherHeadIdx + 1)%5);
      }, 2000);
      return () => clearInterval(interval);
    }, []);


    return (

        <section id="mn-teacher" className="py-5">
            <HomeTitle  
                title = "COW PTE Family's Profiles"
                des = "Experts from frontend, backend, mobile, and livestream to present the premium digital learning system."
            />
            
            <Container className="animation-transparency an-active">
                <Row>
                    <Col md={4} className="animation-translation-fr an-active p-0 mb-3">
                        <div className="teacher-heads">
                            <div className="teacher-list">
                                {
                                    teacherHead.map( (item, idx) => (
                                    <TeacherHeadItem 
                                        key = {`tea-head-${idx}`}
                                        active = {idx === teacherHeadIdx}
                                        index = {idx+1}
                                        name = {item.name}
                                        school = {item.school}
                                        diploma = {item.diploma}
                                        des = {item.des}
                                        slogan = {item.slogan}
                                    />
                                    ))
                                }
                            </div>

                            <ul className="teacher-l-list">
                                {
                                    [...Array(5).keys()].map((idx) => (
                                        <TeacherHeadSubItem 
                                        key = {`tea-head-sub-${idx}`}
                                        active = {idx === teacherHeadIdx}
                                        index={idx+1} />
                                    ))
                                }
                            </ul>
                        </div>
                    </Col>
                        
                    <Col md={8} className="animation-translation-rf an-active p-0 mb-3">
                        <div class="teacher-subs">
                        {
                            teacherItem.map((item, idx) => (
                                <TeacherItem 
                                    key = {`tea-${idx}`}
                                    name={item.name}
                                    title={item.title}
                                    imgName={item.imgName}
                                    des={item.des}
                                />              
                            ))
                        }
                        </div>

                        
                    </Col>
                </Row>
            </Container>
            <BottomButton 
                btns = {["Consult", "Join us"]}
            />
        </section>

    )
}
