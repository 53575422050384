/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect} from 'react'
import './practiceHeader.scss'
import {AiOutlineClockCircle, AiFillCaretLeft} from 'react-icons/ai'
import {getTypeUpper, questionTabEnum} from '../utils'
import {useHistory, useLocation} from 'react-router-dom'


export default function PracticeHeader(props) {
    const [seconds, setSeconds] = useState(0)

    function turnSecondsFormat(seconds) {
        return new Date(seconds * 1000).toISOString().substr(11, 8)
    }


    useEffect(() => {
        let interval = setInterval(() => {setSeconds(prevState => prevState+1)}, 1000)
        return () => clearInterval(interval)
    }, [seconds])

    /**
     * @param {*} itemType 
     * @returns 
     */
     function tabReducer(itemType){
        if (props.questionTabState === itemType) {
            return "active"
        }  else {
            return ""
        }
    }



    return (
        <>
        <div id="mn-practice-header" className="px-3 py-2">
            <div className="left">
                
                <div className="type" onClick={() => {props.setModalShow()}}>
                    <AiFillCaretLeft className="mr-2"/>
                    <span>{getTypeUpper(props.slug)}</span>
                </div>

                <div>|</div>
                <div 
                className={tabReducer(questionTabEnum.questionContent)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.questionContent)}}
                >Topic</div>
                <div 
                className={tabReducer(questionTabEnum.myPractices)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.myPractices)}}
                >My Practice</div>
                <div 
                className={tabReducer(questionTabEnum.allPractices)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.allPractices)}}
                >Others' Pratice</div>
                <div 
                className={tabReducer(questionTabEnum.allComments)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.allComments)}}
                >
                    Comments</div>
                <div 
                className={tabReducer(questionTabEnum.questionAnalysis)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.questionAnalysis)}}
                >Analysis</div>
            </div>

            <span className="right" style={{color:"white"}}>
                <AiOutlineClockCircle />
                &nbsp;Timer:&nbsp;
                <span id="totalindextimer">{turnSecondsFormat(seconds)}</span>
            </span>

        </div>
        <div id='mn-practice-category'>
            <div className='bottom'>
                <span className='category'>
                        Real question
                </span>
                <span className='category'>
                        Predict
                </span>
                <span className='category'>
                        Updated
                </span>
                <span className='category'>
                        Average
                </span>
                <span className='category'>
                        Sentence type
                </span>
                <span className='category'>
                       High prefrequency
                </span>
            </div>
        </div>
        <br/> <br/>
        </>
    )
}
