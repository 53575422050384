/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import auth from '../../utils/auth'


export default function LoginMainBtn(props) {
    return (
        <div 
        className={`enter ${auth.enableFormSubmitBtn(props.formState)? "" : "disabled"}`}
        onClick={auth.enableFormSubmitBtn(props.formState)? props.onClick : undefined}
        >
            {props.content}
        </div>
    )
}
