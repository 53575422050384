/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useReducer, useEffect} from 'react'
import PracticeAudioIndicator from '../../common/audioIndicator/practiceAudioIndicator'
import ClickableParagraph from '../../common/clickableParagraph/clickableParagraph'
import './practiceRAPage.scss'
import 'styled-components/macro'
import { QuestionWrapper } from '../questionWrapper'

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {

    return (
        <>
        <div className="mb-3">
            <PracticeAudioIndicator 
            state={props.state}
            dispatch = {props.dispatch}
            />
        </div>
        <div>
            { props.questionData && <ClickableParagraph>{props.questionData.content}</ClickableParagraph> }
            { !props.questionData && <p>Is loading...</p>}
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRAPage() {
   

    const RASTATES = {
        prepare: {id: 0, seconds: 40, currentTime: 40},
        record: {id: 1, seconds: 40, currentTime: 0},
        idle: {id: 2, currentTime: -41}
    }
    
    const RAACTIONS = {
        reset: "reset",
        toRecord: "toRecord",
        toIdle: "toIdle",
        decrease: "decrease", 
        increase: "increase"
    }
    
    const initialState = RASTATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case RAACTIONS.reset:
                return RASTATES.prepare
            case RAACTIONS.toRecord:
                return RASTATES.record
            case RAACTIONS.toIdle:
                return RASTATES.idle
            case RAACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            case RAACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        let intervalRef
        if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: RAACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: RAACTIONS.toRecord})
            }
        } else if (state.id === 1) {
            if (state.currentTime < state.seconds) {
                intervalRef = setInterval(() => {
                    dispatch({type: RAACTIONS.increase})
                }, 1000)
            } else {
                dispatch({type: RAACTIONS.toIdle})
            }
            
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
    }, [state])
    

    return (
        // 
        <QuestionPage 
        questionType="ra" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}