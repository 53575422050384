/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React, {useState, useEffect, useRef} from 'react'
import 'styled-components/macro'
import './audioPlayer.scss'


export default function AudioPlayer(props) {
    const [audioVolume, setAudioVolume] = useState(1)

    const audioRef = useRef()
    useEffect(() => {
        // play
        if (props.state.id === 0) {
            if(audioRef.current){
                audioRef.current.pause();
                audioRef.current.load();
                audioRef.current.play();
            }
        }
    }, [props.state.id])


    function getText() {
       if (props.state.id === -1) {
           return "Beginning in " + props.state.currentTime + " seconds"
       } else if (props.state.id === 0) {
           return "Playing"
       } else {
           return "Completed"
       }
    }

    function getProgress() {
        if (props.state.id === -1) {
            return 0
        } else if (props.state.id === 0 ){
            return 100 - props.state.currentTime/props.state.seconds*100
        } else {
            return 100
        }
    }



    if (props.state.id === 2) {
        return (
            <table css={`display: none;`}></table>
        )
    } else {
   
    return (
        <> 
        <table className="audio-player">
            <audio controls ref={audioRef}>
                <source src={props.audioSrc} type="audio/mp3" />
                您的浏览器不支持 audio 元素。
            </audio>

            <tbody>
                <tr>
                    <td colspan="3">
                        &nbsp;Status:&nbsp;<span>{getText()}</span>
                        {
                            props.state.id === -1 &&
                            <button className="mx-3" onClick={() => {props.dispatch({type: "toPlay"})}}>skip</button>
                        }
                    </td>
                </tr>
                <tr>
                    <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="3">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="20%">&nbsp;Volume</td>
                                    <td width="72%" className="px-1">
                                        <input className="volume" type="range" min="0" max="100" step="1" value={audioVolume*100} onChange={(e) => {setAudioVolume(e.target.value/100)}} onInput={(e) => {setAudioVolume(e.target.value/100)}}/>
                                    </td>
                                    <td width="8%">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><img src={process.env.PUBLIC_URL+'/assets/images/slider.avif'} width="209" height="18" alt="" /></td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                   
                   </td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <div className="processbar processbar__prepare" >
                            <div className="processbar processbar__practice" css={`width: ${getProgress()}%;`}>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="21" colSpan="3">&nbsp;</td>
                </tr>
            </tbody>
        </table>
        </>
    )
    }   
}
