/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import PlatformItem from './platformItem';
import HomeTitle from '../../general/title/homeTitle';
import './platform.scss';
import {BiWind} from 'react-icons/bi';
import {BsAlarm} from 'react-icons/bs';
import {FaAssistiveListeningSystems, FaRegLightbulb} from 'react-icons/fa';
import 'styled-components/macro';
import BottomButton from '../../general/buttons/bottomButton';
import { Container } from 'react-bootstrap';


const itemData = [
    {
        url: './predict',
        img: () => {return <BiWind size="4vw" css={`background: linear-gradient(to right top, rgb(105, 118, 206), rgba(105, 118, 206, 0.5));`}/>},
        title: 'Predicts',
        description: 'Get most possible questions to be tested.',
        btnTitle: 'Practice',
        iconUrl: './assets/images/arrow_function_1.avif',
        iconUrlH: './assets/images/arrow_function_h.avif',
    },
    {
        url: './practice',
        img: () => {return <FaAssistiveListeningSystems size="4vw" css={`background: linear-gradient(to right top, rgba(51, 141, 245), rgba(51, 141, 245, 0.5));`}/>},
        title: 'Question bank',
        description: 'AI powered conclusive questions',
        btnTitle: 'Practice',
        iconUrl: './assets/images/arrow_function_2.avif',
        iconUrlH: './assets/images/arrow_function_h.avif',
    },
    {
        url: './admin/mock',
        img: () => {return <BsAlarm size="4vw" css={`background: linear-gradient(to right top, rgba(254, 183, 94, 1), rgba(254, 183, 94, 0.5));`}/>},
        title: 'Mocks',
        description: 'Feel how PTE tests and which level you are now',
        btnTitle: 'Test',
        iconUrl: './assets/images/arrow_function_4.avif',
        iconUrlH: './assets/images/arrow_function_h.avif',
    },
    {
        url: './course',
        img: () => {return <FaRegLightbulb size="4vw" css={`background: linear-gradient(to right top, rgb(44, 174, 219), rgba(44, 174, 219, 0.5));`}/>},
        title: 'Courses',
        description: 'Watch latest PTE videos',
        btnTitle: 'Listen',
        iconUrl: './assets/images/arrow_function_3.avif',
        iconUrlH: './assets/images/arrow_function_h.avif',
    }
    
]
export default function Platform() {
    return (
      
      <section id="mn-platform" className="py-5">
        <HomeTitle  
          title = "AI-powered practice platform"
          des = "Questions, courses, tutors to help you in PTE"
        />
        <Container>
            <ul className="practice-list">
                {
                itemData.map((item, idx) => (
                    <PlatformItem
                    key = {idx}
                    url={item.url}
                    img={item.img}
                    title={item.title}
                    description={item.description}
                    btnTitle={item.btnTitle}
                    iconUrl={item.iconUrl}
                    iconUrlH={item.iconUrlH}
                    />
                ))
                }
            </ul>
        </Container>

        <BottomButton btns={["Trial class", "Study group"]}/>
      </section>

    )
}
