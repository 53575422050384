/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import { scoreType } from './analysisData'
import './popupExam.scss'

export default function PopupExam(props) {
  return (
    <Modal id="mn-modal-analysis" show={props.modalIsShow} onHide={props.hideModal}>
        <Modal.Header closeButton>
            <Modal.Title>Set a goal</Modal.Title>
        </Modal.Header>
        <Modal.Body className='body'>
            <p>Exam date</p>
            <input type="date" className='date-input'></input>
            <div className='score-list'>
                <div className='score-info'>
                    {scoreType.map((type, idx)=>{
                        return(
                            <div key={idx} className='input-score'>
                                <input type='text' placeholder='--'></input>
                                <p>{type}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className='footer'>
          <Button variant="secondary" onClick={props.hideModal}>
               Cancel
          </Button>
          <Button variant="primary" onClick={props.hideModal}>
               Confirm
          </Button>
        </Modal.Footer>
    </Modal>
  )
}
